import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useBreakpoint } from 'hooks/useBreakpoint';
import PortfolioProperties from 'components/PortfolioProperties';
import { productAdapter } from 'containers/utils/formats';
import PortfolioChart from 'components/PortfolioChart';
import {
  saveSimulatorAnswers,
  sendSimulatorResult,
} from 'containers/utils/fetch';
import Loader from 'components/Loader';
import { useNotificationContext } from 'features/NotificationContext';

import { LANGUAGES } from 'containers/utils/constants';
import Button from 'components/Button';
import { SimulatorContext } from '../Context';
import { BASE_PATH, HUBSPOT_URL } from '../constants';

import Actions from './Actions';

const CLASS_PREFIX = 'simulator';

const Results = () => {
  const history = useHistory();
  const { t } = useTranslation('simulator');
  const { showNotification } = useNotificationContext();
  const { simulatorData, setSimulatorData } = useContext(SimulatorContext);
  const breakpoint = useBreakpoint();
  const isMobileDevice = breakpoint === 'md' || breakpoint === 'sm';
  const product = productAdapter(simulatorData.targetInfo.data.target.product);
  const {
    targetInfo: { data: targetInfoData },
    personalInfo: {
      data: {
        name: customerName,
        email: customerEmail,
        phone: customerPhone,
        is_gbm_client: isGBMClient,
      },
    },
    results,
  } = simulatorData;
  const currentLang = simulatorData.lng;

  const handleTryAgain = () => {
    setSimulatorData({
      ...simulatorData,
      results: {
        data: {},
        isFilled: false,
      },
    });
    history.push(BASE_PATH);
  };

  useEffect(() => {
    if (!results.isFilled) {
      const simulatorBody = {
        customer_email: customerEmail?.toLowerCase(),
        customer_name: customerName?.trim(),
        customer_phone: customerPhone,
        first_deposit: targetInfoData.first_deposit,
        monthly_deposit: targetInfoData.monthly_deposit,
        investment_time: targetInfoData.years,
        is_gbm_client: isGBMClient,
      };
      const targetId = targetInfoData.target.id;

      saveSimulatorAnswers(
        simulatorData,
        setSimulatorData,
        targetId,
        simulatorBody,
      );
    }
  }, []);

  const handleSendAdvised = () => {
    window.open(HUBSPOT_URL, '_blank');
  };

  const handleSendResults = () => {
    const scoreId = simulatorData.results.data.score_id;
    if (sendSimulatorResult(scoreId)) {
      showNotification('success', `${t('resultSendToEmail')} ${customerEmail}`);
    } else {
      showNotification('error', `${t('genericError')}`);
    }
  };

  if (!results.isFilled) return <Loader animated={false} type="dark" />;

  return currentLang === LANGUAGES.en ? (
    <div
      className={clsx(CLASS_PREFIX, {
        'simulator--dark': true,
      })}
      data-testid="simulator"
    >
      <div className={`${CLASS_PREFIX}__container ${CLASS_PREFIX}__results`}>
        <div className={`${CLASS_PREFIX}__content`}>
          <div
            className={clsx('font-mabry typography--regular product__title', {
              'typography-2': !isMobileDevice,
              'typography-3': isMobileDevice,
            })}
          >
            {t('yourResultIs')}:
            <br />
            <span className="typography--bold">{t(`${product}Product`)}</span>
          </div>
          <PortfolioProperties
            description={t('yieldTooltip')}
            property={t('yield')}
            percentage={simulatorData.targetInfo.data.target.yield}
          />
          <PortfolioProperties
            description={t('volatilityTooltip')}
            property={t('volatility')}
            percentage={simulatorData.targetInfo.data.target.volatility}
          />
          <PortfolioProperties
            description={t('liquidityTooltip')}
            property={t('liquidity')}
            percentage={simulatorData.targetInfo.data.target.liquidity}
          />
          <div className={`${CLASS_PREFIX}__actions-desktop`}>
            <div className={`${CLASS_PREFIX}__actions`}>
              <Button
                className={`${CLASS_PREFIX}__btn-again`}
                testId="btnTryAgain"
                onClick={handleTryAgain}
                kind="secondary"
                text={t('testOtherProduct')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={clsx(CLASS_PREFIX, {
        'simulator--dark': true,
      })}
      data-testid="simulator"
    >
      <div className={`${CLASS_PREFIX}__container ${CLASS_PREFIX}__results`}>
        <div className={`${CLASS_PREFIX}__content`}>
          <div
            className={clsx('font-mabry typography--regular product__title', {
              'typography-2': !isMobileDevice,
              'typography-3': isMobileDevice,
            })}
          >
            {t('yourResultIs')}
            <br />
            {t(`${product}.name`)}
          </div>
          {isMobileDevice ? (
            <div className="typography-b-1 typography--regular product__description">
              {t(`${product}.description`)}
            </div>
          ) : null}
          <PortfolioProperties
            description={t('yieldTooltip')}
            property={t('yield')}
            percentage={simulatorData.targetInfo.data.target.yield}
          />
          <PortfolioProperties
            description={t('volatilityTooltip')}
            property={t('volatility')}
            percentage={simulatorData.targetInfo.data.target.volatility}
          />
          <PortfolioProperties
            description={t('liquidityTooltip')}
            property={t('liquidity')}
            percentage={simulatorData.targetInfo.data.target.liquidity}
          />
          {!isMobileDevice ? (
            <div className={`${CLASS_PREFIX}__actions-desktop`}>
              <Actions
                onSendAdvised={handleSendAdvised}
                onSendResults={handleSendResults}
                onTryAgain={handleTryAgain}
                target={targetInfoData}
              />
            </div>
          ) : null}
        </div>
        <div
          className={`${CLASS_PREFIX}__content ${CLASS_PREFIX}__content--right`}
        >
          {!isMobileDevice ? (
            <div className="typography-b-1 typography--regular product__description">
              {t(`${product}.description`)}
            </div>
          ) : null}
          <div className={`${CLASS_PREFIX}__content--chart`}>
            <PortfolioChart
              targetInfoData={targetInfoData}
              resultsData={results.data}
            />
          </div>
          <div className="typography-b-1 typography--regular product__disclaimer">
            {t('disclaimer')}
          </div>
          {isMobileDevice ? (
            <div className={`${CLASS_PREFIX}__actions-mobile`}>
              <Actions
                onSendAdvised={handleSendAdvised}
                onSendResults={handleSendResults}
                onTryAgain={handleTryAgain}
                target={targetInfoData}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

Results.propTypes = {};

Results.defaultProps = {};

export default Results;
