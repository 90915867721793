const sharedFieldProps = {
  type: 'text',
  required: true,
};

const STRATEGY_NAME_REGULAR_EXPRESSION =
  /(?!.*(true|false))^[^`~,.<>;*':"|{}()=_+\-¿?¡!&/\\#%[\]@^“”‘’]+$/;

export const THIRD_QUESTION_KEY = [
  'target_characteristics_final_amount_short_term',
  'target_characteristics_final_amount',
  'target_characteristics_expense_amount',
  'target_characteristics_expense_amount_short_term',
  'target_characteristics_retirement_amount',
  'target_characteristics_initial_amount',
];

export const FIRST_QUESTION_KEY = 'target_characteristics_term';

export const QUESTION_TO_NAME_KEY = [
  'target_characteristics_type',
  'target_characteristics_type_short',
];

export const STATIC_QUESTIONS = {
  v1: [
    {
      question_id: '00000000-0000-0000-0000-000000000000',
      question_key: 'strategy_name',
      description: 'Tu razón principal para invertir es',
      metadata: {
        placeholder: 'Ej. "Imprevistos"',
        regular_expression: STRATEGY_NAME_REGULAR_EXPRESSION,
        max_length: 20,
        question_data_type: 'name',
        notes: '',
      },
      choice_answer: [],
      question_type: 'input',
      value: '¿Qué nombre le darías a esta estrategia?',
    },
  ],
};

export const MOCK_ANSWERS_ARRAY = [
  {
    answer_id: 1,
    value: '1',
  },
  {
    answer_id: 2,
    value: '2',
  },
];

export const FIRST_TIME_QUESTIONNAIRE = {
  v2: [
    {
      question_id: '0ebcefd8-88f7-41b6-a1fa-119a4885aa55',
      question_key: '',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '174f7eac-218f-42c1-b589-5df62f81afc8',
      previous_question_id: '',
      next_question_id: '',
      choice_answer: [
        {
          answer_id: '42e1d262-c5f8-4810-9404-d2a312da2486',
          value: 'Tener un fondo para emergencias',
          points: 0,
        },
        {
          answer_id: '34c91a5a-660c-4884-92b6-83bb7a1880ba',
          value: 'Hacer un gasto importante',
        },
        {
          answer_id: 'f483b964-c229-4175-a997-eb23b6a08f6c',
          value: 'La educación de mis hijos',
        },
        {
          answer_id: 'f483b964-c229-4175-a997-eb23b6a08d6c',
          value: 'Ahorrar para el retiro',
        },
        {
          answer_id: 'f483b964-c229-4175-a997-eb23b6a08f6a',
          value: 'Construir mi patrimonio',
        },
        {
          answer_id: 'f483b964-c229-4175-a997-eb23b6a08f3c',
          value: 'Incrementar mi patrimonio',
        },
      ],
      question_type: 'select',
      is_related: false,
      value: '¿Cuál es el objetivo para esta estrategia?',
      is_end_of_section: true,
    },
    {
      question_id: '13aea7d7-7bc6-4136-af46-4c87417c893c',
      question_key: 'target_characteristics_final_amount',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '0bdf060b-6e6d-43f6-81a1-e481a4dd33b4',
      previous_question_id: '97b924d2-0736-4f5f-b8f5-b3681dd48451',
      next_question_id: '25000c1d-ca86-4a3e-86e0-bedf3d80f99f',
      metadata: {
        placeholder: '00,000',
        regular_expression: 'aqui-va-la-expresion-regular',
        maxlenght: 15,
        question_data_type: 'amount',
        notes: '',
      },
      question_type: 'input',
      is_related: false,
      value:
        '¿Cuál es el monto que deseas alcanzar para hacer tu gasto importante?',
      is_end_of_section: false,
    },
    {
      question_id: '13aea7d7-7bc6-4136-af46-4c87417c893c',
      question_key: 'target_characteristics_final_amount',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '0bdf060b-6e6d-43f6-81a1-e481a4dd33b4',
      previous_question_id: '97b924d2-0736-4f5f-b8f5-b3681dd48451',
      next_question_id: '25000c1d-ca86-4a3e-86e0-bedf3d80f99f',
      metadata: {
        placeholder: '00,000',
        regular_expression: 'aqui-va-la-expresion-regular',
        maxlenght: 15,
        question_data_type: 'range',
        notes: '',
      },
      question_type: 'input',
      is_related: false,
      value: '¿En cuánto tiempo vas a utilizar tu dinero? ',
      is_end_of_section: false,
    },
    {
      question_id: '13aea7d7-7bc6-4136-af46-4c87417c893c',
      question_key: 'target_characteristics_final_amount',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '0bdf060b-6e6d-43f6-81a1-e481a4dd33b4',
      previous_question_id: '97b924d2-0736-4f5f-b8f5-b3681dd48451',
      next_question_id: '25000c1d-ca86-4a3e-86e0-bedf3d80f99f',
      description:
        'No se hará ningún cargo al crear tu estrategia, este dato sólo es informativo',
      metadata: {
        placeholder: '00,000',
        regular_expression: 'aqui-va-la-expresion-regular',
        maxlenght: 15,
        question_data_type: 'amount',
        notes: '',
      },
      question_type: 'input',
      is_related: false,
      value: '¿Con qué monto deseas iniciar?',
      is_end_of_section: false,
    },
    {
      question_id: '13aea7d7-7bc6-4136-af46-4c87417c893c',
      question_key: 'target_characteristics_final_amount',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '0bdf060b-6e6d-43f6-81a1-e481a4dd33b4',
      previous_question_id: '97b924d2-0736-4f5f-b8f5-b3681dd48451',
      next_question_id: '25000c1d-ca86-4a3e-86e0-bedf3d80f99f',
      description:
        'No se hará ningún cargo al crear tu estrategia, este dato sólo es informativo',
      metadata: {
        placeholder: '00,000',
        regular_expression: 'aqui-va-la-expresion-regular',
        maxlenght: 15,
        question_data_type: 'amount',
        notes: '',
      },
      question_type: 'input',
      is_related: false,
      value:
        'Además del monto inicial, ¿cuánto te gustaría aportar mensualmente?',
      is_end_of_section: false,
    },
    {
      question_id: '13aea7d7-7bc6-4136-af46-4c87417c893c',
      question_key: 'target_characteristics_final_amount',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '0bdf060b-6e6d-43f6-81a1-e481a4dd33b4',
      previous_question_id: '97b924d2-0736-4f5f-b8f5-b3681dd48451',
      next_question_id: '25000c1d-ca86-4a3e-86e0-bedf3d80f99f',
      metadata: {
        placeholder: '0',
        regular_expression: 'aqui-va-la-expresion-regular',
        maxlenght: 15,
        question_data_type: 'age',
      },
      question_type: 'input',
      is_related: false,
      value: '¿Cuántos años tienes?',
      is_end_of_section: false,
    },
    {
      question_id: '13aea7d7-7bc6-4136-af46-4c87417c893c',
      question_key: 'target_characteristics_final_amount',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '0bdf060b-6e6d-43f6-81a1-e481a4dd33b4',
      previous_question_id: '97b924d2-0736-4f5f-b8f5-b3681dd48451',
      next_question_id: '25000c1d-ca86-4a3e-86e0-bedf3d80f99f',
      description: 'Es el monto total antes de impuestos y otras deducciones',
      metadata: {
        placeholder: '0',
        regular_expression: 'aqui-va-la-expresion-regular',
        maxlenght: 15,
        question_data_type: 'amount',
      },
      question_type: 'input',
      is_related: false,
      value: '¿Cuál es tu ingreso anual bruto?',
      is_end_of_section: false,
    },
    {
      question_id: '13aea7d7-7bc6-4136-af46-4c87417c893c',
      question_key: 'target_characteristics_final_amount',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '0bdf060b-6e6d-43f6-81a1-e481a4dd33b4',
      previous_question_id: '97b924d2-0736-4f5f-b8f5-b3681dd48451',
      next_question_id: '25000c1d-ca86-4a3e-86e0-bedf3d80f99f',
      description:
        'Es la suma de todos tus activos (casa, propiedades, ahorros), menos el monto de tus deudas ',
      metadata: {
        placeholder: '0',
        regular_expression: 'aqui-va-la-expresion-regular',
        maxlenght: 15,
        question_data_type: 'amount',
      },
      question_type: 'input',
      is_related: false,
      value: '¿Cuál es el valor de tu patrimonio?',
      is_end_of_section: false,
    },
    {
      question_id: '13aea7d7-7bc6-4136-af46-4c87417c893c',
      question_key: 'target_characteristics_final_amount',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '0bdf060b-6e6d-43f6-81a1-e481a4dd33b4',
      previous_question_id: '97b924d2-0736-4f5f-b8f5-b3681dd48451',
      next_question_id: '25000c1d-ca86-4a3e-86e0-bedf3d80f99f',
      metadata: {
        placeholder: '0',
        regular_expression: 'aqui-va-la-expresion-regular',
        maxlenght: 15,
        question_data_type: 'int',
        notes: 'Escribe 0 si no es tu caso',
      },
      question_type: 'input',
      is_related: false,
      value: '¿Cuántos dependientes económicos tienes?',
      is_end_of_section: false,
    },
    {
      question_id: '0ebcefd8-88f7-41b6-a1fa-119a4885aa55',
      question_key: '',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '174f7eac-218f-42c1-b589-5df62f81afc8',
      previous_question_id: '',
      next_question_id: '',
      choice_answer: [
        {
          answer_id: '42e1d262-c5f8-4810-9404-d2a312da2486',
          value: 'Muy irregulares',
          points: 0,
        },
        {
          answer_id: '3848dcc7-8413-4b0e-9ee4-56d8bf8e9361',
          value: 'Irregulares',
          points: 1,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Algo estables',
          points: 3,
        },
        {
          answer_id: 'b23f7662-cdca-43bc-b23b-dc46813bbc21',
          value: 'Estables',
          points: 5,
        },
        {
          answer_id: '41c34d0b-a9d1-4b8a-afb4-6c62bb7c7fd8',
          value: 'Muy estables',
          points: 7,
        },
      ],
      question_type: 'select',
      is_related: false,
      value: '¿Cómo definirías tus ingresos?',
      is_end_of_section: true,
    },
    {
      question_id: '0ebcefd8-88f7-41b6-a1fa-119a4885aa55',
      question_key: '',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '174f7eac-218f-42c1-b589-5df62f81afc8',
      previous_question_id: '',
      next_question_id: '',
      choice_answer: [
        {
          answer_id: '42e1d262-c5f8-4810-9404-d2a312da2486',
          value: 'Mis ingresos disminuirán',
          points: 0,
        },
        {
          answer_id: '3848dcc7-8413-4b0e-9ee4-56d8bf8e9361',
          value: 'Mis ingresos se mantendrán iguales',
          points: 1,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Mis ingresos aumentarán',
          points: 3,
        },
      ],
      question_type: 'select',
      is_related: false,
      value: '¿Cómo visualizas tus ingresos en el futuro?',
      is_end_of_section: true,
    },
    {
      question_id: '0ebcefd8-88f7-41b6-a1fa-119a4885aa55',
      question_key: '',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '174f7eac-218f-42c1-b589-5df62f81afc8',
      previous_question_id: '',
      next_question_id: '',
      choice_answer: [
        {
          answer_id: '42e1d262-c5f8-4810-9404-d2a312da2486',
          value: 'Desconozco',
          points: 0,
        },
        {
          answer_id: '3848dcc7-8413-4b0e-9ee4-56d8bf8e9361',
          value: 'Muy poco',
          points: 1,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Mi conocimiento es básico',
          points: 3,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Entiendo muy bien',
          points: 3,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Soy experto',
          points: 3,
        },
      ],
      question_type: 'select',
      is_related: false,
      value: '¿Qué tanto conoces sobre inversiones?',
      is_end_of_section: true,
    },
    {
      question_id: '0ebcefd8-88f7-41b6-a1fa-119a4885aa55',
      question_key: '',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '174f7eac-218f-42c1-b589-5df62f81afc8',
      previous_question_id: '',
      next_question_id: '',
      choice_answer: [
        {
          answer_id: '42e1d262-c5f8-4810-9404-d2a312da2486',
          value: 'Fondos de inversión',
          points: 0,
        },
        {
          answer_id: '3848dcc7-8413-4b0e-9ee4-56d8bf8e9361',
          value: 'ETFs',
          points: 1,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Acciones',
          points: 3,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Bonos',
          points: 3,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'CETES',
          points: 3,
        },
      ],
      question_type: 'multiple_select',
      is_related: false,
      value: '¿En cuál de estos instrumentos has invertido?',
      is_end_of_section: true,
    },
    {
      question_id: '0ebcefd8-88f7-41b6-a1fa-119a4885aa55',
      question_key: '',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '174f7eac-218f-42c1-b589-5df62f81afc8',
      previous_question_id: '',
      next_question_id: '',
      choice_answer: [
        {
          answer_id: '42e1d262-c5f8-4810-9404-d2a312da2486',
          value: 'Desconozco',
          points: 0,
        },
        {
          answer_id: '3848dcc7-8413-4b0e-9ee4-56d8bf8e9361',
          value: 'Entiendo muy poco',
          points: 1,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Entiendo lo básico',
          points: 3,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Entiendo bien',
          points: 3,
        },
      ],
      question_type: 'select',
      is_related: false,
      value: '¿Qué tanto conoces sobre Fondos de inversión?',
      is_end_of_section: true,
    },
    {
      question_id: '0ebcefd8-88f7-41b6-a1fa-119a4885aa55',
      question_key: '',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '174f7eac-218f-42c1-b589-5df62f81afc8',
      previous_question_id: '',
      next_question_id: '',
      choice_answer: [
        {
          answer_id: '42e1d262-c5f8-4810-9404-d2a312da2486',
          value: 'Peligro',
          points: 0,
        },
        {
          answer_id: '3848dcc7-8413-4b0e-9ee4-56d8bf8e9361',
          value: 'Incertidumbre',
          points: 1,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Oportunidad',
          points: 3,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Emoción',
          points: 3,
        },
      ],
      question_type: 'select',
      is_related: false,
      value: 'Cuando escuchas la palabra “riesgo”, ¿en qué piensas?',
      is_end_of_section: true,
    },
    {
      question_id: '0ebcefd8-88f7-41b6-a1fa-119a4885aa55',
      question_key: '',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '174f7eac-218f-42c1-b589-5df62f81afc8',
      previous_question_id: '',
      next_question_id: '',
      choice_answer: [
        {
          answer_id: '42e1d262-c5f8-4810-9404-d2a312da2486',
          value: 'Siempre en las pérdidas',
          points: 0,
        },
        {
          answer_id: '3848dcc7-8413-4b0e-9ee4-56d8bf8e9361',
          value: 'En las posibles pérdidas',
          points: 1,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'En las posibles ganancias',
          points: 3,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Siempre en las ganacias ',
          points: 3,
        },
      ],
      question_type: 'select',
      is_related: false,
      value: 'Al tomar decisiones de inversión, ¿en qué te enfocas?',
      is_end_of_section: true,
    },
    {
      question_id: '0ebcefd8-88f7-41b6-a1fa-119a4885aa55',
      question_key: '',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '174f7eac-218f-42c1-b589-5df62f81afc8',
      previous_question_id: '',
      next_question_id: '',
      choice_answer: [
        {
          answer_id: '42e1d262-c5f8-4810-9404-d2a312da2486',
          value: 'Me es muy difícil',
          points: 0,
        },
        {
          answer_id: '3848dcc7-8413-4b0e-9ee4-56d8bf8e9361',
          value: 'Me es algo díficil',
          points: 1,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Me es algo fácil',
          points: 3,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Me es muy fácil',
          points: 3,
        },
      ],
      question_type: 'select',
      is_related: false,
      value:
        'En temas financieros, ¿qué tan fácil te adaptas cuando algo sale mal?',
      is_end_of_section: true,
    },
    {
      question_id: '0ebcefd8-88f7-41b6-a1fa-119a4885aa55',
      question_key: '',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '174f7eac-218f-42c1-b589-5df62f81afc8',
      previous_question_id: '',
      next_question_id: '',
      choice_answer: [
        {
          answer_id: '42e1d262-c5f8-4810-9404-d2a312da2486',
          value: 'No me gusta el riesgo',
          points: 0,
        },
        {
          answer_id: '3848dcc7-8413-4b0e-9ee4-56d8bf8e9361',
          value: 'No me molestan las fluctuaciones a corto plazo',
          points: 1,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Estoy preparado para movimientos mayores del mercado',
          points: 3,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Estoy dispuesto a asumir fluctuaciones significativas',
          points: 3,
        },
      ],
      question_type: 'select',
      is_related: false,
      value: 'Al invertir, ¿cuál enunciado refleja tu actitud sobre el riesgo?',
      is_end_of_section: true,
    },
    {
      question_id: '0ebcefd8-88f7-41b6-a1fa-119a4885aa55',
      question_key: 'percents',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '174f7eac-218f-42c1-b589-5df62f81afc8',
      previous_question_id: '',
      next_question_id: '',
      choice_answer: [
        {
          answer_id: '42e1d262-c5f8-4810-9404-d2a312da2486',
          value: '3% - 8%',
          points: 0,
        },
        {
          answer_id: '3848dcc7-8413-4b0e-9ee4-56d8bf8e9361',
          value: '-5% - 18%',
          points: 1,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: '-12% - 27%',
          points: 3,
        },
        {
          answer_id: 'b23f7662-cdca-43bc-b23b-dc46813bbc21',
          value: '-20% - 35%',
          points: 5,
        },
        {
          answer_id: '41c34d0b-a9d1-4b8a-afb4-6c62bb7c7fd8',
          value: '-28% - 46%',
          points: 7,
        },
        {
          answer_id: '99ad61ab-7e0d-437e-9266-dc2ae94710b4',
          value: '-38% - 55%',
          points: 9,
        },
      ],
      question_type: 'select',
      is_related: false,
      value: '¿Con qué rango de perdida y ganancia te sentirías cómodo?',
      description:
        'Las inversiones suben y bajan durante el día, lo importante es respetar el plazo de tu estrategia para asegurar un resultado positivo',
      is_end_of_section: true,
    },
    {
      question_id: '0ebcefd8-88f7-41b6-a1fa-119a4885aa55',
      question_key: '',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '174f7eac-218f-42c1-b589-5df62f81afc8',
      previous_question_id: '',
      next_question_id: '',
      choice_answer: [
        {
          answer_id: '42e1d262-c5f8-4810-9404-d2a312da2486',
          value: 'Vendo toda mi inversión',
          points: 0,
        },
        {
          answer_id: '3848dcc7-8413-4b0e-9ee4-56d8bf8e9361',
          value: 'Vendo una parte de mi inversión',
          points: 1,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Mantengo mi inversión, no hago nada',
          points: 3,
        },
        {
          answer_id: '6aa2a3a8-d14f-4f0f-8932-2015a6d2e17b',
          value: 'Compro más',
          points: 3,
        },
      ],
      question_type: 'select',
      is_related: false,
      value:
        '¿Qué harías si tu inversión perdiera más del 20% de su valor en 3 meses?',
      is_end_of_section: true,
    },
    /* {
      question_id: '13aea7d7-7bc6-4136-af46-4c87417c893c',
      question_key: 'target_characteristics_final_amount',
      questionnaire_id: '5b4f25e8-98af-46bf-96d4-781a215a2188',
      organization_id: 'e4c82a64-9bac-45a6-94ab-0af6352f01cb',
      section_id: '0bdf060b-6e6d-43f6-81a1-e481a4dd33b4',
      previous_question_id: '97b924d2-0736-4f5f-b8f5-b3681dd48451',
      next_question_id: '25000c1d-ca86-4a3e-86e0-bedf3d80f99f',
      metadata: {
        placeholder: '00,000',
        regular_expression: 'aqui-va-la-expresion-regular',
        maxlenght: 15,
        question_data_type: 'amount',
        notes: '',
      },
      question_type: 'input',
      is_related: false,
      value: '¿Qué monto deseas alcanzar?',
      is_end_of_section: false,
    },
    {
      id: '0ec24244-44f2-4747-b4f3-dcda2c23b3bb',
      value: '¿Qué nombre le darías a esta estrategia?',
      previous_question_id: 'a9d06d19-37fd-4766-a492-f65b742b4f15',
      next_question_id: 'a9d06d19-37fd-4766-a492-f65b742b4f15',
      section_id: '245fcfef-b0b4-4ae5-a4b1-d8454e45eb78',
      kind: 'text',
      description:
        'Tu razón principal para invertir es Tener un fondo para emergencia',
      metadata: {
        type: 'text',
        maxlenght: '20',
        data: 'name',
        placeholder: 'Ej. Imprevistos',
      },
    },
    {
      id: '0ec24244-44f2-4747-b4f3-dcda2c23b3ba',
      value: '¿Qué monto deseas alcanzar?',
      previous_question_id: '0ec24244-44f2-4747-b4f3-dcda2c23b3bb',
      next_question_id: 'a9d06d19-37fd-4766-a492-f65b742b4f15',
      section_id: '245fcfef-b0b4-4ae5-a4b1-d8454e45eb78',
      kind: 'text',
      metadata: {
        regex: '^[1-9]\\d*$',
        type: 'int',
        input: 'Monto:',
        data: 'range',
        maxlenght: '12',
        placeholder: '0,00',
      },
    },
    {
      id: '0ec24244-44f2-4747-b4f3-dcda2c23a3ba',
      value: '¿A qué edad te gustaría retirarte?',
      previous_question_id: '0ec24244-44f2-3747-b4f3-dcda2c23b3bb',
      next_question_id: 'a9d06d19-37fd-4766-a492-f65b742b4f15',
      section_id: '245fcfef-b0b4-4ae5-a4b1-d8454e45eb78',
      kind: 'text',
      metadata: {
        regex: '^[1-9]\\d*$',
        type: 'int',
        input: 'Monto:',
        data: 'age',
        maxlenght: '12',
        placeholder: '0',
      },
    },
    {
      id: '0ec24244-44f2-4747-b4f3-dcda2c23a3ba',
      value:
        'Además del monto inicial, ¿Cuánto te gustaría aportar mensualmente?',
      previous_question_id: '0ec24244-44f2-3747-b4f3-dcda2c23b3bb',
      next_question_id: 'a9d06d19-37fd-4766-a492-f65b742b4f15',
      section_id: '245fcfef-b0b4-4ae5-a4b1-d8454e45eb78',
      kind: 'text',
      description:
        'No se hará ningún cargo al crear tu estrategia, este dato sólo es informativo',
      isLastQuestion: true,
      metadata: {
        regex: '^[1-9]\\d*$',
        type: 'int',
        input: 'Monto:',
        data: 'amount',
        maxlenght: '12',
        placeholder: '0,00',
      },
    }, */
  ],
  v1: [
    {
      ...sharedFieldProps,
      name: 'experienceInvesting',
      type: 'options',
      options: [
        { value: 1, label: 'Soy experto' },
        { value: 2, label: 'Entiendo bien los conceptos' },
        { value: 3, label: 'Entiendo lo básico sobre estos conceptos' },
        { value: 4, label: 'Nulo entendimiento' },
      ],
      className: 'experience-investing-fix',
      kind: 'dual',
      title:
        'Mi entendimiento sobre fondos de inversión, bonos, acciones y ETFs es:',
    },
    {
      ...sharedFieldProps,
      name: 'whyAreYouInvesting',
      type: 'options',
      big: true,
      options: [
        {
          value: 1,
          label: 'Construir tu patrimonio',
          description:
            'Si estás pensando en comprar una vivienda, poner un negocio o cualquier otra inversión que te ayude a construir día a día tu vida.',
        },
        {
          value: 2,
          label: 'Ahorrar para el retiro',
          description:
            'Pensar en el futuro es importante, por eso en GBM queremos ayudarte a lograr tu meta de un retiro sin preocupaciones.',
        },
        {
          value: 3,
          label: 'Un fondo para emergencias',
          description:
            'Nunca sabemos lo que puede pasar, es importante tener un colchón para ayudarte en caso de imprevistos.',
        },
        {
          value: 4,
          label: 'Hacer un gasto importante',
          description:
            'Siempre existen compras que se salen un poco del presupuesto de todos los días: comprar un coche, un viaje por el mundo, una maestría, etc.',
        },
        {
          value: 5,
          label: 'Educación para tus hijos',
          description:
            'Tu familia es lo más importante y una buena educación es la forma de ofrecerles un mejor futuro.',
        },
        {
          value: 6,
          label: 'Invertir en general',
          description:
            'Cuando eres ahorrador, tienes un ingreso extra o no quieres que tu dinero se quede en el banco sin ganar.',
        },
      ],
      className: 'investment-reason',
      kind: 'dual',
      title: '¿Cuál es tu principal razón para invertir?',
    },
    {
      ...sharedFieldProps,
      name: 'initialAmount',
      maxLength: 11,
      isCurrency: true,
      placeholder: '0,00',
      className: 'initial-amount-fix',
      kind: 'single',
      title: '¿Con qué monto te gustaría empezar?',
      info: '<strong>No se te hará ningún cargo al crear tu estrategia, este dato solo es informativo</strong> pero mientras mayor sea el monto con el que empieces, más cerca estarás de tu objetivo.',
    },
    {
      ...sharedFieldProps,
      name: 'monthlyDeposit',
      isNumber: true,
      isCurrency: true,
      maxLength: 8,
      placeholder: '0,00',
      className: 'monthly-income',
      kind: 'single',
      title: 'Además de tu inversión inicial, ¿cuánto quieres aportar al mes?',
      info: '<strong>No es forzoso invertir mes a mes</strong>, sin embargo, invertir recurrentemente aumenta la probabilidad de obtener mayores beneficios en el tiempo.',
    },
    {
      ...sharedFieldProps,
      name: 'investmentTermYears',
      isNumber: true,
      maxLength: 2,
      isYears: true,
      placeholder: '0',
      className: 'investment-years',
      kind: 'single',
      title: '¿Cuánto tiempo quieres que dure tu inversión?',
      info: 'La recomendación de tu portafolio de inversión depende del <strong>plazo de tiempo</strong> que elijas.',
      subtitle:
        'Recuerda que nuestras estrategias no tienen plazos forzosos y puedes disponer del dinero en cualquier momento.',
    },
    {
      ...sharedFieldProps,
      name: 'monthlyIncome',
      type: 'options',
      options: [
        { value: 1, label: 'Menor a $40 mil pesos' },
        { value: 2, label: 'De $40 mil a $80 mil pesos' },
        { value: 3, label: 'De $80 mil a $130 mil pesos' },
        { value: 4, label: 'De $130 mil a $200 mil pesos' },
        { value: 5, label: 'Mayor a $200 mil pesos' },
      ],
      className: 'monthly-income',
      kind: 'dual',
      title: '¿Cuál es tu ingreso mensual?',
    },
    {
      ...sharedFieldProps,
      name: 'totalAssets',
      type: 'options',
      options: [
        { value: 1, label: 'Menor a $500 mil pesos' },
        { value: 2, label: 'De $500 mil a $2 millones de pesos' },
        { value: 3, label: 'De $2 millones a $5 millones de pesos' },
        { value: 4, label: 'De $5 millones a $10 millones de pesos' },
        { value: 5, label: 'Mayor a $10 millones de pesos' },
      ],
      className: 'total-assets',
      kind: 'dual',
      title:
        '¿Cuál es el valor de tu patrimonio (casa, coche, cuentas, inversiones, etc.)?',
    },
    {
      ...sharedFieldProps,
      name: 'winOrLose',
      type: 'options',
      options: [
        { value: 100, label: 'Ganar es prioridad' },
        { value: 1, label: 'Evitar pérdidas es prioridad' },
        {
          value: 50,
          label: 'Ganar y evitar pérdidas son igual de importantes',
        },
      ],
      className: 'win-lose',
      kind: 'dual',
      title:
        'Cuando decides cómo invertir tu dinero, ¿qué es mas importante para ti?',
    },
    {
      ...sharedFieldProps,
      name: 'portfolioLoss',
      type: 'options',
      options: [
        { value: 1, label: 'Vendería toda mi inversión' },
        { value: 33, label: 'Vendería parte de mi inversión' },
        { value: 67, label: 'Dejo todo igual, no haría nada' },
        { value: 100, label: 'Invertiría más' },
      ],
      className: 'portfolio-loss',
      kind: 'dual',
      title:
        'Imagina que en el próximo mes tu portafolio disminuye su valor total en un 10%, ¿qué harías?',
    },
    {
      ...sharedFieldProps,
      name: 'fallPercentage',
      type: 'options',
      medium: true,
      options: [
        {
          value: 1,
          label:
            '<span class="positive">4%</span> - <span class="positive">8%</span>',
          ariaLabel: 'De 4 por ciento a 8 por ciento',
        },
        {
          value: 20,
          label:
            '<span class="negative">-3%</span> - <span class="positive">16%</span>',
          ariaLabel: 'De menos 3 por ciento a 16 por ciento',
        },
        {
          value: 40,
          label:
            '<span class="negative">-12%</span> - <span class="positive">26%</span>',
          ariaLabel: 'De menos 12 por ciento a 26 por ciento',
        },
        {
          value: 60,
          label:
            '<span class="negative">-21%</span> - <span class="positive">35%</span>',
          ariaLabel: 'De menos 21 por ciento a 35 por ciento',
        },
        {
          value: 80,
          label:
            '<span class="negative">-30%</span> - <span class="positive">45%</span>',
          ariaLabel: 'De menos 30 por ciento a 45 por ciento',
        },
        {
          value: 100,
          label:
            '<span class="negative">-40%</span> - <span class="positive">55%</span>',
          ariaLabel: 'De menos 40 por ciento a 55 por ciento',
        },
      ],
      className: 'fall-percentage-fix',
      kind: 'dual',
      info: '<strong>Recuerda que las inversiones suelen subir o bajar en el día a día</strong>, lo importante es respetar el plazo de tu estrategia para asegurar un resultado positivo.',
      title:
        '¿Con qué rango de ganancia o pérdida en tu inversión te sentirías tranquilo?',
    },
  ],
};

export const SECOND_TIME_QUESTIONNAIRE = {
  v1: FIRST_TIME_QUESTIONNAIRE.v1.slice(1, 5),
};

export const ANSWERS_INVESTMENT_INSTRUMENTS = {
  'ddb5c1e6-daa7-41d7-ab35-6d1cb55477b0': {
    value: 'Fondos de inversión',
  },
  '9fd49232-ea9a-4f1a-b825-5c3afea0890c': {
    value: 'ETFs',
  },
  '42a28b8f-a249-4ed7-968b-03f66ff630d8': {
    value: 'Acciones',
  },
  'e986d76e-5b69-4bf4-a3b2-8335a73bdfc6': {
    value: 'Bonos',
  },
  '5c5ac264-f0f4-475a-b4a4-2d8e51cb542b': {
    value: 'CETES',
  },
};
