import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import config from 'config';
import isEmpty from 'lodash/isEmpty';
import store from 'store';
import { useSelector } from 'react-redux';

import logo from 'images/atm-logo-positive-gbm.svg';

import Button from '../Button';

const { GBM_ADVISOR_STATE } = config();
const CLASS_PREFIX = 'welcome-screen';

const WelcomeScreen = ({ onCloseRedirect, onStartQuestionnaire }) => {
  const { t } = useTranslation('welcome');
  const name = store.get(GBM_ADVISOR_STATE)?.name;
  const questionnaire = useSelector((state) => state.questionnaire);

  return (
    <div className={CLASS_PREFIX} data-testid="welcome-screen-test">
      <div className={`${CLASS_PREFIX}__close`}>
        <img src={logo} alt="GBM+" width="100" height="21" />
        <button
          type="button"
          aria-label="Close"
          onClick={onCloseRedirect}
          className={`${CLASS_PREFIX}__close-button`}
        />
      </div>
      <div className={`${CLASS_PREFIX}__container`}>
        <div className={`${CLASS_PREFIX}__container-icon`}>
          <div className="mark mark__wealth mark--animated">
            <div className="half-circle">
              <div className="half">
                <div className="fill" />
              </div>
            </div>
            <div className="half-circle">
              <div className="half">
                <div className="fill" />
              </div>
            </div>
          </div>
        </div>
        <div className={`${CLASS_PREFIX}__container-mark`}>{t('wealth')}</div>
        <h1 className="typography-2 typography--regular font-mabry">
          {!isEmpty(name) ? t('helloUser', { name }) : t('hello')}
        </h1>
        <div className={`${CLASS_PREFIX}__container-message`}>{t('intro')}</div>
        <div className={`${CLASS_PREFIX}__container-controls`}>
          <Button
            kind="dark"
            size="small"
            testId="start-questionnaire"
            disabled={isEmpty(questionnaire.currentQuestion.data)}
            onClick={onStartQuestionnaire}
            text={t('continue')}
          />
        </div>
      </div>
    </div>
  );
};

WelcomeScreen.propTypes = {
  onCloseRedirect: PropTypes.func,
  onStartQuestionnaire: PropTypes.func,
};

export default WelcomeScreen;
