import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { SimulatorProvider } from './Context';

import PersonalInfo from './PersonalInfo';
import TargetInfo from './TargetInfo';
import Results from './Results';

import { BASE_PATH } from './constants';

const Simulator = () => {
  const { t } = useTranslation('simulator');
  return (
    <SimulatorProvider>
      <>
        <Helmet>
          <title>{t('metadata.title')}</title>
          <meta name="description" content={t('metadata.description')} />s
        </Helmet>
        <Switch>
          <Route exact path={BASE_PATH}>
            <TargetInfo />
          </Route>
          <Route exact path={`${BASE_PATH}/personal-info`}>
            <PersonalInfo />
          </Route>
          <Route exact path={`${BASE_PATH}/results`}>
            <Results />
          </Route>
        </Switch>
      </>
    </SimulatorProvider>
  );
};

export default Simulator;
