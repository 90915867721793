export const HTTP_VERBS = {
  delete: 'DELETE',
  get: 'GET',
  patch: 'PATCH',
  post: 'POST',
  put: 'PUT',
};

export const HTTP_STATUS_CODES = {
  ok: 200,
  created: 201,
  accepted: 202,
  no_content: 204,
  bad_request: 400,
  unauthorized: 401,
  forbidden: 403,
  not_found: 404,
  unprocessable_entity: 422,
  internal_server_error: 500,
  not_implemented: 501,
  bad_gateway: 502,
  service_unavailable: 503,
  gateway_timeout: 504,
};

// export const QUESTIONNAIRE_ID = '5b4f25e8-98af-46bf-96d4-781a215a2188';

export const QUESTIONNAIRE_ID = 'f0bd1177-2f2a-42ab-aca8-93139fe8a000';

export const SIMULATOR_ID = 'c949c5d3-043e-4d61-9b42-7d268f68a07c';

export const SIMULATOR_ID_USD = 'dcc1071d-2b96-4646-8e64-c45129c73625';

export const GFA_DOC_TYPE = 'general_framework_action';

export const LEGAL_DOCUMENT_TYPES = {
  personalIdDataVerification: 'personal_id_data_verification',
  wealthManagement: 'general_framework_action',
};

export const GENERIC_ERROR =
  'Ocurrió un error al procesar la información. Por favor inténtalo más tarde';
