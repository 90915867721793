import React from 'react';
import { useTranslation } from 'react-i18next';

const CLASS_PREFIX = 'advisor-cagr';

const PortfolioCagr = () => {
  const { t } = useTranslation('results');

  return (
    <div className={CLASS_PREFIX}>
      <div className={`${CLASS_PREFIX}--description`}>
        <p>{t('disclaimerFirstText')}</p>
        <p>{t('disclaimer')}</p>
      </div>
    </div>
  );
};

export default PortfolioCagr;
