/* eslint-disable indent */
import { CURRENCY_TYPE, PRODUCT_MAPPER } from 'containers/Simulator/constants';
import { LANGUAGES } from './constants';

/**
 * Format targets list from API to use in Select component
 *
 * @param {array} targetsList
 * @returns {array} targetListFormatted
 */
export const formatTargetsList = (targetsList) =>
  targetsList?.length
    ? targetsList?.map((targetItem) => {
        // eslint-disable-next-line no-unused-vars
        const { target, ...rest } = targetItem;
        return {
          ...rest,
          value: targetItem.id,
          label: targetItem.target,
          currency: CURRENCY_TYPE[targetItem.currency],
        };
      })
    : [];

/**
 * Format API product name from snake_case to camelCase
 *
 * @param {string} product
 * @returns a name product in camelCase
 */
export const productAdapter = (product) => PRODUCT_MAPPER[product];

/**
 *
 * @param {string} lang - language
 * @param {number} value
 * @returns a number formatted as currency
 */
export const formattedCurrency = (lang, value) => {
  const FALLBACK = {
    CURRENCY: lang === LANGUAGES.en ? CURRENCY_TYPE.usd : CURRENCY_TYPE.mxn,
    LOCALE: lang,
  };
  return new Intl.NumberFormat(FALLBACK.LOCALE, {
    style: 'currency',
    currency: FALLBACK.CURRENCY,
    minimumFractionDigits: 0,
  }).format(value);
};
