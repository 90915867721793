import React from 'react';
import Markdown from 'markdown-to-jsx';
import axios from 'axios';
import config from 'config';

const { URLS } = config();

export const markdown2Jsx = async (
  document,
  remote = true,
  markdownOptions = {},
) => {
  let response = {
    data: '',
    err: [],
  };
  const path = remote
    ? `${URLS.documents}/${document}.md`
    : `${URLS.staticDocuments}/${document}.md`;

  try {
    const { data } = await axios.get(path);
    response = {
      ...response,
      data: <Markdown {...markdownOptions}>{data}</Markdown>,
    };
  } catch (err) {
    response = { ...response, err };
  }

  return response;
};
