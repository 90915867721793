import React from 'react';
import PropTypes from 'prop-types';

import Chart from 'views/profilerResultsOld/Chart';
import Table from 'views/profilerResults/Table';
import { PROFILES } from 'catalogs/profiles';

const CLASS_PREFIX = 'advisor-results';

const PortfolioComposition = ({ type, instruments, profileDetail }) => (
  <div className={`${CLASS_PREFIX}__info--old`}>
    <div className={`${CLASS_PREFIX}__profile--old`}>
      <div className={`${CLASS_PREFIX}__profile-type`}>
        <div className={`${CLASS_PREFIX}__profile-title`}>
          {PROFILES?.[type]?.label}
        </div>
        <div className={`${CLASS_PREFIX}__profile-description`}>
          {PROFILES?.[type]?.description}
        </div>
      </div>
      <Chart debt={profileDetail?.debt} equity={profileDetail?.equity} />
    </div>
    <Table table={instruments} />
  </div>
);

PortfolioComposition.defaultProps = {
  type: '',
  instruments: [],
  profileDetail: {},
};

PortfolioComposition.propTypes = {
  type: PropTypes.string,
  instruments: PropTypes.array,
  profileDetail: PropTypes.object,
};

export default PortfolioComposition;
