import React, { memo } from 'react';
import PropTypes from 'prop-types';

const COMPONENT_CLASS = 'back-button';

const BackButton = ({ label, onClick, testId }) => (
  <div className={COMPONENT_CLASS}>
    <button
      type="button"
      aria-label={label}
      onClick={onClick}
      data-testid={testId}
    >
      <div className={`${COMPONENT_CLASS}__icon`} />
      <div className={`${COMPONENT_CLASS}__label`}>{label}</div>
    </button>
  </div>
);

BackButton.defaultProps = {
  label: 'Regresar',
  testId: '',
};

BackButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func,
  testId: PropTypes.string,
};

export default memo(BackButton);
