/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import KEYBOARD_EVENTS from 'catalogs/keyboard';

const CHECKBOX_ROLE = 'checkbox';
const CHECKBOX = 'checkbox';
const CHECKBOX_CONTAINER = 'gbm-checkbox-container';
const CHECKBOX_LABEL = 'checkbox__label';

const Checkbox = ({
  checked,
  disabled,
  onChange,
  stateKey,
  onBlur,
  wrapperClasses,
  hasCard,
  label,
  name,
  value,
  handlePrimary,
  testId,
}) => {
  // Call handleClick when space is pressed
  const handleKey = (e) => {
    if (e.key === ' ') {
      handleClick();
    }

    if (e.key === KEYBOARD_EVENTS.enter.key) {
      handlePrimary();
    }
  };

  // Call toggle function if checkbox isn't disabled
  const handleClick = () => {
    if (!disabled) {
      if (onChange) {
        onChange(stateKey);
      }
    }
  };

  const handleInputBlur = () => {
    if (onBlur) {
      onBlur(stateKey);
    }
  };

  return (
    <div
      className={`${clsx(CHECKBOX_CONTAINER, wrapperClasses)} ${
        hasCard && 'radio--bar'
      }`}
      onClick={handleClick}
    >
      <div className={CHECKBOX}>
        <input
          type={CHECKBOX_ROLE}
          className={`${CHECKBOX}__input`}
          onBlur={handleInputBlur}
          onKeyDown={handleKey}
          onChange={handleClick}
          name={name}
          id={name}
          value={value}
          checked={checked}
          data-testid={testId}
        />
        <label className={CHECKBOX_LABEL} htmlFor={name}>
          {label}
        </label>
      </div>
    </div>
  );
};

Checkbox.defaultProps = {
  disabled: false,
  hasCard: false,
  label: CHECKBOX_ROLE,
  testId: '',
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  hasCard: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  handlePrimary: PropTypes.func,
  stateKey: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  wrapperClasses: PropTypes.string,
  testId: PropTypes.string,
};

export default Checkbox;
