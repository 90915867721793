import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import config from 'config';
import arrowIcn from 'images/atm-icn-arrow.svg';
import lightArrowIcn from 'images/atm-icn-arrow-white-large.svg';
import blogCoffeeImage from 'images/blog-img-1.jpg';
import blogStocksImage from 'images/blog-img-2.jpg';
import blogMapImage from 'images/blog-img-3.jpg';

const env = config();
const { REACT_APP_GBM_BLOG_URL } = env;
const CLASS_PREFIX = 'blog-banner';

const COLOR_SCHEMAS = {
  dark: 'dark',
  light: 'light',
};

const BLOG_ARTICLES = [
  {
    title: 'savingsTitle',
    description: 'savingsDescription',
    image: blogCoffeeImage,
    uri: `${REACT_APP_GBM_BLOG_URL}/ahorro`,
  },
  {
    title: 'marketsTitle',
    description: 'marketsDescription',
    image: blogStocksImage,
    uri: `${REACT_APP_GBM_BLOG_URL}/mercados`,
  },
  {
    title: 'investmentTitle',
    description: 'investmentDescription',
    image: blogMapImage,
    uri: `${REACT_APP_GBM_BLOG_URL}/inversion`,
  },
];

const BlogBanner = ({ colorScheme }) => {
  const { t } = useTranslation('maintenance');
  const lightColorScheme = colorScheme === COLOR_SCHEMAS.light;

  return (
    <div className={CLASS_PREFIX}>
      <div className={`${CLASS_PREFIX}__header`}>
        <div className="title">{t('title')}</div>
        <a
          className={clsx('action', {
            'action--light': lightColorScheme,
          })}
          href={REACT_APP_GBM_BLOG_URL}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('mainActionBtn')}
          <img
            className="arrow"
            data-testid="img-arrow-test"
            src={lightColorScheme ? lightArrowIcn : arrowIcn}
            alt=""
          />
        </a>
      </div>
      <div className={`${CLASS_PREFIX}__body`}>
        {BLOG_ARTICLES.map((article) => (
          <a
            className="item"
            href={article.uri}
            key={article.uri}
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="item__image">
              <img className="thumbnail" src={article.image} alt="" />
            </div>
            <div
              className={clsx('item__description', {
                'item__description--light': lightColorScheme,
              })}
            >
              <div>{t(article.title)}</div>
              <div>{t(article.description)}</div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

BlogBanner.defaultPrps = {
  colorScheme: COLOR_SCHEMAS.dark,
};

BlogBanner.propTypes = {
  colorScheme: PropTypes.oneOf([COLOR_SCHEMAS.dark, COLOR_SCHEMAS.light]),
};

export default BlogBanner;
