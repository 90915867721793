/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const COMPONENT_CLASS = 'help-card';

export const HelpCard = ({ type, icon, message }) => (
  <div
    className={clsx(COMPONENT_CLASS, {
      [`${COMPONENT_CLASS}--${type}`]: type,
    })}
    data-testid="help-card"
  >
    <div
      className={clsx('help-card__icon', {
        [`${COMPONENT_CLASS}__icon--${icon}`]: icon,
      })}
    />
    <div
      className={`${COMPONENT_CLASS}__message`}
      dangerouslySetInnerHTML={{ __html: message }}
    />
  </div>
);

HelpCard.defaultProps = {
  icon: 'info',
};

HelpCard.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
};
