import techImg from 'images/gaming.png';
import investImg from 'images/invest.png';
import earthImg from 'images/earth.png';

export const mockPortfolios = [
  {
    id: 1,
    title: 'Tecnología',
    excerpt: 'Busca una apreciación del capital en el tiempo',
    image: techImg,
    description:
      'Un portafolio de fondos busca una apreciación del capital en el tiempo sacrificando la volatilidad en el corto plazo.',
    data: {
      wtr: 20,
      investment_term: 20,
      portfolio_name: 'Apreciación',
      portfolio_type: 'appreciation',
      instruments: [
        {
          symbol: 'GBMF3',
          percentage: 50,
          instrument_type: 'debt_fund',
        },
        {
          symbol: 'GBMDOL',
          percentage: 50,
          instrument_type: 'equity_fund',
        },
      ],
    },
  },
  {
    id: 2,
    title: 'Fondos GBM+',
    excerpt: 'Busca una apreciación del capital en el tiempo',
    image: investImg,
    description:
      'Un portafolio de fondos busca una apreciación del capital en el tiempo sacrificando la volatilidad en el corto plazo.',
    data: {
      wtr: 20,
      investment_term: 20,
      portfolio_name: 'Apreciación',
      portfolio_type: 'appreciation',
      instruments: [
        {
          symbol: 'GBMF3',
          percentage: 25,
          instrument_type: 'debt_fund',
        },
        {
          symbol: 'GBMDOL',
          percentage: 25,
          instrument_type: 'equity_fund',
        },
        {
          symbol: 'GBMCRE',
          percentage: 25,
          instrument_type: 'equity_fund',
        },
        {
          symbol: 'GBMMOD',
          percentage: 25,
          instrument_type: 'equity_fund',
        },
      ],
    },
  },
  {
    id: 3,
    title: 'Ambiental',
    excerpt: 'Busca una apreciación del capital en el tiempo',
    image: earthImg,
    description:
      'Un portafolio de fondos busca una apreciación del capital en el tiempo sacrificando la volatilidad en el corto plazo.',
    data: {
      wtr: 20,
      investment_term: 20,
      portfolio_name: 'Apreciación',
      portfolio_type: 'appreciation',
      instruments: [
        {
          symbol: 'GBMF3',
          percentage: 100,
          instrument_type: 'debt_fund',
        },
      ],
    },
  },
  {
    id: 4,
    title: 'Tecnología',
    excerpt: 'Busca una apreciación del capital en el tiempo',
    image: techImg,
    description:
      'Un portafolio de fondos busca una apreciación del capital en el tiempo sacrificando la volatilidad en el corto plazo.',
    data: {
      wtr: 20,
      investment_term: 20,
      portfolio_name: 'Apreciación',
      portfolio_type: 'appreciation',
      instruments: [
        {
          symbol: 'GBMF3',
          percentage: 25,
          instrument_type: 'debt_fund',
        },
        {
          symbol: 'GBMDOL',
          percentage: 50,
          instrument_type: 'equity_fund',
        },
        {
          symbol: 'GBMCRE',
          percentage: 25,
          instrument_type: 'equity_fund',
        },
      ],
    },
  },
  {
    id: 5,
    title: 'Fondos GBM+',
    excerpt: 'Busca una apreciación del capital en el tiempo',
    image: investImg,
    description:
      'Un portafolio de fondos busca una apreciación del capital en el tiempo sacrificando la volatilidad en el corto plazo.',
    data: {
      wtr: 20,
      investment_term: 20,
      portfolio_name: 'Apreciación',
      portfolio_type: 'appreciation',
      instruments: [
        {
          symbol: 'GBMF3',
          percentage: 30,
          instrument_type: 'debt_fund',
        },
        {
          symbol: 'GBMDOL',
          percentage: 30,
          instrument_type: 'equity_fund',
        },
        {
          symbol: 'GBMCRE',
          percentage: 30,
          instrument_type: 'equity_fund',
        },
        {
          symbol: 'GBMMOD',
          percentage: 10,
          instrument_type: 'equity_fund',
        },
      ],
    },
  },
];
