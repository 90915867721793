import config from 'config';

import { request } from 'api/utils/request';
import { legalDocumentsAdapter } from 'api/utils/adapter';

import { HTTP_VERBS, QUESTIONNAIRE_ID } from '../constants';

const env = config();
const { API } = env;

const Api = {
  /**
   * GET v1/profiler-questionnaires/{id}
   *
   * @returns a promise
   */
  getQuestionnaires() {
    const url = `${API.v1}/profiler-questionnaires/${QUESTIONNAIRE_ID}`;
    return request(url);
  },

  /**
   * GET /questionnaires/:questionnaireId/questions/:questionId
   *
   * @param {object} questionnaire
   * @param {string} questionId
   * @returns a promise
   */
  getQuestionsById(questionnaire, questionId) {
    const url = `${API.v1}/profiler-questionnaires/${questionnaire.questionnaire_id}/questions/${questionId}`;
    const options = {
      headers: {
        'Profile-Session-Id': questionnaire.profile_session_id,
      },
    };

    return request(url, options);
  },

  /**
   * POST v1/profiler-sessions/{profilerSessionId}/questions/{questionId}
   *
   * @param {object} questionnaire
   * @param {string} questionId
   * @param {object} answer
   * @returns a promise
   */
  saveAnswers(questionnaire, questionId, isEnabledFeatureFlag, answer) {
    const url = `${isEnabledFeatureFlag ? API.v2 : API.v1}/profiler-sessions/${
      questionnaire.profile_session_id
    }/questions/${questionId}`;
    const options = {
      method: HTTP_VERBS.post,
      data: answer,
    };

    return request(url, options);
  },

  /**
   * GET v1/profiler-matrix-points/{matrixPoint}/portfolio-recommendation
   *
   * @param {string} matrixPoint - {x,y}
   * @returns a promise
   */
  getRecommendation(matrixPoint) {
    const url = `${API.v1}/profiler-matrix-points/${matrixPoint}/portfolio-recommendation`;
    return request(url);
  },

  /**
   * POST v1/contracts/{{contract-id}}/accounts/{{account-id}}/reprofile
   * @param {object} contractId
   * @param {string} accountId
   * @param {object} body
   * @returns a promise
   */
  saveReprofile(contractId, accountId, reprofileData) {
    const url = `${API.v1}/contracts/${contractId}/accounts/${accountId}/reprofile`;
    const options = {
      method: HTTP_VERBS.post,
      data: reprofileData,
    };
    return request(url, options);
  },

  /**
   * GET v1/contracts/{contractId}/accounts/{accountId}/profile-session-questionnaire
   *
   * @param {string} contractId
   * @param {string} accountId
   * @returns a promise
   */
  getLastQuestionnaire(accountId, contractId) {
    const url = `${API.v1}/contracts/${contractId}/accounts/${accountId}/profile-session-questionnaire`;
    return request(url);
  },

  /**
   * GET v1/contracts/{contractId}/accounts/{accountId}/reprofile
   *
   * @param {string} contractId
   * @param {string} accountId
   * @returns a promise
   */
  getReprofile(accountId, contractId) {
    const url = `${API.v1}/contracts/${contractId}/accounts/${accountId}/reprofile`;
    const options = {
      headers: {
        'Application-Name': 'gbm-plus-v2',
      },
    };
    return request(url, options);
  },

  /**
   * GET v1/contracts/{contractId}/accounts/{accountId}/profile-details
   *
   * @param {string} contractId
   * @param {string} accountId
   * @returns a promise
   */
  getProfileDetails(accountId, contractId) {
    const url = `${API.v1}/contracts/${contractId}/accounts/${accountId}/profile-details`;
    return request(url);
  },

  /**
   * POST /contracts/:contractId/accounts
   *
   * @param {string} account Account information
   * @return {Promise<object>} Returns a request object, a Promise.
   */
  saveAccount(contractId, account) {
    const url = `${API.v2}/contracts/${contractId}/accounts`;
    const options = {
      method: HTTP_VERBS.post,
      data: account,
    };

    return request(url, options);
  },

  /**
   * PUT /parties/:partyId/profile-type
   *
   * @param {string} partyId
   * @param {string} wtr Related willing to risk
   * @returns {Promise} Returns a request object, a Promise.
   */
  updatePartyProfileType(partyId, wtr) {
    const url = `${API.v1}/parties/${partyId}/profile-type`;
    const options = {
      method: HTTP_VERBS.put,
      data: { investment_profile: wtr },
    };

    return request(url, options);
  },

  /**
   * POST /profiler-sessions/:sessionId/accounts/:accountId
   *
   * @param {string} accountId
   * @param {string} external
   * @param {string} investmentTerm
   * @param {string} portfolio
   * @param {string} sessionId
   * @param {string} wtr
   * @returns a promise
   */
  createProfilerAccount(sessionId, accountId, wtr, investmentTerm, external) {
    const url = `${API.v1}/profiler-sessions/${sessionId}/accounts/${accountId}`;
    const options = {
      method: HTTP_VERBS.post,
      data: {
        wtr,
        external_id: external,
        investment_term: investmentTerm,
        profile_session_id: sessionId,
      },
    };
    return request(url, options);
  },

  /**
   * POST /parties/:partyId/legal-documents
   *
   * @param {string} partyId
   * @param {object} document
   * @return {Promise<object>} Returns a request object, a Promise.
   */
  saveContractAgreements(partyId, document) {
    const url = `${API.v2}/parties/${partyId}/legal-documents`;
    const options = {
      method: HTTP_VERBS.post,
      data: document,
    };

    return request(url, options);
  },

  /**
   * GET /legal-documents
   * GET /parties/:partyId/legal-documents
   *
   * @param {string} partyId
   * @return {array} Returns an array with documents the user need to accept.
   */
  /* istanbul ignore next */
  async getContractAgreements(partyId, returnAllDocuments = false) {
    const documentsToAcceptUrl = `${API.v2}/legal-documents`;
    const acceptedDocumentsUrl = `${API.v2}/parties/${partyId}/legal-documents`;
    const options = {
      method: HTTP_VERBS.get,
    };

    if (returnAllDocuments) {
      return request(documentsToAcceptUrl, options);
    }

    const [
      { data: documentsToAccept, err: documentsToAcceptError },
      { data: acceptedDocuments, err: acceptedDocumentsError },
    ] = await Promise.all([
      request(documentsToAcceptUrl, options),
      request(acceptedDocumentsUrl, options),
    ]);

    return acceptedDocumentsError || documentsToAcceptError
      ? []
      : legalDocumentsAdapter(documentsToAccept, acceptedDocuments);
  },
};

export default Api;
