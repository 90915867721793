import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Button = ({
  className,
  disabled,
  hidden,
  kind,
  loading,
  onClick,
  size,
  testId,
  text,
}) => {
  if (hidden) {
    return null;
  }

  return (
    <button
      className={clsx(className, `button--${kind}`, {
        button: kind !== 'link',
        'button--small': kind !== 'link' && size === 'small',
        'button--big': kind !== 'link' && size === 'big',
        'button--loading': loading,
        'button--disabled': disabled,
      })}
      data-testid={testId}
      onClick={onClick}
      type="button"
      aria-label={text}
      disabled={disabled}
      id={testId}
    >
      {loading ? (
        <div className="button__loader" role="alertdialog" aria-busy="true">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      ) : (
        text
      )}
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  hidden: false,
  text: '',
  className: '',
  kind: 'dark',
  size: 'small',
  testId: 'btnId',
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  kind: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string,
  testId: PropTypes.string,
  text: PropTypes.string,
};

export default Button;
