/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import registerEvents from 'utils/registerEvents';
import {
  TRACKING_EVENTS,
  TRACKING_ENVIRONMENTS,
  PROFILING_VERSION,
  ACCOUNT_TYPES,
} from 'utils/constants';

import {
  FIRST_QUESTION_KEY,
  ANSWERS_INVESTMENT_INSTRUMENTS,
} from 'views/questionnaire/data';
import Api from 'api/madoff';
import RedirectApi from 'api/wm';
import { getAnswersArray } from 'utils';
import { makeTrackingErrorProps, formatErrors } from 'utils/formats';
import {
  QUESTIONS_FOR_RESULTS,
  REDUCER_STATUS,
  GENERIC_ERROR,
  EXPERIENCE_INVESTMENT_INSTRUMENTS,
} from '../constants';

export const fetchQuestionnaires = createAsyncThunk(
  'questionnaire/fetchQuestionnaires',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await Api.getQuestionnaires();
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchQuestionById = createAsyncThunk(
  'questionnaire/fetchQuestionById',
  async (payloadCreator, { rejectWithValue }) => {
    try {
      const { data } = await Api.getQuestionsById(
        payloadCreator.questionnaire,
        payloadCreator.questionId,
      );
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const saveAnswers = createAsyncThunk(
  'questionnaire/saveAnswers',
  async (payloadCreator, { dispatch, rejectWithValue }) => {
    let eventValue;

    if (payloadCreator.questionKey === EXPERIENCE_INVESTMENT_INSTRUMENTS) {
      eventValue = getAnswersArray(
        payloadCreator?.answer?.answer_identifiers,
        ANSWERS_INVESTMENT_INSTRUMENTS,
      );
    } else {
      eventValue = payloadCreator?.value || payloadCreator.answer?.answer_value;
    }

    try {
      const answerReprofile = {
        contract_id: payloadCreator.contractId,
        account_id: payloadCreator.accountId,
        ...payloadCreator.answer,
      };

      const answerRequest = payloadCreator.isReprofiling
        ? answerReprofile
        : payloadCreator.answer;

      const { data } = await Api.saveAnswers(
        payloadCreator.questionnaire,
        payloadCreator.questionId,
        payloadCreator.isEnabledFeatureFlag,
        answerRequest,
      );

      dispatch(
        saveAnswerByQuestionId({
          answer: payloadCreator.answer,
          questionId: payloadCreator.questionId,
          questionKey: payloadCreator.questionKey,
          metadata: payloadCreator.metadata,
        }),
      );

      if (data.is_questionnaire_finished) {
        const stringMatrixPointV2 = `${data?.profile_details?.risk_point?.willingness_to_risk},${data?.profile_details?.risk_point?.investment_term}`;
        const matrixPoint = payloadCreator.isEnabledFeatureFlag
          ? stringMatrixPointV2
          : data.risk_point.matrix_point;

        dispatch(
          fetchRecommendation({
            matrixPoint,
            isReprofiling: payloadCreator.isReprofiling,
          }),
        );
        dispatch(saveMatrixPoint({ matrixPoint }));
      }

      registerEvents(
        TRACKING_EVENTS.v2[payloadCreator.questionKey]
          ? TRACKING_EVENTS.v2[payloadCreator.questionKey].event
          : payloadCreator.questionKey,
        TRACKING_ENVIRONMENTS.MIXPANEL,
        {
          Success: true,
          ...(eventValue && { Type: eventValue }),
          Reprofiling: !!payloadCreator.isReprofiling,
        },
      );

      return data;
    } catch (err) {
      const trackingErrorProps = makeTrackingErrorProps(
        err?.data?.error,
        err?.headers,
      );

      registerEvents(
        TRACKING_EVENTS.v2[payloadCreator.questionKey].event,
        TRACKING_ENVIRONMENTS.MIXPANEL,
        {
          ...trackingErrorProps,
          ...(eventValue && { Type: eventValue }),
          Reprofiling: !!payloadCreator.isReprofiling,
        },
      );

      return rejectWithValue(err);
    }
  },
);

export const fetchRecommendation = createAsyncThunk(
  'questionnaire/recommendation',
  async (payloadCreator, { rejectWithValue }) => {
    try {
      const { data } = await Api.getRecommendation(payloadCreator.matrixPoint);

      const portfolioType = data?.portfolio_type;
      const portfolioName = data?.portfolio_name;

      registerEvents(
        TRACKING_EVENTS.v2.create_recommendation.event,
        TRACKING_ENVIRONMENTS.MIXPANEL,
        {
          Success: true,
          Reprofiling: !!payloadCreator.isReprofiling,
          ...(portfolioType && { Type: portfolioType }),
          ...(portfolioName && { Portfolio: portfolioName }),
        },
      );

      return data;
    } catch (err) {
      const trackingErrorProps = makeTrackingErrorProps(
        err?.data?.error,
        err?.headers,
      );

      registerEvents(
        TRACKING_EVENTS.v2.create_recommendation.event,
        TRACKING_ENVIRONMENTS.MIXPANEL,
        {
          ...trackingErrorProps,
          Reprofiling: !!payloadCreator.isReprofiling,
        },
      );
      return rejectWithValue(err);
    }
  },
);

export const saveReprofile = createAsyncThunk(
  'reprofile/save',
  async (payload, { rejectWithValue }) => {
    try {
      const { data, err, headers } = await Api.saveReprofile(
        payload.contractId,
        payload.accountId,
        payload.reprofileData,
      );
      if (data) {
        registerEvents(
          TRACKING_EVENTS.v2.reprofiling_request.event,
          TRACKING_ENVIRONMENTS.MIXPANEL,
          {
            Success: true,
            profiling_version: payload.profilingVersion,
            initial_portfolio: payload.portfolios.actualPortfolio,
            new_portfolio: payload.portfolios.newPortfolio,
          },
        );
      } else {
        const trackingErrorProps = makeTrackingErrorProps(err, headers);
        registerEvents(
          TRACKING_EVENTS.v2.reprofiling_request.event,
          TRACKING_ENVIRONMENTS.MIXPANEL,
          {
            ...trackingErrorProps,
            profiling_version: payload.profilingVersion,
            initial_portfolio: payload.portfolios.actualPortfolio,
            new_portfolio: payload.portfolios.newPortfolio,
          },
        );
      }
      return data;
    } catch (err) {
      const trackingErrorProps = makeTrackingErrorProps(
        err?.data?.error,
        err?.headers,
      );
      registerEvents(
        TRACKING_EVENTS.v2.reprofiling_request.event,
        TRACKING_ENVIRONMENTS.MIXPANEL,
        {
          ...trackingErrorProps,
          profiling_version: payload.profilingVersion,
          initial_portfolio: payload.portfolios.actualPortfolio,
          new_portfolio: payload.portfolios.newPortfolio,
        },
      );
      return rejectWithValue(err);
    }
  },
);

export const fetchLastQuestionnaire = createAsyncThunk(
  'questionnaire/fetchLastQuestionnaire',
  async (payloadCreator, { rejectWithValue }) => {
    try {
      const { data, err, headers } = await Api.getLastQuestionnaire(
        payloadCreator.accountId,
        payloadCreator.contractId,
      );
      if (data) {
        registerEvents(
          TRACKING_EVENTS.v2.reprofiling_previous_answers.event,
          TRACKING_ENVIRONMENTS.MIXPANEL,
          {
            Success: true,
            profiling_version: payloadCreator.profilingVersion,
          },
        );
      } else {
        const trackingErrorProps = makeTrackingErrorProps(err, headers);
        registerEvents(
          TRACKING_EVENTS.v2.reprofiling_previous_answers.event,
          TRACKING_ENVIRONMENTS.MIXPANEL,
          {
            ...trackingErrorProps,
            profiling_version: payloadCreator.profilingVersion,
          },
        );
      }
      return data;
    } catch (err) {
      const trackingErrorProps = makeTrackingErrorProps(
        err?.data?.error,
        err?.headers,
      );
      registerEvents(
        TRACKING_EVENTS.v2.reprofiling_previous_answers.event,
        TRACKING_ENVIRONMENTS.MIXPANEL,
        {
          ...trackingErrorProps,
          profiling_version: payloadCreator.profilingVersion,
        },
      );
      return rejectWithValue(err);
    }
  },
);

export const fetchReprofile = createAsyncThunk(
  'questionnaire/fetchReprofile',
  async (payloadCreator, { rejectWithValue }) => {
    try {
      const { data } = await Api.getReprofile(
        payloadCreator.accountId,
        payloadCreator.contractId,
      );

      registerEvents(
        TRACKING_EVENTS.v2.reprofiling_questionnaire.event,
        TRACKING_ENVIRONMENTS.MIXPANEL,
        {
          Success: true,
          profiling_version: payloadCreator.profilingVersion,
        },
      );
      return data;
    } catch (err) {
      const trackingErrorProps = makeTrackingErrorProps(
        err?.data?.error,
        err?.headers,
      );
      registerEvents(
        TRACKING_EVENTS.v2.reprofiling_questionnaire.event,
        TRACKING_ENVIRONMENTS.MIXPANEL,
        {
          ...trackingErrorProps,
          profiling_version: payloadCreator.profilingVersion,
        },
      );
      return rejectWithValue(err);
    }
  },
);

export const fetchProfileDetails = createAsyncThunk(
  'questionnaire/fetchProfileDetails',
  async (payloadCreator, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await Api.getProfileDetails(
        payloadCreator.accountId,
        payloadCreator.contractId,
      );
      const { risk_point } = data;
      const stringMatrixPoint = `${risk_point?.willingness_to_risk},${risk_point?.investment_term}`;
      const { data: actualPortfolio } = await Api.getRecommendation(
        stringMatrixPoint,
      );
      const compoundProfileDetails = { ...data, ...actualPortfolio };
      const profilingVersion = data?.created_at
        ? PROFILING_VERSION.v2
        : PROFILING_VERSION.v1;

      dispatch(fetchLastQuestionnaire({ ...payloadCreator, profilingVersion }));
      dispatch(fetchReprofile({ ...payloadCreator, profilingVersion }));

      return compoundProfileDetails;
    } catch (err) {
      dispatch(
        fetchLastQuestionnaire({
          ...payloadCreator,
          profilingVersion: PROFILING_VERSION.v1,
        }),
      );
      dispatch(
        fetchReprofile({
          ...payloadCreator,
          profilingVersion: PROFILING_VERSION.v1,
        }),
      );
      return rejectWithValue(err);
    }
  },
);

export const fetchCreateAccount = createAsyncThunk(
  'questionnaire/fetchCreateAccount',
  // eslint-disable-next-line consistent-return
  async (payloadCreator, { rejectWithValue }) => {
    const { submit_account_creation } = TRACKING_EVENTS;

    try {
      const strategyData = {
        name: payloadCreator.strategy,
        management_type_template: ACCOUNT_TYPES.wealth.management_type_template,
        profile_type_points: payloadCreator.matrixPoint,
        profile_session_id: payloadCreator.profile,
        party_id: payloadCreator.partyId,
        external_id: payloadCreator.external,
      };

      const account = await RedirectApi.createStrategy(
        payloadCreator.contract,
        strategyData,
      );

      if (account.data) {
        registerEvents(
          submit_account_creation.event,
          TRACKING_ENVIRONMENTS.MIXPANEL,
          { ...submit_account_creation.wealth_success },
        );
      } else {
        registerEvents(
          submit_account_creation.event,
          TRACKING_ENVIRONMENTS.MIXPANEL,
          {
            ...submit_account_creation.wealth_error,
            Callback: formatErrors(account?.err?.response),
          },
        );
        return rejectWithValue(account.err.response);
      }

      return account.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const checkQuestionnaireStatus = (questions, action) => {
  const { payload } = action;
  return {
    ...questions,
    [!payload.is_questionnaire_finished && payload.next_question.question_id]: {
      is_questionnaire_finished: payload.is_questionnaire_finished,
      ...payload.next_question,
    },
  };
};

const parseErrorResponse = (response) => {
  let message = response?.message;

  if (response?.details?.length) {
    message = response.details[0].message;
  }

  if (response?.data) {
    const { data } = response;

    if (data.error?.details?.length) {
      message = data.error.details[0].message;
    } else {
      message = data.error?.message;
    }
  }

  return message || GENERIC_ERROR;
};

const initialState = {
  current: {
    status: REDUCER_STATUS.idle,
    data: {},
    error: null,
  },
  currentQuestion: {
    status: REDUCER_STATUS.idle,
    data: {},
    error: null,
  },
  recommendation: {
    status: REDUCER_STATUS.idle,
    data: {},
    error: null,
  },
  reprofile: {
    status: REDUCER_STATUS.idle,
    data: {},
    error: null,
  },
  createAccount: {
    status: REDUCER_STATUS.idle,
    data: {},
    error: null,
  },
  lastQuestionnaire: {
    status: REDUCER_STATUS.idle,
    data: {},
    error: null,
  },
  lastProfileDetails: {
    status: REDUCER_STATUS.idle,
    data: {},
    error: null,
  },
  answers: {},
  currentKey: '',
  previousKey: '',
  questions: {},
  strategyName: '',
  strategyGoal: '',
  initialAmount: '',
  matrixPoint: '',
  monthlyAmount: '',
  monthlyWithdrawal: '',
  showWelcomeScreen: true,
  investmentTerm: '',
};

export const questionsSlice = createSlice({
  name: 'questionnaire',
  initialState: {
    ...initialState,
  },
  reducers: {
    saveStrategyNameAnswer: (state, action) => {
      state.strategyName = action.payload.answer;
      state.answers = {
        ...state.answers,
        [action.payload.questionId]: action.payload.answer,
      };
      state.questions = {
        ...state.questions,
        [action.payload.questionId]: action.payload,
      };
      if (!action?.payload?.isReprofiling) {
        registerEvents(
          TRACKING_EVENTS.v2[action.payload.question.question_key].event,
          TRACKING_ENVIRONMENTS.MIXPANEL,
          {
            Type: 'Wealth Management',
            Callback: 'Success',
            Success: true,
            Name: action.payload.answer,
          },
        );
      }
    },
    saveStrategyGoal: (state, { payload: strategyGoal }) => {
      state.strategyGoal = strategyGoal;
    },
    saveAnswerByQuestionId: (state, { payload }) => {
      const { questionId, questionKey, answer, metadata } = payload;
      state.answers = {
        ...state.answers,
        [questionId]: answer,
      };
      if (QUESTIONS_FOR_RESULTS.initialAmount.includes(questionKey)) {
        state.initialAmount = answer;
      }
      if (QUESTIONS_FOR_RESULTS.monthlyAmount.includes(questionKey)) {
        state.monthlyAmount = answer;
      }
      if (QUESTIONS_FOR_RESULTS.monthlyWithdrawal.includes(questionKey)) {
        state.monthlyWithdrawal = answer;
      }
      if (QUESTIONS_FOR_RESULTS.investmentTerm.includes(questionKey)) {
        state.investmentTerm = { max: metadata.max_range, answer };
      }
    },
    saveMatrixPoint: (state, action) => {
      state.matrixPoint = action.payload.matrixPoint;
    },
    clearQuestionnaire: () => ({
      ...initialState,
      currentKey: FIRST_QUESTION_KEY,
      showWelcomeScreen: false,
    }),
    setShowWelcomeScreen: (state, { payload }) => {
      state.showWelcomeScreen = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuestionnaires.pending, (state) => {
      state.current.status = REDUCER_STATUS.pending;
      state.currentQuestion.status = REDUCER_STATUS.pending;
    });
    builder.addCase(fetchQuestionnaires.fulfilled, (state, action) => {
      const initialQuestion = {
        is_questionnaire_finished: false,
        ...action.payload?.initial_question,
      };
      state.current.status = REDUCER_STATUS.fulfilled;
      state.current.data = action.payload;
      state.current.error = null;
      state.currentQuestion.data = action.payload?.initial_question;
      state.currentQuestion.status = REDUCER_STATUS.fulfilled;
      state.currentQuestion.error = null;
      state.questions = {
        ...state.questions,
        [action.payload?.initial_question?.question_id]: initialQuestion,
      };
      state.currentKey = action.payload?.initial_question?.question_key;
    });
    builder.addCase(fetchQuestionnaires.rejected, (state, action) => {
      state.current.status = REDUCER_STATUS.rejected;
      state.current.data = {};
      state.current.error = parseErrorResponse(action.payload);
    });
    builder.addCase(fetchQuestionById.pending, (state) => {
      state.currentQuestion.status = REDUCER_STATUS.pending;
      state.currentQuestion.data = {};
    });
    builder.addCase(fetchQuestionById.fulfilled, (state, action) => {
      state.currentQuestion.status = REDUCER_STATUS.fulfilled;
      state.currentQuestion.data = action.payload;
      state.questions = {
        ...state.questions,
        [action.payload.question_id]: {
          is_questionnaire_finished: action.payload.is_questionnaire_finished,
          ...(action.payload.is_questionnaire_finished
            ? { ...action.payload.next_question }
            : { ...action.payload.risk_point }),
        },
      };
      state.currentQuestion.error = null;
    });
    builder.addCase(fetchQuestionById.rejected, (state, action) => {
      state.currentQuestion.status = REDUCER_STATUS.rejected;
      state.currentQuestion[action.payload.question_id] = {};
      state.currentQuestion.error = parseErrorResponse(action.payload);
    });
    builder.addCase(saveAnswers.pending, (state) => {
      state.currentQuestion.status = REDUCER_STATUS.pending;
      state.currentQuestion.data = {};
    });
    builder.addCase(saveAnswers.fulfilled, (state, action) => {
      state.currentQuestion.status = REDUCER_STATUS.fulfilled;
      state.currentQuestion.data = {
        is_questionnaire_finished: action.payload.is_questionnaire_finished,
        ...action.payload.next_question,
        profile_details: { ...action.payload?.profile_details },
      };
      state.currentQuestion.error = null;
      state.questions = checkQuestionnaireStatus(state.questions, action);
      state.currentKey = !action.payload.is_questionnaire_finished
        ? action.payload.next_question.question_key
        : '';
    });
    builder.addCase(saveAnswers.rejected, (state, action) => {
      state.currentQuestion.status = REDUCER_STATUS.rejected;
      state.currentQuestion[action.payload?.question_id] = {};
      state.currentQuestion.error = parseErrorResponse(action.payload);
    });
    builder.addCase(fetchRecommendation.pending, (state) => {
      state.recommendation.status = REDUCER_STATUS.pending;
      state.recommendation.data = {};
      state.recommendation.error = null;
    });
    builder.addCase(fetchRecommendation.fulfilled, (state, action) => {
      state.recommendation.status = REDUCER_STATUS.fulfilled;
      state.recommendation.data = action.payload;
      state.recommendation.error = {};
    });
    builder.addCase(fetchRecommendation.rejected, (state, action) => {
      state.recommendation.status = REDUCER_STATUS.rejected;
      state.recommendation.data = {};
      state.recommendation.error = parseErrorResponse(action.payload);
    });
    builder.addCase(saveReprofile.pending, (state) => {
      state.reprofile.status = REDUCER_STATUS.pending;
      state.reprofile.data = {};
      state.reprofile.error = {};
    });
    builder.addCase(saveReprofile.fulfilled, (state, action) => {
      state.reprofile.status = REDUCER_STATUS.fulfilled;
      state.reprofile.data = action.payload;
      state.reprofile.error = {};
    });
    builder.addCase(saveReprofile.rejected, (state, action) => {
      state.reprofile.status = REDUCER_STATUS.rejected;
      state.reprofile.data = {};
      state.reprofile.error = parseErrorResponse(action.payload);
    });
    builder.addCase(fetchLastQuestionnaire.pending, (state) => {
      state.lastQuestionnaire.status = REDUCER_STATUS.pending;
      state.lastQuestionnaire.data = {};
    });
    builder.addCase(fetchLastQuestionnaire.fulfilled, (state, action) => {
      state.lastQuestionnaire.status = REDUCER_STATUS.fulfilled;
      state.lastQuestionnaire.data = action.payload;
      state.lastQuestionnaire.error = null;
    });
    builder.addCase(fetchLastQuestionnaire.rejected, (state, action) => {
      state.lastQuestionnaire.status = REDUCER_STATUS.rejected;
      state.lastQuestionnaire.data = {};
      state.lastQuestionnaire.error = parseErrorResponse(action.payload);
    });
    builder.addCase(fetchReprofile.pending, (state) => {
      state.currentQuestion.status = REDUCER_STATUS.pending;
      state.current.status = REDUCER_STATUS.pending;
    });
    builder.addCase(fetchReprofile.fulfilled, (state, action) => {
      const initialQuestion = {
        is_questionnaire_finished: false,
        ...action.payload?.initial_question,
      };
      state.current.data = action.payload;
      state.current.error = null;
      state.current.status = REDUCER_STATUS.fulfilled;
      state.currentKey = action.payload?.initial_question?.question_key;
      state.currentQuestion.data = action.payload?.initial_question;
      state.currentQuestion.error = null;
      state.currentQuestion.status = REDUCER_STATUS.fulfilled;
      state.questions = {
        ...state.questions,
        [action.payload?.initial_question?.question_id]: initialQuestion,
      };
    });
    builder.addCase(fetchReprofile.rejected, (state, action) => {
      state.current.data = {};
      state.current.error = parseErrorResponse(action.payload);
      state.current.status = REDUCER_STATUS.rejected;
    });
    builder.addCase(fetchCreateAccount.pending, (state) => {
      state.createAccount.status = REDUCER_STATUS.pending;
      state.createAccount.data = {};
    });
    builder.addCase(fetchCreateAccount.fulfilled, (state, action) => {
      state.createAccount.status = REDUCER_STATUS.fulfilled;
      state.createAccount.data = action.payload;
      state.createAccount.error = null;
    });
    builder.addCase(fetchCreateAccount.rejected, (state, action) => {
      state.createAccount.status = REDUCER_STATUS.rejected;
      state.createAccount.data = {};
      state.createAccount.error = parseErrorResponse(action.payload);
    });
    builder.addCase(fetchProfileDetails.pending, (state) => {
      state.lastProfileDetails.status = REDUCER_STATUS.pending;
      state.lastProfileDetails.data = {};
    });
    builder.addCase(fetchProfileDetails.fulfilled, (state, action) => {
      state.lastProfileDetails.status = REDUCER_STATUS.fulfilled;
      state.lastProfileDetails.data = action.payload;
      state.lastProfileDetails.error = null;
    });
    builder.addCase(fetchProfileDetails.rejected, (state, action) => {
      state.lastProfileDetails.status = REDUCER_STATUS.rejected;
      state.lastProfileDetails.data = {};
      state.lastProfileDetails.error = parseErrorResponse(action.payload);
    });
  },
});

export const {
  saveAnswerByQuestionId,
  saveMatrixPoint,
  saveStrategyNameAnswer,
  clearQuestionnaire,
  setShowWelcomeScreen,
  saveStrategyGoal,
} = questionsSlice.actions;

export default questionsSlice.reducer;
