import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import registerEvents from 'utils/registerEvents';

import alertIcn from 'images/atm-icn-alert-triangle.svg';

import {
  TRACKING_EVENTS,
  TRACKING_ENVIRONMENTS,
  PROFILING_VERSION,
} from 'utils/constants';
import Button from '../Button';

const CancelScreen = ({ onClose, backStrategy }) => {
  const { t } = useTranslation('cancel');
  const questionnaire = useSelector((state) => state.questionnaire);
  const profilingVersion = questionnaire?.lastProfileDetails?.data?.created_at
    ? PROFILING_VERSION.v2
    : PROFILING_VERSION.v1;

  useEffect(() => {
    backStrategy?.();
  }, []);

  const handleClickExit = () => {
    registerEvents(
      TRACKING_EVENTS.v2.reprofiling_exit.event,
      TRACKING_ENVIRONMENTS.MIXPANEL,
      {
        profiling_version: profilingVersion,
      },
    );
    onClose(true);
  };

  const handleClickCancel = () => {
    registerEvents(
      TRACKING_EVENTS.v2.reprofiling_cancel.event,
      TRACKING_ENVIRONMENTS.MIXPANEL,
      {
        profiling_version: profilingVersion,
      },
    );
    onClose(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75 }}
    >
      <div className="cancel-screen" data-testid="cancel-screen-test">
        <div className="cancel-screen cancel-screen__container">
          <img src={alertIcn} alt="Close" />
          <div className="cancel-screen__container cancel-screen__container-question">
            <span>{t('title')}</span>
          </div>
          <div className="cancel-screen__container cancel-screen__container-description">
            <span>{t('description')}</span>
          </div>
          <div className="cancel-screen__container cancel-screen__container-controls">
            <button
              data-testid="btnExit"
              type="button"
              aria-label="Exit"
              className="button button--dark button--small"
              onClick={handleClickExit}
            >
              {t('exit')}
            </button>
            <Button
              testId="btnCancel"
              className="align-center link link--bold"
              onClick={handleClickCancel}
              kind="link"
              text={t('cancel')}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

CancelScreen.propTypes = {
  onClose: PropTypes.func,
  backStrategy: PropTypes.func,
};

export default CancelScreen;
