import { Mixpanel } from 'utils/mixpanel';
import { TRACKING_ENVIRONMENTS } from './constants';

/**
 * Register events in multiple platforms
 *
 * @param {string} event
 * @param {string} trackingEnviroment
 * @param {object} properties
 * @param {requestCallback} cb
 */
export const registerEvents = (
  event,
  trackingEnviroment,
  properties = {},
  cb = () => null,
) => {
  switch (trackingEnviroment) {
    case TRACKING_ENVIRONMENTS.MIXPANEL:
      Mixpanel.track(event, properties, cb);
      break;
    case TRACKING_ENVIRONMENTS.ALL:
      Mixpanel.track(event, properties, cb);
      break;
    default:
      break;
  }
};

export const parseError = (error) => {
  if (error.response) {
    return error.response.data;
  }

  if (error.request) {
    return error.request;
  }

  return error.message;
};

export default registerEvents;
