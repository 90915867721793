// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ToolTip from 'components/ToolTip';

const Row = ({ symbol, percentage }) => (
  <tr>
    <td className="advisor-table--cell advisor-table--cell__name">{symbol}</td>
    <td className="advisor-table--cell advisor-table--cell__value">
      {percentage}%
    </td>
  </tr>
);

Row.propTypes = {
  symbol: PropTypes.string,
  percentage: PropTypes.number,
};

const TableBody = ({ rows }) => (
  <tbody>
    {rows.map((row) => (
      <Row {...row} key={row.symbol} />
    ))}
  </tbody>
);

TableBody.propTypes = {
  rows: PropTypes.array,
};

const Table = ({ table }) => {
  const { t } = useTranslation('results');
  const equities = table?.filter(
    (fund) => fund.instrument_type === 'equity_fund',
  );
  const debts = table?.filter((fund) => fund.instrument_type === 'debt_fund');

  return (
    <div className="advisor-results--table">
      <div className="advisor-results--title">{t('composition')}</div>
      <div className="advisor-results--description">
        {t('compositionInvestment')}
      </div>
      {!!equities?.length && (
        <>
          <div className="advisor-table--cell advisor-table--cell__kind equity">
            <div className="indicator" role="presentation" />
            <ToolTip
              content="Los instrumentos de renta variable son aquellos que están invertidos en acciones del capital de una empresa, y las ganancias pueden venir de dos fuentes: de el aumento en el precio de la acción o de los dividendos pagados periódicamente por la misma."
              textIndicator={t('equities')}
            />
          </div>
          <table className="advisor-table">
            <TableBody
              rows={equities.sort((a, b) => a.percentage - b.percentage)}
            />
          </table>
        </>
      )}
      {!!debts?.length && (
        <>
          <div className="advisor-table--cell advisor-table--cell__kind debt">
            <div className="indicator debt" role="presentation" />
            <ToolTip
              content="Los instrumentos de renta fija son emisiones de deuda que realizan los estados y las empresas públicas o privadas, y las ganacias vienen de intereses previamente definidos durante la vida del instrumento hasta su vencimiento, cuando se repaga la inversión inicial."
              textIndicator={t('debt')}
            />
          </div>
          <table className="advisor-table advisor-table--borderless">
            <TableBody
              rows={debts.sort((a, b) => a.percentage - b.percentage)}
            />
          </table>
        </>
      )}
    </div>
  );
};

Table.propTypes = {
  table: PropTypes.array,
};

export default Table;
