import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Chart from 'views/profilerResults/Chart';

const CLASS_PREFIX = 'advisor-results';

const PortfolioComposition = ({ profileDetail, portfolioType }) => {
  const { t } = useTranslation('results');
  const typeMessage = {
    settlement: t('bottomLabelSettlement'),
    debt: t('bottomLabelDebt'),
    preservation: t('bottomLabelPreservation'),
    balance: t('bottomLabelBalance'),
    appreciation: t('bottomLabelAppreciation'),
    growth: t('bottomLabelGrowth'),
    equities: t('bottomLabelEquities'),
  };
  return (
    <div className={`${CLASS_PREFIX}__info`}>
      <div className={`${CLASS_PREFIX}__profile`}>
        <div className={`${CLASS_PREFIX}__profile-type`}>
          <div className={`${CLASS_PREFIX}__profile-title`}>
            {t('composition')}
          </div>
        </div>
        <Chart
          debt={profileDetail?.debt}
          equity={profileDetail?.equity}
          portfolioType={portfolioType}
        />
        <div className={`${CLASS_PREFIX}__profile-bottom`}>
          {typeMessage[portfolioType]}
        </div>
      </div>
    </div>
  );
};

PortfolioComposition.defaultProps = {
  profileDetail: {},
};

PortfolioComposition.propTypes = {
  profileDetail: PropTypes.object,
  portfolioType: PropTypes.string,
};

export default PortfolioComposition;
