/* eslint-disable camelcase */
export const PROFILES = {
  debt: {
    label: 'Perfil Deuda',
    key: 'debt',
    cagr: 5.89,
    description:
      'Para inversionistas conservadores cuya principal preocupación es no sufrir pérdidas.',
  },
  preservation: {
    label: 'Perfil de Preservación',
    key: 'preservation',
    cagr: 6.44,
    description:
      'Para inversionistas cuyo objetivo principal es la preservación de su capital.',
  },
  balance: {
    label: 'Perfil de Balance',
    key: 'balance',
    cagr: 7.74,
    description:
      'Para inversionistas cuyo objetivo es la preservación de su capital con algo de crecimiento.',
  },
  appreciation: {
    label: 'Perfil de Apreciación',
    key: 'appreciation',
    cagr: 9.31,
    description:
      'Para inversionistas que buscan un crecimiento de su capital en el largo plazo, con una volatilidad reducida.',
  },
  growth: {
    label: 'Perfil de Crecimiento',
    key: 'growth',
    cagr: 11.18,
    description:
      'Para inversionistas agresivos que buscan mayores rendimientos y pueden tolerar la mayor volatilidad que los acompaña.',
  },
  equities: {
    label: 'Perfil de Bolsa',
    key: 'equities',
    cagr: 13.33,
    description:
      'Para inversionistas agresivos que buscan maximizar rendimientos y toleran alta volatilidad.',
  },
  settlement: {
    label: 'Perfil Descubrimiento',
    key: 'settlement',
    cagr: 5.62,
    description: 'Tu dinero crecerá poco a poco y con el riesgo más bajo.',
  },
};

export const INSTRUMENT_TYPE = {
  equity: 'Renta Variable',
  debt: 'Renta Fija',
};

export const getProfileDetail = (investmentProfile, portfolio) => {
  let debt = 0;
  let equity = 0;

  const table = portfolio.instruments?.map(
    ({ symbol, instrument_type, percentage }) => {
      switch (instrument_type) {
        case 'equity_fund':
          equity += percentage;
          break;
        case 'debt_fund':
          debt += percentage;
          break;
        default:
          break;
      }

      return {
        name: symbol,
        instrument_type,
        kind: INSTRUMENT_TYPE[instrument_type],
        value: percentage,
      };
    },
  );

  return {
    ...PROFILES[investmentProfile],
    table,
    debt,
    equity,
  };
};
