import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({ position, content, imageIndicator, textIndicator }) => {
  const Indicator = imageIndicator ? (
    <img src={imageIndicator} alt="Info" data-testid="tooltip-image" />
  ) : (
    <span className="tooltip__indicator--text">{textIndicator}</span>
  );

  return (
    <div className="tooltip" role="tooltip">
      <div className="tooltip__indicator">{Indicator}</div>
      <div className={`tooltip__card tooltip__card--${position}`}>
        {content}
      </div>
    </div>
  );
};

Tooltip.defaultProps = {
  position: 'right',
};

Tooltip.propTypes = {
  position: PropTypes.oneOf(['right', 'top', 'down']),
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  imageIndicator: PropTypes.string,
  textIndicator: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default memo(Tooltip);
