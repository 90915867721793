export const QUOTES = [
  {
    quote:
      'El interés compuesto es la octava maravilla del mundo, aquel que lo entiende lo gana, aquel que no, lo paga',
    author: 'Albert Einstein',
  },
  {
    quote:
      'Cuando inviertes, estás comprando un día que no tienes que trabajar',
    author: 'Aya Laraya',
  },
  {
    quote:
      'Si quieres tener éxito, algo que se puede controlar es lo duro que trabajas',
    author: 'Michael Bloomberg',
  },
  {
    quote:
      'La planificación a largo plazo no es pensar en decisiones futuras, sino en el futuro de las decisiones presentes',
    author: 'Peter Drucker',
  },
  {
    quote:
      'Haz que cada detalle sea perfecto y limita el número de detalles a perfecto',
    author: 'Jack Dorsey',
  },
  {
    quote: 'El precio de la perfección es la práctica constante',
    author: 'Andrew Carnegie',
  },
  {
    quote: 'El dinero no está en la compra o la venta, sino en la espera',
    author: 'Charlie Munger',
  },
  {
    quote:
      'Tenemos que ser rígidos en nuestras reglas y flexibles en nuestras expectativas',
    author: 'Mark Douglas',
  },
  {
    quote:
      'Invertir sin investigar es como jugar al póker sin mirar nunca las cartas',
    author: 'Peter Lynch',
  },
];

export const RANDOM_QUOTE_TIME = 6000;

export const RECOMMENDATION_LOADER_TIME = 4000;
