import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ENVIRONMENTS } from 'utils/constants';

const CLASS_PREFIX = 'environment-alert';

const EnvironmentAlert = ({ environment }) => {
  const { t } = useTranslation('maintenance');

  if (environment === ENVIRONMENTS.PRODUCTION) {
    return null;
  }

  return (
    <div className={CLASS_PREFIX} role="alert">
      <div className={`${CLASS_PREFIX}__icon`} />
      <div className={`${CLASS_PREFIX}__message`}>
        <span>{t('testEnvironment')}</span>
      </div>
    </div>
  );
};

EnvironmentAlert.propTypes = {
  environment: PropTypes.string.isRequired,
};

export default memo(EnvironmentAlert);
