export const REDUCER_STATUS = {
  idle: 'idle',
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected',
};

export const QUESTIONS_FOR_RESULTS = {
  initialAmount: [
    'target_characteristics_initial_amount',
    'target_characteristics_initial_amount_short_term',
  ],
  monthlyAmount: [
    'target_characteristics_monthly_amount_contribute',
    'target_characteristics_monthly_amount_contribute_short_term',
  ],
  monthlyWithdrawal: ['target_characteristics_monthly_amount_withdrawal'],
  investmentTerm: [
    'target_characteristics_time_amount',
    'target_characteristics_time_amount_education',
  ],
};

export const NOTIFICATION_TIMEOUT = 6000;

export const GENERIC_ERROR =
  'Ocurrió un error al procesar la información, por favor inténtalo más tarde.';

export const EXPERIENCE_INVESTMENT_INSTRUMENTS =
  'experience_investment_instruments';
