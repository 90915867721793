/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import store from 'store';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import ROUTES from 'routes';
import config from 'config';
import { getCents, getProjectedAmount } from 'utils';
import { markdown2Jsx } from 'utils/markdown2Jsx';
import { Advisor } from 'api';
import registerEvents, { parseError } from 'utils/registerEvents';
import { TRACKING_EVENTS, TRACKING_ENVIRONMENTS } from 'utils/constants';

import { GENERIC_ERROR, REDUCER_STATUS } from 'features/constants';
import { getProfileDetail } from 'catalogs/profiles';
import Button from 'components/Button';
import CancelScreen from 'components/CancelScreen';
import Checkbox from 'components/Checkbox';
import FormattedNumber from 'components/FormattedNumber';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import Title from 'components/Title';
import PortfolioComposition from 'components/PortfolioCompositionOld';
import PortfolioCagr from 'components/PortfolioCompositionOld/PortfolioCagr';
import logo from 'images/atm-logo-positive-gbm.svg';
import { useNotificationContext } from 'features/NotificationContext';
import {
  clearQuestionnaire,
  fetchCreateAccount,
} from 'features/questions/questionsSlice';
import { LEGAL_DOCUMENT_TYPES, HTTP_STATUS_CODES } from 'api/constants';
import { formatErrors } from 'utils/formats';
import Api from 'api/madoff';
import { mockPortfolios } from '../profilerResults/constants';
import Portfolios from '../profilerResults/Portfolios';
import { FIRST_QUESTION_KEY } from '../questionnaire/data';
import { getMonthlyAmountLabel, getInvestmentTermLabel } from './utils';

const { URLS, GBM_ADVISOR_STATE } = config();

const ProfilerResultsOld = ({ history, featureFlags }) => {
  const questionnaire = useSelector((state) => state.questionnaire);
  const { showNotification } = useNotificationContext();
  const dispatch = useDispatch();
  const { t } = useTranslation('results');
  const recommendationDetail = getProfileDetail(
    questionnaire.recommendation.data.portfolio_type,
    questionnaire.recommendation.data,
  );
  const [profileDetail, setProfileDetail] = useState(recommendationDetail);
  const [legalDocument, setLegalDocument] = useState();
  const [isPrimaryLoading, setIsPrimaryLoading] = useState(false);
  const [cancelScreen, setCancelScreen] = useState(false);
  const [showLegalDoc, setShowLegalDoc] = useState(false);
  const [isCheckedGFA, setCheckedGFA] = useState(false);
  const [profileAgain, setProfileAgain] = useState(false);
  const [portfolioSelected, setPortfolioSelected] = useState(
    questionnaire.recommendation?.data,
  );

  useEffect(() => {
    const {
      currentKey,
      current: { data: questionnaireData },
    } = questionnaire;

    /* istanbul ignore else */
    if (isEmpty(questionnaireData) || currentKey === FIRST_QUESTION_KEY) {
      history.replace(`${ROUTES.question}/${FIRST_QUESTION_KEY}`);
    } else {
      const { error, status } = questionnaire.recommendation;

      if (status === REDUCER_STATUS.rejected && error) {
        showNotification('error', error);
      }
    }
  }, [questionnaire]);

  // Manage browser back button
  /* istanbul ignore next */
  useEffect(() => {
    const unblockHistory = history.block((_, action) => {
      if (action === 'POP') {
        handleProfileAgain();
        return false;
      }
      return true;
    });

    return () => {
      unblockHistory();
    };
  }, []);

  const monthlyAmount =
    questionnaire.monthlyAmount?.answer_value ||
    questionnaire.monthlyWithdrawal?.answer_value ||
    0;
  const investmentTerm =
    questionnaire.investmentTerm?.answer?.answer_value ||
    questionnaire.recommendation.data.investment_term / 2;
  const contract = store.get(GBM_ADVISOR_STATE)?.contract;
  const needAcceptance = store.get(GBM_ADVISOR_STATE)?.needAcceptance;
  const acceptance = {
    label: needAcceptance ? t('btnContinue') : t('btnCreateStrategy'),
    onClick: needAcceptance
      ? () => handlePrimary()
      : () => handleReturnAndCreateStrategy(),
  };
  const expectedAmount = getProjectedAmount(
    Number(questionnaire.initialAmount.answer_value),
    Number(monthlyAmount),
    investmentTerm,
    recommendationDetail.cagr / 100,
  );
  const isLoading =
    questionnaire.recommendation.status === REDUCER_STATUS.pending ||
    isPrimaryLoading;

  const handlePrimary = async () => {
    setIsPrimaryLoading(true);

    try {
      const document = await Advisor.GetGFADocument();
      let documentMkdwn = await markdown2Jsx(document?.file_name);

      if (isEmpty(documentMkdwn.data)) {
        documentMkdwn = await markdown2Jsx(document?.file_name, false);
      }

      if (!isEmpty(documentMkdwn.data)) {
        setLegalDocument(documentMkdwn.data);
        handleLegalDocVisbility(true);
      } else {
        registerEvents(
          TRACKING_EVENTS.v2.gfa_document_not_found.event,
          TRACKING_ENVIRONMENTS.MIXPANEL,
          {
            Success: false,
            Callback: parseError(documentMkdwn.err),
          },
        );
        showNotification('error', GENERIC_ERROR);
      }
    } catch (err) {
      registerEvents(
        TRACKING_EVENTS.v2.gfa_document_not_found.event,
        TRACKING_ENVIRONMENTS.MIXPANEL,
        {
          Callback: parseError(err),
          Success: false,
        },
      );

      showNotification('error', GENERIC_ERROR);
    } finally {
      setIsPrimaryLoading(false);
    }
  };

  const handleReturnAndCreateStrategy = async () => {
    const { strategyName, matrixPoint } = questionnaire;
    const { profile_session_id, external_id } = questionnaire.current.data;
    const { wtr, investment_term, portfolio_name } =
      questionnaire.recommendation.data;
    const partyId = store.get(GBM_ADVISOR_STATE)?.partyId;
    const legalDocuments = await Api.getContractAgreements(partyId);
    const generalFrameworkDocument = legalDocuments.find(
      (document) => document.type === LEGAL_DOCUMENT_TYPES.wealthManagement,
    );
    const { submit_account_creation } = TRACKING_EVENTS;

    if (!contract) {
      registerEvents(
        TRACKING_EVENTS.v2.contract_not_found.event,
        TRACKING_ENVIRONMENTS.MIXPANEL,
        {
          Success: false,
        },
      );

      showNotification('error', GENERIC_ERROR);
      return;
    }
    if (generalFrameworkDocument) {
      let status;
      try {
        const data = await Api.saveContractAgreements(partyId, {
          type: generalFrameworkDocument.type,
          version: generalFrameworkDocument.version,
        });
        status = data.status;
      } catch (err) {
        registerEvents(TRACKING_EVENTS.legal_documents_error.event, {
          Callback: formatErrors(err.response),
        });
      }

      if (status !== HTTP_STATUS_CODES.created) {
        store.clearAll();
        window.location.assign(URLS.dashboard);
        return;
      }
    }
    try {
      await dispatch(
        fetchCreateAccount({
          wtr,
          strategy: strategyName,
          matrixPoint,
          contract,
          portfolio: portfolio_name,
          profile: profile_session_id,
          external: external_id,
          investmentTerm: investment_term,
          partyId,
        }),
      );
    } catch (err) {
      registerEvents(submit_account_creation.event, {
        ...submit_account_creation.wealth_error,
        Callback: formatErrors(err),
      });
    }

    store.clearAll();
    window.location.assign(URLS.dashboard);
  };

  const handleConfirmCancel = (cancel) => {
    if (cancel) {
      if (profileAgain) {
        dispatch(clearQuestionnaire());
      } else {
        window.location.assign(`${URLS.dashboard}`);
      }
    } else {
      setCancelScreen(false);
    }
  };

  const handleProfileAgain = () => {
    registerEvents(
      TRACKING_EVENTS.v2.restart_profiler.event,
      TRACKING_ENVIRONMENTS.MIXPANEL,
    );
    setProfileAgain(true);
    setCancelScreen(true);
  };

  /**
   * When the modal of the general framework of action is shown, the body
   * will hide its scroll bar so that it does not have two scroll bars
   * at the same time. And when the modal is hidden, the body will show
   * its scroll bar.
   */
  const handleLegalDocVisbility = (isVisible) => {
    document.querySelector('body').style.overflowY = showLegalDoc
      ? 'hidden'
      : 'scroll';
    setShowLegalDoc(isVisible);
  };

  const handleClickPortfolio = (portfolio) => {
    const detail = getProfileDetail(
      portfolio.data.portfolio_type,
      portfolio.data,
    );
    setPortfolioSelected(portfolio.data);
    setProfileDetail(detail);
  };

  if (cancelScreen) return <CancelScreen onClose={handleConfirmCancel} />;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75 }}
    >
      {showLegalDoc && (
        <Modal loading>
          <button
            type="button"
            className="close"
            aria-label="Close"
            data-testid="hide-legal-doc"
            onClick={() => handleLegalDocVisbility(false)}
          />
          <div className="legal-document" data-testid="legal-document-test">
            <div className="legal-document__container">
              <div className="legal-document__title">
                {t('generalFramework')}
              </div>
              <div className="legal-document__content">{legalDocument}</div>
            </div>
            <div className="advisor-results__controls">
              <Checkbox
                key="acceptance"
                stateKey="acceptance"
                checked={isCheckedGFA}
                label={t('acceptanceGeneralFramework')}
                onChange={() => setCheckedGFA(!isCheckedGFA)}
                index={0}
              />
              <Button
                id="createAccount"
                text={t('btnCreateStrategy')}
                onClick={handleReturnAndCreateStrategy}
                className="align-center"
                disabled={!isCheckedGFA}
              />
            </div>
          </div>
        </Modal>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <section
          className="advisor-section advisor-section--old"
          data-testid="advisor-section-test"
        >
          <header>
            <img src={logo} alt="GBM+" width="100" height="21" />
            <button
              type="button"
              className="close"
              aria-label="Close"
              data-testid="advisor-close-test"
              onClick={() => setCancelScreen(true)}
            />
          </header>
          <div className="results-title">
            <Title
              centered
              hasIcon={false}
              subtitle={t('buildPortfolio')}
              title={questionnaire.strategyName || t('myStrategy')}
            />
          </div>
          <div className="projected-wrapper projected-wrapper--old">
            <div className="advisor-projected">
              <span className="section-wrapper">
                <div className="advisor-projected--section expected-amount">
                  <span className="advisor-projected--section__description">
                    {t('expectedAmount')}
                  </span>
                  <span className="advisor-projected--section__amount">
                    <span className="entire">
                      <FormattedNumber
                        value={expectedAmount || 0}
                        maximumFractionDigits={0}
                        minimumFractionDigits={0}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                      />
                    </span>
                    .<span className="cents">{getCents(100000)}</span>
                  </span>
                </div>
              </span>
              <span className="section-wrapper">
                <div className="advisor-projected--section initial-amount">
                  <span className="advisor-projected--section__description">
                    {t('initialAmount')}
                  </span>
                  <span className="advisor-projected--section__amount">
                    <span className="entire">
                      <FormattedNumber
                        value={questionnaire.initialAmount.answer_value || 0}
                        maximumFractionDigits={0}
                        minimumFractionDigits={0}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                      />
                    </span>
                    .
                    <span className="cents">
                      {getCents(questionnaire.initialAmount.answer_value)}
                    </span>
                  </span>
                </div>
              </span>
              <span className="section-wrapper">
                <div className="advisor-projected--section monthly-deposit">
                  <span className="advisor-projected--section__description">
                    {getMonthlyAmountLabel(
                      t,
                      questionnaire.monthlyAmount?.answer_value,
                    )}
                  </span>
                  <span className="advisor-projected--section__amount">
                    <span className="entire">
                      <FormattedNumber
                        value={monthlyAmount}
                        maximumFractionDigits={0}
                        minimumFractionDigits={0}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                      />
                    </span>
                    .<span className="cents">{getCents(monthlyAmount)}</span>
                  </span>
                </div>
              </span>
              <span className="section-wrapper">
                <div className="advisor-projected--section term-years">
                  <span className="advisor-projected--section__description">
                    {t('investmentTerm')}
                  </span>
                  <span className="advisor-projected--section__amount">
                    <span className="entire">
                      {getInvestmentTermLabel(
                        t,
                        investmentTerm,
                        questionnaire.investmentTerm?.max,
                      )}
                    </span>
                  </span>
                </div>
              </span>
            </div>
          </div>
          {featureFlags.wealthManagementPortfolios.isEnabled() && (
            <div className="projected-wrapper">
              <Portfolios
                portfolios={mockPortfolios}
                onClickPortfolio={handleClickPortfolio}
              />
            </div>
          )}
          <div className="advisor-results">
            <PortfolioComposition
              instruments={portfolioSelected?.instruments}
              type={portfolioSelected?.portfolio_type}
              profileDetail={profileDetail}
            />
            <PortfolioCagr cagr={profileDetail?.cagr} />
            <div className="advisor-results__controls">
              <Button
                text={acceptance.label}
                onClick={acceptance.onClick}
                className="align-center"
                testId="acceptance-button-test"
                size="big"
              />
              <Button
                id="profileAgain"
                testId="profile-again-test"
                text={t('btnProfileAgain')}
                kind="link"
                onClick={handleProfileAgain}
                className="align-center link link-old link--bold"
              />
            </div>
          </div>
        </section>
      )}
    </motion.div>
  );
};

ProfilerResultsOld.propTypes = {
  history: PropTypes.object,
  featureFlags: PropTypes.object,
};

export default ProfilerResultsOld;
