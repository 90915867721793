import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import PortfolioCard from 'components/PortfolioCard';
import { useTranslation } from 'react-i18next';

const CLASS_PREFIX = 'portfolios';

const Portfolios = ({ portfolios, onClickPortfolio }) => {
  const { t } = useTranslation('results');
  const [selected, setSelected] = useState({});

  const handleClickPortfolio = (portfolio) => {
    setSelected(portfolio);
    onClickPortfolio(portfolio);
  };

  useEffect(() => {
    handleClickPortfolio(portfolios[0]);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={CLASS_PREFIX}>
      <div className={`${CLASS_PREFIX}__header`}>
        <span className="typography-5 typography--semibold">
          {t('portfolios')}
        </span>
        <p className="typography-b-1 typography--regular">
          {t('portfoliosDescription')}
        </p>
      </div>
      <div
        className={clsx(`${CLASS_PREFIX}__cards`, {
          [`${CLASS_PREFIX}__cards--adjust`]: portfolios.length < 4,
          [`${CLASS_PREFIX}__cards--overflow`]: portfolios.length >= 4,
        })}
      >
        {portfolios.map((portfolio) => (
          <PortfolioCard
            {...portfolio}
            isSelected={selected.id === portfolio.id}
            onClick={() => handleClickPortfolio(portfolio)}
            key={`portfolio-card-${portfolio.id}`}
          />
        ))}
      </div>
      <div className={`${CLASS_PREFIX}__content`}>
        <span className="typography-5 typography--semibold">
          {selected.title}
        </span>
        <p className="typography-b-1 typography--regular">
          {selected.description}
        </p>
      </div>
    </div>
  );
};

Portfolios.defaultProps = {
  onClickPortfolio: () => null,
};

Portfolios.propTypes = {
  portfolios: PropTypes.array.isRequired,
  onClickPortfolio: PropTypes.func,
};

export default Portfolios;
