import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class Portal extends React.Component {
  componentDidMount() {
    this.setEventListener();
  }

  componentWillUnmount() {
    this.setEventListener('remove');
  }

  setEventListener = (type = 'add') => {
    const {
      eventListener,
      eventListenerFunc,
      eventListenerNode,
      eventListenerType,
    } = this.props;

    eventListener &&
      document
        .getElementById(eventListenerNode)
        [`${type}EventListener`](eventListenerType, eventListenerFunc);
  };

  render() {
    const { children, node } = this.props;
    return ReactDOM.createPortal(
      children,
      document.getElementById(node || 'portal-target'),
    );
  }
}

Portal.defaultProps = {
  eventListener: false,
};

Portal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  node: PropTypes.object,
  eventListener: PropTypes.bool,
  eventListenerType: PropTypes.string,
  eventListenerFunc: PropTypes.func,
  eventListenerNode: PropTypes.string,
};

export default Portal;
