import { LEGAL_DOCUMENT_TYPES } from 'api/constants';

export const legalDocumentsAdapter = (documentsToAccept, acceptedDocuments) => {
  const latestDocumentsToAccept = documentsToAccept.filter(
    (document) =>
      document.type !== LEGAL_DOCUMENT_TYPES.PERSONAL_ID_DATA_VERIFICATION,
  );

  const lastestMapping = [...latestDocumentsToAccept].map((document) => {
    const fileName = document?.uri?.split('/').pop().split('.')[0];

    return {
      ...document,
      file_name: fileName,
    };
  });

  if (!acceptedDocuments.length) {
    // User has not accepted any legal documents, hence he/she need to accept all
    return latestDocumentsToAccept;
  }

  // User has accepted some documents, lets check if those are the latest
  return lastestMapping.filter((documentToAccept) => {
    const acceptedDocument = acceptedDocuments.find(
      ({ type, version }) =>
        type === documentToAccept.type && version === documentToAccept.version,
    );

    // The document is pending to be accepted if the user has not
    // accepted it previously
    return !acceptedDocument;
  });
};
