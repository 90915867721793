import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useBreakpoint } from 'hooks/useBreakpoint';
import Bars from 'components/Bars';
import ToolTip from 'components/ToolTip';
import infoIcn from 'images/icons/atm-icn-info-white.svg';

const CLASS_PREFIX = 'portfolio-properties';

const PortfolioProperties = ({ description, property, percentage }) => {
  const { t } = useTranslation('simulator');
  const breakpoint = useBreakpoint();
  const isMobileDevice = breakpoint === 'md' || breakpoint === 'sm';

  return (
    <div className={CLASS_PREFIX}>
      <div className={`${CLASS_PREFIX}__title`}>
        <span>{property}</span>
        <ToolTip
          content={description}
          imageIndicator={infoIcn}
          position={isMobileDevice ? 'top' : 'right'}
        />
      </div>
      <div className={`${CLASS_PREFIX}__bars`}>
        <Bars percentage={percentage} offset={isMobileDevice ? 33 : 44} />
      </div>
      <div className={`${CLASS_PREFIX}__levels`}>
        <div className={`${CLASS_PREFIX}__level`}>{t('levelDown')}</div>
        <div className={`${CLASS_PREFIX}__level`}>{t('levelUp')}</div>
      </div>
    </div>
  );
};

PortfolioProperties.propTypes = {
  description: PropTypes.string,
  property: PropTypes.string,
  percentage: PropTypes.number,
};

export default PortfolioProperties;
