import mixpanel from 'mixpanel-browser';
import config from 'config';

const { IS_MIXPANEL_ENABLED } = config();

const actions = {
  alias: (id) => IS_MIXPANEL_ENABLED && mixpanel.alias(id),
  track: (name, props = {}, cb = () => null) =>
    IS_MIXPANEL_ENABLED && mixpanel.track(name, props, cb),
  people: {
    set: (props) => IS_MIXPANEL_ENABLED && mixpanel.people.set(props),
  },
};

export const Mixpanel = actions;
