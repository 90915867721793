import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import observePlugin from 'store/plugins/observe';
import store from 'store';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Auth from 'api/auth/auth';
import { getAnswerByType } from 'utils';
import ROUTES from 'routes';
import config from 'config';

import BackButton from 'components/BackButton';
import Loader from 'components/Loader';
import WelcomeScreen from 'components/WelcomeScreen';
import CancelScreen from 'components/CancelScreen';
import useBodyScroll from 'hooks/useBodyScroll';
import { REDUCER_STATUS, QUESTIONS_FOR_RESULTS } from 'features/constants';
import {
  fetchProfileDetails,
  fetchQuestionById,
  fetchQuestionnaires,
  saveAnswers,
  saveStrategyNameAnswer,
  setShowWelcomeScreen,
  saveStrategyGoal,
} from 'features/questions/questionsSlice';
import { useNotificationContext } from 'features/NotificationContext';

import {
  FIRST_QUESTION_KEY,
  STATIC_QUESTIONS,
  QUESTION_TO_NAME_KEY,
  THIRD_QUESTION_KEY,
} from './data';
import Steps from './steps';

store.addPlugin(observePlugin);

let staticQuestion = {
  ...STATIC_QUESTIONS.v1[0],
};

const { URLS, GBM_ADVISOR_STATE } = config();

const Questionnaire = ({ history, currentStep, featureFlags }) => {
  const [data, setData] = useState({
    btnCloseRedirect: false,
    cancelScreen: false,
    client: '',
    currentQuestion: {},
    previousQuestion: {},
    currentStep,
    welcomeScreen: true,
  });
  const questionnaire = useSelector((state) => state.questionnaire);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState('goForward');
  const [previousQuestion, setPreviousQuestion] = useState({});
  const [lastAnswerValue, setLastAnswerValue] = useState();
  const { showNotification } = useNotificationContext();
  const strategyName = store.get(GBM_ADVISOR_STATE)?.strategyName;
  const account = store.get(GBM_ADVISOR_STATE)?.account;
  const contract = store.get(GBM_ADVISOR_STATE)?.contract;
  const userEmail = store.get(GBM_ADVISOR_STATE)?.userEmail;

  useBodyScroll();
  // Did mount
  useEffect(() => {
    // Check if there is an accessToken
    const auth = new Auth();
    if (!auth.getToken()) {
      window.location.assign(`${URLS.dashboard}`);
    }

    if (!strategyName) dispatch(fetchQuestionnaires());

    if ((!account && !contract) || !userEmail) {
      window.location.replace(URLS.dashboard);
    }
  }, []);

  useEffect(() => {
    const strategyData = {
      accountId: account,
      contractId: contract,
    };
    if (strategyName) {
      dispatch(fetchProfileDetails(strategyData));
    }
  }, [account, contract]);

  // Fill out the reprofiling questionnaire
  useEffect(() => {
    if (
      questionnaire?.lastQuestionnaire?.data?.sections?.length &&
      questionnaire?.currentQuestion?.data?.section_id
    ) {
      let answer;

      const {
        currentQuestion: { data: currentData },
        lastQuestionnaire: { data: lastData },
        lastProfileDetails: { data: lastProfileData },
      } = questionnaire;

      const findSection = lastData?.sections?.find(
        (section) => section.section_id === currentData?.section_id,
      );

      answer = findSection?.answers?.find(
        (item) => item.question_id === currentData?.question_id,
      );

      // Uses current amount if the account is funded
      if (
        currentData.question_key === QUESTIONS_FOR_RESULTS.initialAmount &&
        !!lastProfileData?.current_amount
      ) {
        answer = {
          ...answer,
          answer_value: String(lastProfileData.current_amount),
        };
      }
      setLastAnswerValue(answer);
    }
  }, [questionnaire]);

  // Handling errors
  const checkIfHasError = () => {
    const { error: currentError, status: currentStatus } =
      questionnaire.current;
    const { error: currentQuestionError, status: currentQuestionStatus } =
      questionnaire.currentQuestion;
    let error = null;

    if (currentStatus === REDUCER_STATUS.rejected && currentError) {
      error = currentError;
    }

    if (
      currentQuestionStatus === REDUCER_STATUS.rejected &&
      currentQuestionError
    ) {
      error = currentQuestionError;
    }

    if (error) {
      showNotification('error', error);
    }
  };

  const handleFulfilledQuestionnaire = () => {
    setLoading(false);
    // eslint-disable-next-line camelcase
    if (questionnaire.currentQuestion.data?.is_questionnaire_finished) {
      const redirectUrl = strategyName ? ROUTES.results : ROUTES.done;
      return history.push(`${redirectUrl}`);
    }

    const { currentQuestion } = data;
    const nextQuestion = questionnaire.currentQuestion.data;

    // When user go back from the third question, prepare the staticQuestion
    // eslint-disable-next-line camelcase
    if (QUESTION_TO_NAME_KEY.includes(nextQuestion?.question_key)) {
      staticQuestion = {
        ...staticQuestion,
        previous_question_id: nextQuestion.question_id,
      };
    }

    // When user is reprofiling, skip staticQuestion
    if (!!strategyName && questionnaire?.strategyName !== strategyName) {
      const answerData = {
        question: staticQuestion,
        questionId: staticQuestion.question_id,
        answer: strategyName,
        isReprofiling: !!strategyName,
      };
      dispatch(saveStrategyNameAnswer(answerData));
    }

    const goForwardToStatic = QUESTION_TO_NAME_KEY.includes(
      currentQuestion.question_key,
    );

    if (
      THIRD_QUESTION_KEY.includes(currentQuestion.question_key) &&
      direction === 'goBackward' &&
      !strategyName
    ) {
      setPreviousQuestion(currentQuestion);
      return showStaticQuestion();
    }

    if (goForwardToStatic && !strategyName && direction === 'goForward') {
      return showStaticQuestion();
    }

    if (!isEmpty(previousQuestion)) {
      const { question_key: questionKey } = previousQuestion;
      setData({
        ...data,
        currentQuestion: previousQuestion,
      });
      setPreviousQuestion({});
      return history.push(`${ROUTES.question}/${questionKey}`);
    }
    setData({
      ...data,
      currentQuestion: nextQuestion,
    });
    // eslint-disable-next-line camelcase
    return history.push(`${ROUTES.question}/${nextQuestion?.question_key}`);
  };

  useEffect(() => {
    checkIfHasError();

    setData({
      ...data,
      welcomeScreen: questionnaire.showWelcomeScreen,
    });

    if (
      questionnaire.currentQuestion.status === REDUCER_STATUS.pending ||
      questionnaire.lastQuestionnaire.status === REDUCER_STATUS.pending
    ) {
      setLoading(true);
    }

    if (questionnaire.currentQuestion.status === REDUCER_STATUS.fulfilled) {
      handleFulfilledQuestionnaire();
    }
    // eslint-disable-next-line
  }, [questionnaire]);

  /**
   * When the user presses the browser back button, shows the cancel screen if
   * is in the first question, otherwise go back to the previous question.
   */
  useEffect(() => {
    const { action } = history;
    const { welcomeScreen, cancelScreen, currentQuestion } = data;

    if (action === 'POP' && !welcomeScreen && !cancelScreen) {
      if (currentQuestion.question_key === FIRST_QUESTION_KEY) {
        enableCancelScreen();
      } else {
        handleBackwardStep();
      }
    }
    // eslint-disable-next-line
  }, [history, history.location, history.action]);

  const showStaticQuestion = () => {
    staticQuestion = {
      ...staticQuestion,
      description: `${STATIC_QUESTIONS.v1[0].description} <strong>${questionnaire.strategyGoal}</strong>`,
    };
    setData({
      ...data,
      currentQuestion: staticQuestion,
    });
    history.push(`${ROUTES.question}/${staticQuestion.question_key}`);
  };

  const handleForwardStep = (currentQuestion, answer) => {
    setDirection('goForward');
    const answerByType = getAnswerByType(currentQuestion, answer);

    if (currentQuestion.question_key !== staticQuestion.question_key) {
      const answerData = {
        answer: answerByType,
        value: answer?.value,
        questionId: currentQuestion.question_id,
        questionKey: currentQuestion.question_key,
        metadata: currentQuestion.metadata,
        questionnaire: questionnaire.current.data,
        contractId: contract,
        accountId: account,
        isReprofiling: !!strategyName,
        isEnabledFeatureFlag:
          featureFlags.tmpWealthManagementReprofiling.isEnabled(),
      };

      dispatch(saveAnswers(answerData));
      if (QUESTION_TO_NAME_KEY.includes(currentQuestion.question_key)) {
        dispatch(saveStrategyGoal(answer.value));
      }
    } else {
      const answerData = {
        question: staticQuestion,
        questionId: staticQuestion.question_id,
        answer,
      };
      dispatch(saveStrategyNameAnswer(answerData));
    }
  };

  const handleBackwardStep = () => {
    setDirection('goBackward');
    setPreviousQuestion({});
    const { currentQuestion } = data;
    const questionData = {
      questionnaire: questionnaire.current.data,
      questionId: currentQuestion.previous_question_id,
    };
    dispatch(fetchQuestionById(questionData));
  };

  const startQuestionnaire = () => {
    setData({
      ...data,
      welcomeScreen: false,
      cancelScreen: false,
    });
    dispatch(setShowWelcomeScreen(false));
  };

  const handleConfirmCancel = (cancel) => {
    if (cancel) {
      store.clearAll();
      window.location.assign(
        !strategyName
          ? `${URLS.dashboard}`
          : `${URLS.dashboard}/wealth-management/contract/${contract}/account/${account}`,
      );
    } else {
      setData({
        ...data,
        cancelScreen: cancel,
      });
    }
  };

  const enableCancelScreen = () => {
    setData({
      ...data,
      cancelScreen: true,
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75 }}
    >
      <div className="advisor">
        {!data.welcomeScreen && !data.cancelScreen && (
          <>
            <div className="advisor-container">
              <header>
                <div
                  className={clsx('header__back', {
                    'header__back--hidden':
                      // eslint-disable-next-line camelcase
                      !data.currentQuestion?.previous_question_id,
                  })}
                >
                  <BackButton
                    onClick={handleBackwardStep}
                    testId="back-button"
                  />
                </div>
                <div className="header__close">
                  <button
                    type="button"
                    data-testid="enable-cancel-screen-test"
                    onClick={enableCancelScreen}
                    aria-label="Close"
                    className="questionnaire__close-button"
                  />
                </div>
              </header>
              {loading ? (
                <Loader />
              ) : (
                <main className="advisor-container__wrapper">
                  <div
                    className={`questionnaire-container step-${currentStep}`}
                    data-testid="questionnaire-container-test"
                  >
                    <Steps
                      accountProfiling={!!data.accountProfiling}
                      currentQuestion={data.currentQuestion}
                      currentStep={currentStep}
                      onForwardStep={handleForwardStep}
                      lastAnswerValue={lastAnswerValue}
                      t={t}
                    />
                  </div>
                </main>
              )}
            </div>
            <aside className="advisor-sidebar" />
          </>
        )}
        {data.welcomeScreen && !data.cancelScreen && (
          <WelcomeScreen
            featureFlags={featureFlags}
            onCloseRedirect={enableCancelScreen}
            onStartQuestionnaire={startQuestionnaire}
            visible={data.welcomeScreen}
          />
        )}
        {data.cancelScreen && <CancelScreen onClose={handleConfirmCancel} />}
      </div>
    </motion.div>
  );
};

Questionnaire.propTypes = {
  currentStep: PropTypes.string,
  featureFlags: PropTypes.object,
  history: PropTypes.object,
};

export default Questionnaire;
