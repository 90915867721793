const ROUTES = {
  home: '/',
  done: '/done',
  question: '/question',
  questionnaire: '/questionnaire',
  recommendation: '/recommendation',
  results: '/results',
  step: '/step',
  success: '/success',
  view: '/view',
  simulator: '/simulador-de-inversiones',
};

export default ROUTES;
