import axios from 'axios';
import config from 'config';

import Auth from 'api/auth/auth';
import { HTTP_STATUS_CODES, HTTP_VERBS } from 'api/constants';

const { URLS } = config();

export const baseAdapter = (response) => ({
  data: response.data,
  status: response.status,
  headers: response.headers,
});

export const request = (
  url,
  options = {},
  adapter = baseAdapter,
  auth = true,
) => {
  const _options = {
    url,
    method: options.method || HTTP_VERBS.get,
    data: options.data || {},
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...options.headers,
    },
  };

  if (auth) {
    const token = new Auth().getToken();
    _options.headers.Authorization = `Bearer ${token}`;
  }

  const instance = axios.create(_options);

  instance.interceptors.request.use(
    (configuration) => configuration,
    (error) => Promise.reject(error),
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === HTTP_STATUS_CODES.unauthorized) {
        window.location.assign(`${URLS.dashboard}`);
      }

      return Promise.reject(error.response);
    },
  );

  return instance()
    .then((response) => adapter(response))
    .catch((error) => Promise.reject(adapter(error)));
};
