import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DonutChart from 'components/Chart';

const Chart = ({ debt, equity, portfolioType }) => {
  const { t } = useTranslation('results');
  const debtMessage = {
    debt: t('debtMorePercentage'),
    equity: t('equitiesLessPercentage'),
  };
  const equityMessage = {
    debt: t('debtLessPercentage'),
    equity: t('equitiesMorePercentage'),
  };
  const typeMessage = {
    settlement: debtMessage,
    debt: debtMessage,
    preservation: debtMessage,
    balance: debtMessage,
    appreciation: equityMessage,
    growth: equityMessage,
    equities: equityMessage,
  };
  return (
    <div className="advisor-results--chart">
      <span className="advisor-results--chart-results">
        <DonutChart debt={debt} equity={equity} />
      </span>
      <span className="advisor-results__label">
        {t('compositionInvestmentReprofile')}
      </span>
      <div className="advisor-results--chart-instruments">
        {!!equity && (
          <div className="advisor-results__chart-description">
            <span className="chart-instruments chart-instruments--equity">
              <span className="chart-instruments--type-title">
                <p>{t('equities')}</p>
              </span>
              <span className="chart-instruments--type-percentage">
                <p>{equity}%</p>
              </span>
            </span>
            <span className="chart-description__text">
              {typeMessage[portfolioType].equity}
            </span>
          </div>
        )}
        {!!debt && (
          <div className="advisor-results__chart-description">
            <span className="chart-instruments chart-instruments--debt">
              <span className="chart-instruments--type-title">
                <p>{t('debt')}</p>
              </span>
              <span className="chart-instruments--type-percentage">
                <p>{debt}%</p>
              </span>
            </span>
            <span className="chart-description__text">
              {typeMessage[portfolioType].debt}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

Chart.propTypes = {
  debt: PropTypes.number,
  equity: PropTypes.number,
  portfolioType: PropTypes.string,
};

export default Chart;
