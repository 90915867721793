import React, { useContext, useEffect } from 'react';

import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { func, object } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useBreakpoint } from 'hooks/useBreakpoint';
import Loader from 'components/Loader';
import Button from 'components/Button';
import { formatTargetsList, formattedCurrency } from 'containers/utils/formats';
import { fetchTargets } from 'containers/utils/fetch';

import { LANGUAGES } from 'containers/utils/constants';
import { SimulatorContext } from '../Context';

import { BASE_PATH, CURRENCY_TYPE } from '../constants';
import { targetFormSchema } from '../validationSchema';

const CLASS_PREFIX = 'simulator';
const className = clsx('input__input input__input--big');

const props = {
  className,
};

const TargetInfo = () => {
  const { simulatorData, setSimulatorData } = useContext(SimulatorContext);
  const { targetInfo, targetsList } = simulatorData;
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isMobileDevice = breakpoint === 'md' || breakpoint === 'sm';
  const { t } = useTranslation('simulator');
  const currentLang = simulatorData.lng;

  const errorMessages = {
    minAmountRequired: (minAmount) =>
      t('minAmountRequired', {
        min: formattedCurrency(currentLang, minAmount),
      }),
    maxAmountRequired: (maxAmount) =>
      t('maxAmountRequired', {
        max: formattedCurrency(currentLang, maxAmount),
      }),
    amountRequired: t('amountRequired'),
    minYearsRequired: (minYears) =>
      t('minYearsRequired', {
        min: minYears,
        years: minYears > 1 ? t('years') : t('year'),
      }),
    maxYearsRequired: (maxYears) =>
      t('maxYearsRequired', {
        max: maxYears,
        years: maxYears > 1 ? t('years') : t('year'),
      }),
    yearsRequired: t('yearsRequired'),
  };
  const formattedTargetList = formatTargetsList(targetsList?.data);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(targetFormSchema(errorMessages)),
  });

  const customStyles = {
    control: () => ({
      minHeight: 34,
      display: 'flex',
      borderBottom: '1px solid #a8a8a8',
    }),
    dropdownIndicator: () => ({
      position: 'relative',
      right: '0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: () => ({
      minWidth: 352,
      position: 'absolute',
      backgroundColor: '#393939',
      zIndex: 2,
    }),
    menuList: () => ({
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (styles, { isSelected, isFocused }) => {
      const backgroundColor = isSelected || isFocused ? '#525252' : '#393939';

      return {
        ...styles,
        backgroundColor,
        fontSize: 16,
        ':active': {
          backgroundColor: '#525252',
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: '#FFF',
      padding: '2px 0px',
      margin: 0,
      fontSize: 20,
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: '2px 0px',
    }),
  };

  const updateTargetValues = (updateFunction, targetItem) => {
    updateFunction('target', targetItem.target);
    updateFunction('first_deposit', targetItem.first_deposit);
    updateFunction('monthly_deposit', targetItem.monthly_deposit);
    updateFunction('years', targetItem.years);
    clearErrors();
  };

  const currencyValue = watch('target')?.currency;

  const onSubmitForm = (data) => {
    const route = `${BASE_PATH}/personal-info`;
    setSimulatorData({
      ...simulatorData,
      targetInfo: {
        data,
        isFilled: true,
      },
    });
    history.push(route);
  };

  useEffect(() => {
    if (!targetsList.isFilled) {
      fetchTargets(simulatorData, setSimulatorData);
    }

    if (targetInfo.isFilled && targetsList.isFilled) {
      const preFilledForm = {
        target: targetInfo.data.target,
        first_deposit: targetInfo.data.first_deposit,
        monthly_deposit: targetInfo.data.monthly_deposit,
        years: targetInfo.data.years,
      };
      updateTargetValues(setValue, preFilledForm);
    }
  }, []);

  useEffect(() => {
    if (!targetInfo.isFilled && targetsList.isFilled) {
      const defaultTargetValues = {
        target: formattedTargetList[0],
        first_deposit: formattedTargetList[0].default_first_deposit,
        monthly_deposit: formattedTargetList[0].default_monthly_deposit,
        years: formattedTargetList[0].default_years_minimum,
      };
      updateTargetValues(setValue, defaultTargetValues);
    }
  }, [targetsList]);

  return targetsList.isFilled ? (
    <div
      className={clsx(CLASS_PREFIX, { 'simulator--dark': true })}
      data-testid="simulator"
    >
      <form
        className={`${CLASS_PREFIX}__container`}
        data-testid="simulator-form"
      >
        <div
          className={`${CLASS_PREFIX}__content ${CLASS_PREFIX}__content--stretch`}
        >
          <div
            className={clsx('font-mabry typography--regular', {
              'typography-2': !isMobileDevice,
              'typography-3': isMobileDevice,
            })}
          >
            {t('discoverProductsTitle')}
          </div>
          <div className="mt-40 mb-60">
            <div className="typography-6 typography--regular">
              {t('discoverProductsDescription')}
            </div>
            {currentLang === LANGUAGES.es && (
              <div className="typography-6 typography--regular mt-16">
                <a
                  href="https://media.gbm.com/inversion/calcula-tu-rendimiento-con-el-simulador-de-inversiones-gbm/?utm_source=SimuladorInversiones&utm_medium=Organico&utm_campaign=SimuladorInversiones"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('discoverProductsRecommendation')}
                </a>
              </div>
            )}
          </div>
          {!isMobileDevice ? (
            <Button
              className={`${CLASS_PREFIX}__btn-test`}
              testId="btnSimulatorTestNow"
              onClick={handleSubmit(onSubmitForm)}
              kind="secondary"
              text={t('btnTestNow')}
            />
          ) : null}
        </div>
        <div className={`${CLASS_PREFIX}__form`}>
          <div className="input-wrapper" data-testid="select-input">
            <div className="input__label">{t('target')}</div>
            <div className="input input--question">
              <Controller
                name="target"
                control={control}
                render={({ field: { value } }) => (
                  <Select
                    value={value}
                    onChange={(targetItem) => {
                      const defaultTargetValuesSelected = {
                        target: targetItem,
                        first_deposit: targetItem.default_first_deposit,
                        monthly_deposit: targetItem.default_monthly_deposit,
                        years: targetItem.default_years_minimum,
                      };
                      updateTargetValues(setValue, defaultTargetValuesSelected);
                    }}
                    styles={customStyles}
                    options={formattedTargetList}
                    isSearchable={false}
                  />
                )}
              />
            </div>
          </div>
          <div className="input-wrapper" data-testid="multiple-input">
            <div className="input__label">{t('firstDeposit')}</div>
            <div
              className={clsx(
                'input input--big input--question input--currency',
                {
                  'input--error': errors?.first_deposit?.message,
                  'input--currency-usd': currencyValue === CURRENCY_TYPE.usd,
                },
              )}
            >
              <Controller
                name="first_deposit"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumericFormat
                    autoFocus={false}
                    {...props}
                    value={value}
                    thousandSeparator
                    decimalScale={0}
                    allowNegative={false}
                    onValueChange={(e) => onChange(e.floatValue)}
                    aria-label="first_deposit"
                  />
                )}
              />
            </div>
            <span className="input--error-label">
              {errors?.first_deposit?.message}
            </span>
          </div>
          <div className="input-wrapper" data-testid="multiple-input">
            <div className="input__label">{t('monthlyDeposit')}</div>
            <div
              className={clsx(
                'input input--big input--question input--currency',
                {
                  'input--error': errors?.monthly_deposit?.message,
                  'input--currency-usd': currencyValue === CURRENCY_TYPE.usd,
                },
              )}
            >
              <Controller
                name="monthly_deposit"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumericFormat
                    autoFocus={false}
                    {...props}
                    value={value}
                    thousandSeparator
                    decimalScale={0}
                    allowNegative={false}
                    onValueChange={(e) => onChange(e.floatValue)}
                    aria-label="monthly_deposit"
                  />
                )}
              />
            </div>
            <span className="input--error-label">
              {errors?.monthly_deposit?.message}
            </span>
          </div>
          <div className="input-wrapper" data-testid="multiple-input">
            <div className="input__label">{t('timePeriod')}</div>
            <div
              className={clsx('input input--big input--question input--years', {
                'input--error': errors?.years?.message,
                'input--years-eng': currentLang === LANGUAGES.en,
              })}
            >
              <Controller
                name="years"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumericFormat
                    autoFocus={false}
                    {...props}
                    value={value}
                    thousandSeparator
                    decimalScale={0}
                    allowNegative={false}
                    onValueChange={(e) => onChange(e.floatValue)}
                    aria-label="years"
                  />
                )}
              />
            </div>
            <span className="input--error-label">{errors?.years?.message}</span>
          </div>
        </div>
        {isMobileDevice ? (
          <div className="btn-mobile">
            <Button
              className={`${CLASS_PREFIX}__btn-test`}
              testId="btnSimulatorTestNow"
              onClick={handleSubmit(onSubmitForm)}
              kind="secondary"
              text={t('btnTestNow')}
            />
          </div>
        ) : null}
      </form>
    </div>
  ) : (
    <Loader animated={false} type="dark" />
  );
};

TargetInfo.propTypes = {
  history: object,
  onSubmitObjectives: func,
};
TargetInfo.defaultProps = {
  history: {},
  onSubmitObjectives: () => {},
};

export default TargetInfo;
