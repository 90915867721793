import { useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const body = document.querySelector('body');

const useBodyScroll = () => {
  useEffect(() => {
    disableBodyScroll(body);
    return () => enableBodyScroll(body);
  }, []);

  return {
    disableBodyScroll,
    enableBodyScroll,
    body,
  };
};

export default useBodyScroll;
