/**
 * Generate a random secure number within a range if specified
 * @param min
 * @param max
 * @param withRange
 * @returns {number}
 */
const generateSecureRandomNumber = (min, max, withRange = false) => {
  const crypto = window.crypto || window.msCrypto;
  const randomBuffer = new Uint32Array(1);
  crypto.getRandomValues(randomBuffer);

  if (withRange) {
    const randomNumber = randomBuffer[0] / (0xffffffff + 1);
    const maxFloor = Math.floor(max);
    const minCeil = Math.ceil(min);
    return Math.floor(randomNumber * (maxFloor - minCeil + 1)) + minCeil;
  }

  return randomBuffer[0];
};

export default generateSecureRandomNumber;
