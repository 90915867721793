import registerEvents, { parseError } from 'utils/registerEvents';
import { TRACKING_EVENTS, TRACKING_ENVIRONMENTS } from 'utils/constants';

import config from '../config';
import { request } from './utils/request';
import { GFA_DOC_TYPE } from './constants';

const env = config();
const { API } = env;

export class Advisor {
  static async GetGFADocument() {
    try {
      const { data } = await request(`${API.v2}/legal-documents`);
      const gfaDocument = data.find(
        (document) => document.type === GFA_DOC_TYPE,
      );
      gfaDocument.file_name = gfaDocument?.uri?.split('/').pop().split('.')[0];
      return gfaDocument;
    } catch (err) {
      registerEvents(
        TRACKING_EVENTS.v2.gfa_document_not_found.event,
        TRACKING_ENVIRONMENTS.MIXPANEL,
        {
          Callback: parseError(err),
          Success: false,
        },
      );

      return {};
    }
  }
}
