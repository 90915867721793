/* eslint-disable indent */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import store from 'store';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import ROUTES from 'routes';
import config from 'config';
import { getCents } from 'utils';
import { markdown2Jsx } from 'utils/markdown2Jsx';
import { Advisor } from 'api';
import { HTTP_STATUS_CODES, LEGAL_DOCUMENT_TYPES } from 'api/constants';
import Api from 'api/madoff';
import registerEvents, { parseError } from 'utils/registerEvents';
import {
  TRACKING_EVENTS,
  TRACKING_ENVIRONMENTS,
  PROFILING_VERSION,
  SHORT_TERM_PORTFOLIO,
} from 'utils/constants';

import clockIcon from 'images/icons/atm-icn-clock.svg';
import strokeIcon from 'images/icons/atm-icn-stroke.svg';
import { GENERIC_ERROR, REDUCER_STATUS } from 'features/constants';
import { getProfileDetail } from 'catalogs/profiles';
import Button from 'components/Button';
import CancelScreen from 'components/CancelScreen';
import Checkbox from 'components/Checkbox';
import FormattedNumber from 'components/FormattedNumber';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import Title from 'components/Title';
import PortfolioComposition from 'components/PortfolioComposition';
import PortfolioCagr from 'components/PortfolioComposition/PortfolioCagr';
import logo from 'images/atm-logo-positive-gbm.svg';
import { useNotificationContext } from 'features/NotificationContext';
import {
  clearQuestionnaire,
  saveReprofile,
  fetchCreateAccount,
} from 'features/questions/questionsSlice';
import Card from 'components/Card';
import InformationSection from 'components/InformationSection';
import ConfirmSaveScreen from 'components/ConfirmSaveScreen';
import { formatErrors } from 'utils/formats';
import { mockPortfolios } from './constants';
import Portfolios from './Portfolios';
import { FIRST_QUESTION_KEY } from '../questionnaire/data';
import {
  getMonthlyAmountLabel,
  getInvestmentTermLabel,
  formattedCurrency,
} from './utils';

const { URLS, GBM_ADVISOR_STATE } = config();

const ProfilerResults = ({ history, featureFlags }) => {
  const questionnaire = useSelector((state) => state.questionnaire);
  const { showNotification } = useNotificationContext();
  const dispatch = useDispatch();
  const { t } = useTranslation('results');
  const [profileDetail, setProfileDetail] = useState();
  const [legalDocument, setLegalDocument] = useState();
  const [isPrimaryLoading, setIsPrimaryLoading] = useState(false);
  const [cancelScreen, setCancelScreen] = useState(false);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [isCheckedGFA, setCheckedGFA] = useState(false);
  const [profileAgain, setProfileAgain] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [portfolioSelected, setPortfolioSelected] = useState(
    questionnaire.recommendation?.data,
  );
  const [isShortTerm, setIsShortTerm] = useState(false);
  const isReprofiling = store.get(GBM_ADVISOR_STATE)?.strategyName;
  const accountId = store.get(GBM_ADVISOR_STATE)?.account;
  const { lastProfileDetails } = questionnaire;
  const profilingVersion = lastProfileDetails?.data?.created_at
    ? PROFILING_VERSION.v2
    : PROFILING_VERSION.v1;

  useEffect(() => {
    const recommendationDetail = getProfileDetail(
      questionnaire.recommendation.data.portfolio_type,
      questionnaire.recommendation.data,
    );
    if (
      !profileDetail?.table &&
      questionnaire.recommendation.status === REDUCER_STATUS.fulfilled
    ) {
      setProfileDetail(recommendationDetail);
      setIsShortTerm(
        portfolioSelected.wtr === SHORT_TERM_PORTFOLIO.wtr &&
          portfolioSelected.portfolio_type === SHORT_TERM_PORTFOLIO.type,
      );
    }
  }, [profileDetail, questionnaire]);

  useEffect(() => {
    const {
      currentKey,
      current: { data: questionnaireData },
    } = questionnaire;

    /* istanbul ignore else */
    if (isEmpty(questionnaireData) || currentKey === FIRST_QUESTION_KEY) {
      history.replace(`${ROUTES.question}/${FIRST_QUESTION_KEY}`);
    } else {
      const { error, status } = questionnaire.recommendation;

      if (status === REDUCER_STATUS.rejected && error) {
        showNotification('error', error);
      }

      if (
        questionnaire.reprofile.status === REDUCER_STATUS.rejected &&
        questionnaire.reprofile.error
      ) {
        showNotification('error', questionnaire.reprofile.error);
      }

      if (questionnaire.reprofile.status === REDUCER_STATUS.fulfilled) {
        setShowConfirmSave(true);
      }
    }
  }, [questionnaire]);

  // Manage browser back button
  /* istanbul ignore next */
  useEffect(() => {
    const unblockHistory = history.block((_, action) => {
      if (action === 'POP') {
        handleProfileAgain();
        return false;
      }
      return true;
    });

    registerEvents(
      TRACKING_EVENTS.v2.reprofiling_results_view.event,
      TRACKING_ENVIRONMENTS.MIXPANEL,
      {
        profiling_version: profilingVersion,
      },
    );

    return () => {
      unblockHistory();
    };
  }, []);

  const monthlyAmount =
    questionnaire.monthlyAmount?.answer_value ||
    questionnaire.monthlyWithdrawal?.answer_value ||
    0;
  const contract = store.get(GBM_ADVISOR_STATE)?.contract;
  const needAcceptance = store.get(GBM_ADVISOR_STATE)?.needAcceptance;
  const initialPortfolio = store.get(GBM_ADVISOR_STATE)?.initialPortfolio;
  const acceptance = {
    label: needAcceptance ? t('btnContinue') : t('btnCreateStrategy'),
    onClick: needAcceptance
      ? () => handlePrimary()
      : () => handleReturnAndCreateStrategy(),
  };

  const isLoading =
    questionnaire.recommendation.status === REDUCER_STATUS.pending ||
    isPrimaryLoading;

  const handlePrimary = async () => {
    setIsPrimaryLoading(true);

    try {
      const document = await Advisor.GetGFADocument();
      let documentMkdwn = await markdown2Jsx(document?.file_name);

      if (isEmpty(documentMkdwn.data)) {
        documentMkdwn = await markdown2Jsx(document?.file_name, false);
      }

      if (!isEmpty(documentMkdwn.data)) {
        setLegalDocument(documentMkdwn.data);
      } else {
        registerEvents(
          TRACKING_EVENTS.v2.gfa_document_not_found.event,
          TRACKING_ENVIRONMENTS.MIXPANEL,
          {
            Success: false,
            Callback: parseError(documentMkdwn.err),
          },
        );
        showNotification('error', GENERIC_ERROR);
      }
    } catch (err) {
      registerEvents(
        TRACKING_EVENTS.v2.gfa_document_not_found.event,
        TRACKING_ENVIRONMENTS.MIXPANEL,
        {
          Callback: parseError(err),
          Success: false,
        },
      );

      showNotification('error', GENERIC_ERROR);
    } finally {
      setIsPrimaryLoading(false);
    }
  };

  const handleReturnAndCreateStrategy = async () => {
    setIsCreatingAccount(true);
    const { strategyName, matrixPoint } = questionnaire;
    const { profile_session_id, external_id } = questionnaire.current.data;
    const { wtr, investment_term, portfolio_name } =
      questionnaire.recommendation.data;
    const partyId = store.get(GBM_ADVISOR_STATE)?.partyId;
    const legalDocuments = await Api.getContractAgreements(partyId);
    const generalFrameworkDocument = legalDocuments.find(
      (document) => document.type === LEGAL_DOCUMENT_TYPES.wealthManagement,
    );
    const { submit_account_creation } = TRACKING_EVENTS;

    if (!contract) {
      registerEvents(
        TRACKING_EVENTS.v2.contract_not_found.event,
        TRACKING_ENVIRONMENTS.MIXPANEL,
        {
          Success: false,
        },
      );

      showNotification('error', GENERIC_ERROR);
      return;
    }

    if (generalFrameworkDocument) {
      let status;

      try {
        const data = await Api.saveContractAgreements(partyId, {
          type: generalFrameworkDocument.type,
          version: generalFrameworkDocument.version,
        });
        status = data.status;
      } catch (err) {
        /* istanbul ignore next */
        registerEvents(
          TRACKING_EVENTS.legal_documents_error.event,
          TRACKING_ENVIRONMENTS.MIXPANEL,
          {
            Callback: formatErrors(err.response),
          },
        );
        setIsCreatingAccount(false);
      }

      if (status !== HTTP_STATUS_CODES.created) {
        store.clearAll();
        window.location.assign(URLS.dashboard);
        return;
      }
    }

    try {
      await dispatch(
        fetchCreateAccount({
          wtr,
          strategy: strategyName,
          matrixPoint,
          contract,
          portfolio: portfolio_name,
          profile: profile_session_id,
          external: external_id,
          investmentTerm: investment_term,
          partyId,
        }),
      );
    } catch (err) {
      /* istanbul ignore next */
      registerEvents(
        submit_account_creation.event,
        TRACKING_ENVIRONMENTS.MIXPANEL,
        {
          ...submit_account_creation.wealth_error,
          Callback: formatErrors(err),
        },
      );
      setIsCreatingAccount(false);
    }

    store.clearAll();
    window.location.assign(URLS.dashboard);
  };

  const handleReturnToStrategy = () => {
    store.clearAll();
    window.location.replace(
      `${URLS.dashboard}/wealth-management/contract/${contract}/account/${accountId}`,
    );
  };

  const handleConfirmCancel = (cancel) => {
    if (cancel) {
      if (profileAgain) {
        dispatch(clearQuestionnaire());
      } else if (isReprofiling) {
        handleReturnToStrategy();
      } else {
        window.location.assign(`${URLS.dashboard}`);
      }
    } else {
      setCancelScreen(false);
    }
  };

  const handleRestartProfiler = () => {
    registerEvents(
      TRACKING_EVENTS.v2.restart_profiler.event,
      TRACKING_ENVIRONMENTS.MIXPANEL,
      {
        Reprofiling: !!isReprofiling,
      },
    );
    dispatch(clearQuestionnaire());
    history.replace(`${ROUTES.question}/${FIRST_QUESTION_KEY}`);
  };

  const handleConfirmSave = () => {
    const { wtr, investment_term } = questionnaire.recommendation.data;
    const { profile_session_id, external_id } = questionnaire.current.data;
    const reprofileData = {
      external_id,
      profile_session_id,
      account_id: accountId,
      wtr,
      investment_term,
    };
    const portfolios = {
      actualPortfolio:
        lastProfileDetails?.data?.portfolio_type || initialPortfolio,
      newPortfolio: questionnaire?.recommendation?.data?.portfolio_type,
    };

    registerEvents(
      TRACKING_EVENTS.v2.reprofiling_save.event,
      TRACKING_ENVIRONMENTS.MIXPANEL,
      {
        profiling_version: profilingVersion,
      },
    );

    dispatch(
      saveReprofile({
        contractId: contract,
        accountId,
        reprofileData,
        profilingVersion,
        portfolios,
      }),
    );
  };

  const handleProfileAgain = () => {
    registerEvents(
      TRACKING_EVENTS.v2.restart_profiler.event,
      TRACKING_ENVIRONMENTS.MIXPANEL,
      {
        Reprofiling: !!isReprofiling,
      },
    );
    setProfileAgain(true);
    setCancelScreen(true);
  };

  const BuildControls = () => (
    <>
      <Button
        text={acceptance.label}
        onClick={acceptance.onClick}
        className="align-center"
        testId="acceptance-button-test"
        size="big"
        loading={
          questionnaire.createAccount.status === REDUCER_STATUS.pending ||
          isCreatingAccount
        }
      />
      <Button
        id="profileAgain"
        testId="profile-again-test"
        text={t('btnProfileAgain')}
        kind="link"
        onClick={handleRestartProfiler}
        className="align-center link link--bold"
        disabled={questionnaire.createAccount.status === REDUCER_STATUS.pending}
      />
    </>
  );

  const ReprofileControls = () => (
    <>
      <Button
        loading={questionnaire.reprofile.status === REDUCER_STATUS.pending}
        text={t('saveProfile')}
        onClick={handleConfirmSave}
        className="align-center"
        testId="reprofile-button-test"
        size="big"
      />
      <Button
        id="profileAgain"
        testId="profile-again-test"
        text={t('editProfile')}
        kind="link"
        onClick={handleRestartProfiler}
        className="align-center link link--bold"
        disabled={questionnaire.reprofile.status === REDUCER_STATUS.pending}
      />
    </>
  );

  const backStrategy = () => {
    if (isReprofiling && showConfirmSave) {
      return handleReturnToStrategy();
    }
    return undefined;
  };

  const showControls = isReprofiling ? (
    <ReprofileControls />
  ) : (
    <BuildControls />
  );

  const handleClickPortfolio = (portfolio) => {
    const detail = getProfileDetail(
      portfolio.data.portfolio_type,
      portfolio.data,
    );
    setPortfolioSelected(portfolio.data);
    setProfileDetail(detail);
  };

  if (cancelScreen) {
    return (
      <CancelScreen onClose={handleConfirmCancel} backStrategy={backStrategy} />
    );
  }

  if (showConfirmSave)
    return <ConfirmSaveScreen onClick={handleReturnToStrategy} />;

  const strategySummary = isLoading ? (
    <Loader />
  ) : (
    <section className="advisor-section" data-testid="advisor-section-test">
      <div className="complete-profile">
        <header>
          <img src={logo} alt="GBM+" className="gbm-logo" />
          <button
            type="button"
            className="close"
            aria-label="Close"
            data-testid="advisor-close-test"
            onClick={() => setCancelScreen(true)}
          />
        </header>
        <div className="results-title">
          {isReprofiling ? (
            <Title
              centered
              hasIcon={false}
              subtitle={t('editPortfolio')}
              title={isReprofiling || t('myStrategy')}
              testId="reprofile-title"
            />
          ) : (
            <Title
              centered
              hasIcon={false}
              subtitle={t('buildPortfolio')}
              title={questionnaire.strategyName || t('myStrategy')}
              testId="build-title"
            />
          )}
        </div>
        <div className="advisor-details">
          <div className="projected-wrapper">
            <div className="amount--projected">
              <Card>
                <div className="projected-information">
                  <div className="initial-information">
                    <InformationSection
                      description={t('expectedAmountTitle')}
                      value={
                        <span>
                          <FormattedNumber
                            value={
                              questionnaire.currentQuestion.data
                                ?.profile_details?.expected_amount || 0
                            }
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}
                            // eslint-disable-next-line react/style-prop-object
                            style="currency"
                          />
                        </span>
                      }
                      subtitle={
                        isReprofiling &&
                        !isEmpty(lastProfileDetails.data) &&
                        t('prevValueAmount', {
                          prev: formattedCurrency(
                            lastProfileDetails.data.expected_amount,
                          ),
                        })
                      }
                    />
                    <InformationSection
                      description={
                        isShortTerm
                          ? t('netHistoricalPerformance')
                          : t('historicalPerformance')
                      }
                      value={
                        isShortTerm
                          ? `${featureFlags.wealthManagementShortTermRate.getValue()}%`
                          : t('percentageCagr', {
                              cagr: questionnaire.currentQuestion.data
                                ?.profile_details?.compound_annual_growth_rate,
                            })
                      }
                      subtitle={
                        isReprofiling &&
                        !isEmpty(lastProfileDetails.data) &&
                        t('prevPercentage', {
                          prev: lastProfileDetails.data
                            .compound_annual_growth_rate,
                        })
                      }
                    />
                  </div>
                  <div className="initial-information">
                    <InformationSection
                      description={t('initialAmount')}
                      value={
                        <span>
                          <FormattedNumber
                            value={
                              questionnaire.initialAmount.answer_value || 0
                            }
                            maximumFractionDigits={0}
                            minimumFractionDigits={0}
                            // eslint-disable-next-line react/style-prop-object
                            style="currency"
                          />
                          <span className="cents">
                            .
                            {getCents(questionnaire.initialAmount.answer_value)}
                          </span>
                        </span>
                      }
                      subtitle={
                        isReprofiling &&
                        !isEmpty(lastProfileDetails.data) &&
                        t('prevValueAmount', {
                          prev: `${formattedCurrency(
                            lastProfileDetails.data.initial_amount,
                          )}.${getCents(100000)}`,
                        })
                      }
                    />
                    <InformationSection
                      description={getMonthlyAmountLabel(
                        t,
                        questionnaire.monthlyAmount?.answer_value,
                      )}
                      value={
                        <span>
                          <FormattedNumber
                            value={monthlyAmount}
                            maximumFractionDigits={0}
                            minimumFractionDigits={0}
                            // eslint-disable-next-line react/style-prop-object
                            style="currency"
                          />
                          <span className="cents">
                            .{getCents(monthlyAmount)}
                          </span>
                        </span>
                      }
                      subtitle={
                        isReprofiling &&
                        !isEmpty(lastProfileDetails.data) &&
                        t('prevValueAmount', {
                          prev: `${formattedCurrency(
                            lastProfileDetails.data.monthly_amount,
                          )}.${getCents(100000)}`,
                        })
                      }
                    />
                  </div>
                </div>
              </Card>
            </div>
            <div className="portfolio-definition">
              <Card>
                <div className="portfolio-definition__description">
                  <span className="portfolio-definition__title">
                    {t('portfolioDefinition')}
                  </span>
                  <div className="portfolio-definition__info">
                    <InformationSection
                      description={t('investmentTerm')}
                      value={getInvestmentTermLabel(
                        t,
                        questionnaire.currentQuestion.data?.profile_details
                          ?.investment_term_in_years ?? 0,
                        questionnaire.investmentTerm?.max,
                      )}
                      icon={clockIcon}
                      subtitle={
                        !isEmpty(lastProfileDetails.data) &&
                        t('prevValue', {
                          prev: getInvestmentTermLabel(
                            t,
                            lastProfileDetails?.data?.investment_term_in_years,
                          ),
                        })
                      }
                    />
                    <InformationSection
                      description={t('riskLevel')}
                      value={t('riskLevelInfo', {
                        wtr: questionnaire.currentQuestion.data?.profile_details
                          ?.wtr_ten_base,
                      })}
                      icon={strokeIcon}
                      subtitle={
                        !isEmpty(lastProfileDetails.data) &&
                        t('prevValue', {
                          prev: t('riskLevelInfo', {
                            wtr: lastProfileDetails?.data?.wtr_ten_base,
                          }),
                        })
                      }
                    />
                  </div>
                </div>
              </Card>
            </div>
            <PortfolioCagr cagr={profileDetail?.cagr} />
          </div>
          <div className="advisor-results__wrapper">
            <Card>
              <PortfolioComposition
                instruments={portfolioSelected?.instruments}
                type={portfolioSelected?.portfolio_type}
                profileDetail={profileDetail}
                portfolioType={questionnaire.recommendation.data.portfolio_type}
              />
            </Card>
          </div>
        </div>
        <div className="advisor-results__controls">{showControls}</div>
        {featureFlags.wealthManagementPortfolios.isEnabled() && (
          <div className="projected-wrapper">
            <Portfolios
              portfolios={mockPortfolios}
              onClickPortfolio={handleClickPortfolio}
            />
          </div>
        )}
      </div>
    </section>
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75 }}
    >
      {legalDocument ? (
        <Modal loading>
          <button
            type="button"
            className="close"
            aria-label="Close"
            data-testid="hide-legal-doc"
            onClick={() => setLegalDocument()}
          />
          <div className="legal-document" data-testid="agf-modal">
            <div className="legal-document__container">
              <div className="legal-document__title">
                {t('generalFramework')}
              </div>
              <div className="legal-document__content">{legalDocument}</div>
            </div>
            <div className="advisor-results__controls">
              <Checkbox
                key="acceptance"
                stateKey="acceptance"
                checked={isCheckedGFA}
                label={t('acceptanceGeneralFramework')}
                onChange={() => setCheckedGFA(!isCheckedGFA)}
                index={0}
                testId="acceptance-checkbox"
              />
              <Button
                id="createAccount"
                text={t('btnCreateStrategy')}
                onClick={handleReturnAndCreateStrategy}
                className="align-center"
                disabled={!isCheckedGFA}
                testId="accept-agf-button"
              />
            </div>
          </div>
        </Modal>
      ) : (
        strategySummary
      )}
    </motion.div>
  );
};

ProfilerResults.propTypes = {
  history: PropTypes.object,
  featureFlags: PropTypes.object,
};

export default ProfilerResults;
