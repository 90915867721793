export const TRACKING_DEFAULT_VALUES = {
  emptyParam: 'N/A',
};

export const GENERIC_ERROR =
  'Ocurrió un error al procesar la información. Por favor inténtalo más tarde';

/**
 * Create an object error for tracking purposes
 * @param err
 * @param headers
 * @return {object}
 */
export const makeTrackingErrorProps = (err, headers) => ({
  Success: false,
  code: err?.code || TRACKING_DEFAULT_VALUES.emptyParam,
  event_id: err?.event_id || TRACKING_DEFAULT_VALUES.emptyParam,
  message: formatErrors(err),
  request_id:
    headers?.['x-amzn-requestid'] || TRACKING_DEFAULT_VALUES.emptyParam,
});

/**
 * Extracts the error message or fill with fallback,
 *
 * @param {object} error response
 * @returns {string} error message
 */
export const formatErrors = (error) => {
  let message = error?.message;

  if (!message && error?.details?.length) {
    message = error.details[0].message;
  }

  if (!message && error?.data) {
    const { data } = error;
    message = data.error?.message;

    if (!message && data.error?.details?.length) {
      message = data.error.details[0].message;
    }
  }

  return message || GENERIC_ERROR;
};
