/**
 *
 * OutsideAlerter
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import KEYBOARD_EVENTS from 'catalogs/keyboard';

/**
 * Component that alerts if you click outside of it
 */
export default class OutsideAlerter extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.onEscapeKeyPressed, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keydown', this.onEscapeKeyPressed, false);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  /**
   * Alerts if clicked on outside of element
   */
  handleClickOutside = (event) => {
    if (!this.wrapperRef?.contains(event.target)) {
      this.props.onClickOutside();
    }
  };

  /**
   * Detects if esc keys has been pressed
   */
  onEscapeKeyPressed = (event) => {
    if (event.key === KEYBOARD_EVENTS.escape.key) {
      this.props.onClickOutside();
    }
  };

  render() {
    return (
      <div
        className={this.props.className}
        ref={this.setWrapperRef}
        data-testid="outsider-test"
      >
        {this.props.children}
      </div>
    );
  }
}

OutsideAlerter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  onClickOutside: PropTypes.func,
  className: PropTypes.string,
};
