// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { foreground } from './Foreground';

class RadialChart extends React.Component {
  // Render chart when component is mounted
  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.renderChart(true);
  }

  // Call all the functions that render SVG
  renderChart(remove = false) {
    const {
      node,
      props: { size, debt, equity },
    } = this;
    foreground(node, size, debt, equity, 15, remove);
  }

  // Main render, assign this.node to make it available for the chart renders
  render() {
    const { size } = this.props;
    return (
      <div
        className="composition-chart"
        ref={(node) => {
          this.node = node;
        }}
        width={size}
        height={size}
      />
    );
  }
}

RadialChart.defaultProps = {
  size: 228,
  debt: 0,
  equity: 0,
};

RadialChart.propTypes = {
  size: PropTypes.number,
  debt: PropTypes.number,
  equity: PropTypes.number,
};

export default RadialChart;
