import React, { createContext, useState } from 'react';
import { element } from 'prop-types';
import { useTranslation } from 'react-i18next';

export const SimulatorContext = createContext();

export const SimulatorProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [simulatorData, setSimulatorData] = useState({
    targetsList: {
      data: [],
      isFilled: false,
    },
    targetInfo: {
      data: {
        target: null,
        first_deposit: '',
        monthly_deposit: '',
        years: '',
      },
      isFilled: false,
    },
    personalInfo: {
      data: {},
      isFilled: false,
    },
    results: {
      data: {},
      isFilled: false,
    },
    lng: i18n.language,
  });

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SimulatorContext.Provider value={{ simulatorData, setSimulatorData }}>
      {children}
    </SimulatorContext.Provider>
  );
};

SimulatorProvider.propTypes = {
  children: element,
};
