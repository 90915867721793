import { useEffect } from 'react';
import PropTypes from 'prop-types';

const ScrollToTop = ({ location, children }) => {
  useEffect(() => {
    scrollToTop();
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return children;
};

ScrollToTop.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.object,
};

export default ScrollToTop;
