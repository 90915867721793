export const PRODUCT = {
  wealthManagement: 'Wealth Management',
};

export const DEFAULT_SUCCESS = {
  Success: true,
  Callback: 'Success',
};

export const DEFAULT_ERROR = {
  Success: false,
};

export const TRACKING_EVENTS = {
  v2: {
    target_characteristics_type: {
      event: 'target_characteristics_type',
    },
    strategy_name: {
      event: 'fill_account_name',
    },
    target_characteristics_final_amount: {
      event: 'target_characteristics_final_amount',
    },
    target_characteristics_expense_amount: {
      event: 'target_characteristics_expense_amount',
    },
    target_characteristics_time_amount: {
      event: 'target_characteristics_time_amount',
    },
    target_characteristics_retirement_amount: {
      event: 'target_characteristics_retirement_amount',
    },
    target_characteristics_time_amount_education: {
      event: 'target_characteristics_time_amount_edu',
    },
    target_characteristics_retirement_age: {
      event: 'target_characteristics_retirement_age',
    },
    target_characteristics_initial_amount: {
      event: 'target_characteristics_initial_amount',
    },
    target_characteristics_monthly_amount_contribute: {
      event: 'target_characteristics_month_contribute',
    },
    target_characteristics_monthly_amount_withdraw: {
      event: 'target_characteristics_month_withdraw',
    },
    target_characteristics_monthly_amount_withdrawal: {
      event: 'target_characteristics_month_withdraw',
    },
    risk_capacity_age: {
      event: 'risk_capacity_age',
    },
    risk_capacity_income: {
      event: 'risk_capacity_income',
    },
    risk_capacity_wealth: {
      event: 'risk_capacity_wealth',
    },
    risk_capacity_economic_dependants: {
      event: 'risk_capacity_economic_dependants',
    },
    risk_capacity_income_stability: {
      event: 'risk_capacity_income_stability',
    },
    risk_capacity_income_expectation: {
      event: 'risk_capacity_income_expectation',
    },
    experience_investment: {
      event: 'experience_investment',
    },
    experience_investment_instruments: {
      event: 'experience_investment_instruments',
    },
    experience_investment_instruments_funds: {
      event: 'experience_investment_funds',
    },
    experience_investment_instruments_etfs: {
      event: 'experience_investment_etfs',
    },
    experience_investment_instruments_stock: {
      event: 'experience_investment_stock',
    },
    experience_investment_instruments_bonus: {
      event: 'experience_investment_bonus',
    },
    experience_investment_instruments_cetes: {
      event: 'experience_investment_cetes',
    },
    risk_tolerance_think: {
      event: 'risk_tolerance_think',
    },
    risk_tolerance_focus: {
      event: 'risk_tolerance_focus',
    },
    risk_tolerance_adaptation: {
      event: 'risk_tolerance_adaptation',
    },
    risk_tolerance_priority: {
      event: 'risk_tolerance_priority',
    },
    risk_tolerance_attitude: {
      event: 'risk_tolerance_attitude',
    },
    risk_tolerance_low_yield: {
      event: 'risk_tolerance_low_yield',
    },
    risk_tolerance_market_falls: {
      event: 'risk_tolerance_market_falls',
    },
    risk_tolerance_time_investment_recover: {
      event: 'risk_tolerance_time_investment_recover',
    },
    risk_tolerance_loss_gain_range: {
      event: 'risk_tolerance_loss_gain_range',
    },
    risk_tolerance_extended_investment_lost_20: {
      event: 'risk_tolerance_extended_invest_lost_20',
    },
    risk_tolerance_extended_debt_investment_lost_4: {
      event: 'risk_tolerance_extend_debt_invest_lost_4',
    },
    gfa_document_not_found: {
      event: 'gfa_document_not_found',
    },
    contract_not_found: {
      event: 'contract_not_found',
    },
    restart_profiler: {
      event: 'restart_profiler',
    },
    create_recommendation: {
      event: 'create_recommendation',
    },
    reprofiling_previous_answers: {
      event: 'reprofiling_previous_answers',
    },
    reprofiling_questionnaire: {
      event: 'reprofiling_questionnaire',
    },
    reprofiling_results_view: {
      event: 'reprofiling_results_view',
    },
    reprofiling_save: {
      event: 'reprofiling_save',
    },
    reprofiling_request: {
      event: 'reprofiling_request',
    },
    reprofiling_exit: {
      event: 'reprofiling_exit',
    },
    reprofiling_cancel: {
      event: 'reprofiling_cancel',
    },
    reprofiling_confirmation_view: {
      event: 'reprofiling_confirmation_view',
    },
    reprofiling_confirmation: {
      event: 'reprofiling_confirmation',
    },
  },
  v1: {
    experienceInvesting: {
      event: 'answer_experience_investing',
    },
    whyAreYouInvesting: {
      event: 'answer_why_are_you_investing',
    },
    initialAmount: {
      event: 'answer_initial_amount',
    },
    monthlyDeposit: {
      event: 'answer_monthly_deposit',
    },
    monthlyWithdrawal: {
      event: 'answer_monthly_withdrawal',
    },
    investmentTermYears: {
      event: 'answer_investment_years',
    },
    monthlyIncome: {
      event: 'answer_monthly_income',
    },
    totalAssets: {
      event: 'answer_total_assets',
    },
    winOrLose: {
      event: 'answer_win_or_lose',
    },
    portfolioLoss: {
      event: 'answer_portfolio_loss',
    },
    fallPercentage: {
      event: 'answer_fall_percentage',
    },
    investmentProfile: {
      event: 'recommended_investment_profile',
      success: {
        Callback: 'Success',
        Success: 'true',
      },
      error: {
        Success: false,
      },
    },
  },
  legal_documents_error: {
    event: 'legal_documents_error',
  },
  submit_account_creation: {
    event: 'submit_account_creation',
    wealth_success: {
      ...DEFAULT_SUCCESS,
      Type: PRODUCT.wealthManagement,
    },
    wealth_error: {
      ...DEFAULT_ERROR,
      Type: PRODUCT.wealthManagement,
    },
  },
};

export const ENVIRONMENTS = {
  LOCAL: 'LOCAL',
  DEVELOPMENT: 'dev',
  STAGE: 'stg',
  PRODUCTION: 'prd',
};

export const TRACKING_ENVIRONMENTS = {
  MIXPANEL: 'mixpanel',
  ALL: 'all',
};

export const PROFILING_VERSION = {
  v1: 'v1',
  v2: 'v2',
};

export const ACCOUNT_TYPES = {
  wealth: {
    label: 'wealth',
    type: 'Advised',
    management_type_template: 'wealth',
  },
};

export const RANGE_MATCHERS = {
  time: 'tiempo',
};

export const SHORT_TERM_PORTFOLIO = {
  wtr: 1,
  type: 'settlement',
};
