// Libraries
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import Rox from 'rox-browser';
import mixpanel from 'mixpanel-browser';
import qs from 'qs';

import i18n from 'i18n';

import store from 'store';
import config from 'config';

import { generateFeatureFlags } from 'utils/featureFlags';
import Loader from 'components/Loader';
import NotificationProvider from 'features/NotificationContext';
import Notification from 'components/Notification';
import Advisor from 'views/app';

import questionnaireStore from 'questionnaireStore';
import { unregister } from 'registerServiceWorker';

// Other
import './styles/index.css';
import Auth from './api/auth/auth';

const { GBM_ADVISOR_STATE } = config();
const queryParams = qs.parse(window.location.search, {
  ignoreQueryPrefix: true,
});
let userId;
let userEmail = store.get(GBM_ADVISOR_STATE)?.userEmail;

if (queryParams?.accessToken) {
  const auth = new Auth();
  userEmail =
    queryParams.userEmail?.replace(/\s/g, '+') ||
    store.get(GBM_ADVISOR_STATE)?.userEmail ||
    auth.getEmail(queryParams.accessToken);
  userId = auth.getUserId(queryParams.accessToken);
}

const env = config();
const ROLLOUT_FETCHER_STATUS = {
  FROM_NETWORK: 'APPLIED_FROM_NETWORK',
  FROM_CACHE: 'APPLIED_FROM_CACHE',
};
const {
  DEFAULT_FEATURE_FLAGS,
  IS_MIXPANEL_ENABLED,
  MIXPANEL_API_KEY,
  ROLL_OUT_API_KEY,
} = env;
const featureFlags = generateFeatureFlags(DEFAULT_FEATURE_FLAGS);

if (userEmail) {
  Rox.setCustomStringProperty('userEmail', userEmail);
}

Rox.register('', featureFlags);
Rox.setup(ROLL_OUT_API_KEY, {
  configurationFetchedHandler: /* istanbul ignore next */ ({
    fetcherStatus,
  }) => {
    fetcherStatus === ROLLOUT_FETCHER_STATUS.FROM_NETWORK &&
      ReactDOM.render(
        <Provider store={questionnaireStore}>
          <I18nextProvider i18n={i18n}>
            <Suspense fallback={<Loader withQuotes />}>
              <NotificationProvider>
                <Notification />
                <Advisor featureFlags={featureFlags} />
              </NotificationProvider>
            </Suspense>
          </I18nextProvider>
        </Provider>,
        document.getElementById('root'),
      );
  },
  freeze: 'none',
});

if (IS_MIXPANEL_ENABLED) {
  mixpanel.init(MIXPANEL_API_KEY);
  mixpanel.set_config({ debug: process.env.NODE_ENV !== 'production' });
  userId && mixpanel.identify(userId);
}

unregister();

if (process.env.NODE_ENV === 'production') {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistration().then((registrations) => {
      if (Array.isArray(registrations)) {
        registrations.forEach(
          (registration) => registration && registration.unregister(),
        );
        window.location.reload();
      } else if (registrations) {
        registrations.unregister();
        window.location.reload();
      }
    });
  }
}
