import config from 'config';

import { request } from 'api/utils/request';
import { LANGUAGES } from 'containers/utils/constants';
import { HTTP_VERBS, SIMULATOR_ID, SIMULATOR_ID_USD } from '../constants';

const env = config();
const { SIMULATOR_API } = env;

const SimulatorApi = {
  /**
   * GET v1/simulators/{simulatorId}/targets
   *
   * @param {string} SIMULATOR_ID
   * @returns a promise
   */
  getTargets(language) {
    const simulatorId =
      language === LANGUAGES.es ? SIMULATOR_ID : SIMULATOR_ID_USD;
    const url = `${SIMULATOR_API.v1}/simulators/${simulatorId}/targets`;

    return request(url);
  },

  /**
   * POST v1/simulators/:simulatorId/targets/:targetId
   *
   * @param {string} SIMULATOR_ID
   * @param {string} targetId
   * @param {object} simulator
   * @returns a promise
   */
  saveSimulatorAnswers(targetId, simulator, language) {
    const simulatorId =
      language === LANGUAGES.es ? SIMULATOR_ID : SIMULATOR_ID_USD;
    const url = `${SIMULATOR_API.v1}/simulators/${simulatorId}/targets/${targetId}`;
    const options = {
      method: HTTP_VERBS.post,
      data: simulator,
    };

    return request(url, options);
  },

  /**
   * POST v1/customer-simulation/:scoreId/send
   *
   * @param {string} scoreId
   */
  sendSimulatorResult(scoreId) {
    const url = `${SIMULATOR_API.v1}/customer-simulation/${scoreId}/send`;
    const options = { method: HTTP_VERBS.post };

    return request(url, options);
  },
};

export default SimulatorApi;
