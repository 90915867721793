import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import InputMask from 'react-input-mask';
import { NumericFormat } from 'react-number-format';

import KEYBOARD_EVENTS from 'catalogs/keyboard';

const Input = ({
  valueChanged,
  name,
  label,
  mask,
  maskChar,
  maxLength,
  placeholder,
  questionType,
  title,
  handlePrimary,
  type,
  hint,
  isLabelHidden,
  hasHint,
  valid,
  defaultValue,
  invalid,
  disabled,
  pattern,
  autoFocus,
  testId,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleValueChange = (key, e) => {
    let newValue =
      typeof e === 'undefined' ? key.currentTarget.value : key.value;
    newValue =
      questionType !== 'number' ? newValue.replace(/^0+/, '') : newValue;

    setValue(newValue);

    const regex = pattern ? new RegExp(pattern) : null;
    valueChanged(newValue, regex ? regex.test(newValue) : true);
  };

  const renderLabel = () => (
    <label className="input__label" htmlFor={name}>
      {label}
    </label>
  );

  const renderHint = () => (
    <span
      className={clsx('input__message', {
        'advisor-input--message-hint': valid,
        'advisor-input--message-error': invalid,
      })}
    >
      {hint}
    </span>
  );

  const renderInput = () => {
    let inputComponent;
    const className = clsx('input__input input__input--big', {
      'input__input--center': questionType !== 'name',
      'advisor-input--input-valid': valid,
      'advisor-input--input-invalid': invalid,
    });
    const props = {
      disabled,
      value,
      placeholder,
      className,
      maxLength: mask || maxLength === 0 ? '' : maxLength,
      onKeyDown: (key) => {
        if (key.key === KEYBOARD_EVENTS.enter.key) {
          handlePrimary();
        }
      },
    };

    switch (questionType?.toLowerCase()) {
      case 'number':
      case 'amount':
      case 'int':
        inputComponent = (
          <NumericFormat
            autoFocus={autoFocus}
            valueIsNumericString
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            allowLeadingZeros={questionType === 'number'}
            decimalScale={0}
            allowNegative={false}
            onValueChange={handleValueChange}
            aria-label={title}
            {...props}
          />
        );
        break;
      case 'age':
        inputComponent = (
          <InputMask
            autoFocus={autoFocus}
            {...props}
            mask={mask}
            maskChar={maskChar}
            type={type}
            onChange={handleValueChange}
          />
        );
        break;
      default:
        inputComponent = (
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            className={className}
            type="text"
            value={value}
            data-testid={testId}
            onChange={handleValueChange}
            placeholder={placeholder}
            name="strategy"
            {...props}
          />
        );
        break;
    }

    return inputComponent;
  };

  return (
    <div
      className={clsx('input input--big input--question', {
        'input--currency': questionType === 'amount',
        'input--error': invalid,
      })}
    >
      {!isLabelHidden && renderLabel()}
      {renderInput()}
      {hasHint && renderHint()}
    </div>
  );
};

Input.defaultProps = {
  defaultValue: '',
  pattern: '',
  disabled: false,
  isLabelHidden: false,
  handlePrimary: () => null,
  help: '',
  hint: '',
  hasHint: false,
  invalid: false,
  label: '',
  mask: '',
  maskChar: '',
  maxLength: 40,
  name: '',
  placeholder: '',
  testId: '',
  type: 'text',
  valid: false,
  autoFocus: false,
  valueChanged: () => null,
};

Input.propTypes = {
  defaultValue: PropTypes.string,
  pattern: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(RegExp),
  ]),
  disabled: PropTypes.bool,
  handlePrimary: PropTypes.func,
  hasHint: PropTypes.bool,
  help: PropTypes.string,
  hint: PropTypes.string,
  invalid: PropTypes.bool,
  isLabelHidden: PropTypes.bool,
  label: PropTypes.string,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  questionType: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  testId: PropTypes.string,
  valid: PropTypes.bool,
  autoFocus: PropTypes.bool,
  valueChanged: PropTypes.func,
};

export default Input;
