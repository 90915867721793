import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { loadAnimation } from 'lottie-web';
import { motion } from 'framer-motion';

import registerEvents from 'utils/registerEvents';
import CheckIcon from 'images/animations/confirmCheck.json';

import { TRACKING_EVENTS, TRACKING_ENVIRONMENTS } from 'utils/constants';
import Button from '../Button';

const ConfirmSaveScreen = ({ onClick }) => {
  const { t } = useTranslation('confirm');

  useEffect(() => {
    registerEvents(
      TRACKING_EVENTS.v2.reprofiling_confirmation_view.event,
      TRACKING_ENVIRONMENTS.MIXPANEL,
    );
  }, []);

  useLayoutEffect(() => {
    loadAnimation({
      container: document.getElementById('animation-confirm-save'),
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: CheckIcon,
    });
  }, []);

  const handleClickConfirm = () => {
    registerEvents(
      TRACKING_EVENTS.v2.reprofiling_confirmation.event,
      TRACKING_ENVIRONMENTS.MIXPANEL,
    );
    onClick();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75 }}
    >
      <div className="confirm-save" data-testid="confirm-screen-test">
        <div className="confirm-save__image">
          <div id="animation-confirm-save" />
        </div>
        <span className="confirm-save__title typography-3 typography--bold font-mabry">
          {t('title')}
        </span>
        <span className="confirm-save__description typography-6">
          {t('description')}
        </span>
        <div className="confirm-save__controls">
          <Button
            testId="btnConfirm"
            onClick={handleClickConfirm}
            size="big"
            text={t('accept')}
          />
        </div>
      </div>
    </motion.div>
  );
};

ConfirmSaveScreen.propTypes = {
  onClick: PropTypes.func,
};

export default ConfirmSaveScreen;
