import { select, interpolate, arc } from 'd3';

class ChartUtils {
  // Calculate radius from diameter
  radius = (diameter) => diameter / 2;

  // Return the translate string for centering this element
  center = (diameter, chartPadding) =>
    `translate(${this.radius(diameter) + chartPadding}, ${
      this.radius(diameter) + chartPadding
    })`;

  // Set base spec for all arcs
  renderArc = arc()
    .innerRadius((d) => d.innerRadius)
    .outerRadius((d) => d.outerRadius);

  changeArc = (index, nodeList, datum, newOuterRadius, newInnerRadius) => {
    /* istanbul ignore next */
    select(nodeList[index])
      .transition()
      .attrTween('d', () => (t) => {
        const interpol = interpolate(
          {
            innerRadius: datum.innerRadius,
            outerRadius: datum.outerRadius,
          },
          { innerRadius: newInnerRadius, outerRadius: newOuterRadius },
        );
        const d = datum;
        d.outerRadius = interpol(t).outerRadius;
        d.innerRadius = interpol(t).innerRadius;
        /* istanbul ignore next */
        return this.renderArc(d);
      });
  };

  animateChart = (
    index,
    nodeList,
    datum,
    event,
    newInnerRadius,
    newOuterRadius,
  ) => {
    switch (event) {
      case 'mouseover':
      case 'mouseout':
        this.changeArc(index, nodeList, datum, newOuterRadius, newInnerRadius);
        break;
      default:
        break;
    }
  };
}

export default new ChartUtils();
