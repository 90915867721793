/* istanbul ignore file */
import React, { useState, useRef, useEffect } from 'react';
import { object } from 'prop-types';
import { Line } from 'react-chartjs-2';
import { formattedCurrency } from 'views/profilerResults/utils';
import clsx from 'clsx';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from 'chart.js';
import { CHART_FIRST_RENDER } from 'containers/Simulator/constants';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
);
ChartJS.defaults.font.family = 'Work Sans';

const PortfolioChart = ({ resultsData, targetInfoData }) => {
  const dataValues = [
    Number(targetInfoData?.first_deposit),
    ...Object.values(resultsData.points_to_graph),
  ];
  const dataValuesLastIndex = dataValues?.length ? dataValues.length - 1 : 0;

  const [activePointIndex, setActivePointIndex] = useState(CHART_FIRST_RENDER);
  const [cursorType, setCursorType] = useState('default');
  const chartRef = useRef(null);
  const currentChart = chartRef?.current;

  const formattedLabels = [
    'HOY',
    ...Object.keys(resultsData.points_to_graph).map(
      (point) => `${point} ${point > 1 ? 'AÑOS' : 'AÑO'}`,
    ),
  ];

  const drawLastIndexLine = () => {
    const tooltipChart = currentChart?.tooltip;

    setActivePointIndex(dataValuesLastIndex);
    tooltipChart?.setActiveElements([
      {
        datasetIndex: 0,
        index: dataValuesLastIndex,
      },
    ]);
  };

  useEffect(() => {
    if (
      activePointIndex === dataValuesLastIndex ||
      activePointIndex === CHART_FIRST_RENDER
    ) {
      drawLastIndexLine();
    }
  }, [currentChart, activePointIndex]);

  const data = {
    labels: formattedLabels,
    datasets: [
      {
        label: 'Dataset 1',
        data: dataValues,
        borderColor: 'rgb(255, 255, 255)',
        pointStyle: 'circle',
        pointRadius: 2,
        pointBackgroundColor: 'white',
        pointHoverRadius: 0,
      },
    ],
  };
  const config = {
    responsive: true,
    tension: 0.4,
    maintainAspectRatio: false,
    elements: {
      point: {
        hitRadius: formattedLabels?.length > 50 ? 2 : 5,
      },
    },
    layout: {
      padding: {
        top: 15,
        right: 28,
      },
    },
    interaction: {
      mode: 'x',
      intersect: false,
    },
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          borderWidth: 0,
        },
        position: 'right',
        max: dataValues[dataValuesLastIndex],
        min: dataValues[0],
      },
      x: {
        ticks: {
          color: 'white',
          padding: 10,
        },
        grid: {
          display: false,
          borderColor: 'white',
        },
      },
    },
    plugins: {
      tooltip: {
        yAlign: 'bottom',
        xAlign: 'right',
        padding: {
          right: 30,
        },
        backgroundColor: 'transparent',
        titleFont: { weight: 500, size: 14 },
        titleAlign: 'right',
        bodyFont: { weight: 500, size: 14 },
        bodyAlign: 'right',
        titleMarginBottom: 8,
        displayColors: false,
        callbacks: {
          title: () => 'Monto',
          label: (e) =>
            `$${formattedCurrency(e.raw)} ${targetInfoData.target.currency}`,
        },
      },
    },
    onHover: (event) => {
      if (event.chart.tooltip._active.length) {
        setCursorType('pointer');
      } else {
        setCursorType('default');
      }
    },
  };
  const plugins = [
    {
      id: 'tooltipLine',
      beforeDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const { ctx } = chart;
          ctx.save();
          const activePoint = chart.tooltip._active[0];
          setActivePointIndex(activePoint.index);
          ctx.beginPath();
          ctx.setLineDash([3, 4]);
          ctx.moveTo(activePoint.element.x, chart.chartArea.top);
          ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
          ctx.lineWidth = 2;
          ctx.strokeStyle = 'white';
          ctx.stroke();
          ctx.restore();
        } else {
          setActivePointIndex(null);
        }
      },
    },
    {
      id: 'drawHoverBorderTooltip',
      beforeDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const { ctx } = chart;
          const activePoint = chart.tooltip._active[0];
          const angle = Math.PI / 180;
          ctx.save();
          ctx.beginPath();
          ctx.fillStyle = 'rgba(170, 170, 170, 0.3)';
          ctx.arc(
            activePoint.element.x,
            activePoint.element.y,
            12,
            angle * 0,
            angle * 360,
            false,
          );
          ctx.fill();
          ctx.closePath();
        }
      },
    },
    {
      id: 'drawHoverTooltip',
      beforeDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const { ctx } = chart;
          const activePoint = chart.tooltip._active[0];
          const angle = Math.PI / 180;
          ctx.save();
          ctx.beginPath();
          ctx.fillStyle = 'rgb(255, 255, 255)';
          ctx.arc(
            activePoint.element.x,
            activePoint.element.y,
            5,
            angle * 0,
            angle * 360,
            false,
          );
          ctx.fill();
          ctx.closePath();
        }
      },
    },
    {
      id: 'drawLastIndexTooltip',
      beforeDraw: (chart) => {
        const {
          ctx,
          scales: { x },
          chartArea: { top },
        } = chart;
        const angle = Math.PI / 180;
        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = 'rgb(255, 255, 255)';
        ctx.arc(
          x.getPixelForValue(dataValuesLastIndex),
          top,
          5,
          angle * 0,
          angle * 360,
          false,
        );
        ctx.fill();
        ctx.closePath();
      },
    },
  ];

  return (
    <Line
      options={config}
      data={data}
      plugins={plugins}
      ref={chartRef}
      onMouseLeave={() =>
        setTimeout(() => {
          drawLastIndexLine();
        }, 100)
      }
      className={clsx({
        'portfolio-chart-cursor--default': cursorType === 'default',
        'portfolio-chart-cursor--pointer': cursorType === 'pointer',
      })}
    />
  );
};

PortfolioChart.propTypes = {
  resultsData: object,
  targetInfoData: object,
};

export default PortfolioChart;
