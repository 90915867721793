import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { clearQuestionnaire } from 'features/questions/questionsSlice';
import { FIRST_QUESTION_KEY } from 'views/questionnaire/data';
import logo from 'images/atm-logo-positive-gbm.svg';
import ROUTES from 'routes';

import CancelScreen from '../CancelScreen';
import Button from '../Button';

const DoneScreen = () => {
  const history = useHistory();
  const { t } = useTranslation('done');
  const dispatch = useDispatch();
  const questionnaire = useSelector((state) => state.questionnaire);
  const [showCancelScreen, setShowCancelScreen] = useState(false);

  const handleConfirmCancel = (cancel) => {
    if (cancel) {
      dispatch(clearQuestionnaire());
      history.replace(`${ROUTES.question}/${FIRST_QUESTION_KEY}`);
    } else {
      setShowCancelScreen(false);
    }
  };

  if (showCancelScreen) {
    return <CancelScreen onClose={handleConfirmCancel} />;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75 }}
    >
      <div className="done-screen">
        <div className="done-screen__close">
          <img src={logo} alt="GBM+" width="100" height="21" />
          <button
            data-testid="btnClose"
            type="button"
            aria-label="Close"
            onClick={() => setShowCancelScreen(true)}
            className="done-screen__close-button"
          />
        </div>
        <div className="done-screen__container">
          <div className="done-screen__container-icon">
            <div className="mark mark__wealth">
              <div className="half-circle">
                <div className="half">
                  <div className="fill" />
                </div>
              </div>
              <div className="half-circle">
                <div className="half">
                  <div className="fill" />
                </div>
              </div>
            </div>
          </div>
          <div className="done-screen__container-mark">{t('wealth')}</div>
          <h1 className="typography-2 typography--regular font-mabry">
            {t('well-done')}
          </h1>
          <div className="done-screen__container-message">
            {t('description')}
          </div>
          <div className="done-screen__container-controls">
            <Button
              testId="btnResults"
              kind="dark"
              size="small"
              loading={isEmpty(questionnaire.recommendation?.data)}
              onClick={() => history.push('/results')}
              text={t('continue')}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default DoneScreen;
