/*
  CONFIG
*/

const environments = {
  LOCAL: {
    api: {
      v1: 'https://origin.api.stg-gbm.com/v1',
      v2: 'https://origin.api.stg-gbm.com/v2',
    },
    redirectApi: {
      v1: 'https://api-stg.appgbm.com/gbmcom/wealth-management/v1',
      v2: 'https://api-stg.appgbm.com/gbmcom/wealth-management/v2',
    },
    consigliereApiUrl:
      'https://nlq28upk77.execute-api.us-east-1.amazonaws.com/stg',
    simulatorApi: {
      v1: 'https://b8sw2t6lcb.execute-api.us-east-1.amazonaws.com/stg/v1',
    },
    defaultFeatureFlags: {
      configurations: {},
      featureFlags: {
        isEnabledRebrandingLogo: true,
        maintenanceMode: false,
        wealthManagementPortfolios: false,
        tmpWealthManagementReprofiling: false,
        simulator: true,
        simulatorMaintenanceMode: false,
        wealthManagementShortTermRate: true,
      },
    },
    urls: {
      dashboard: 'http://localhost:3000',
      documents: 'https://origin.app.stg-gbm.com/docs',
      staticDocuments: `${process.env.PUBLIC_URL}/docs`,
    },
    debugLang: true,
    isMixpanelEnabled: false,
    mixpanelApiKey: '8ff89039b721db846de3ae187166d05a',
    rollOutApiKey: '5d4449ecbcc466413a109c99',
    gbmBlogUrl: 'https://blog.gbm.com',
    facebookAppId: '2341873139408432',
  },
  dev: {
    api: {
      v1: 'https://origin.api.stg-gbm.com/v1',
      v2: 'https://origin.api.stg-gbm.com/v2',
    },
    redirectApi: {
      v1: 'https://api-stg.appgbm.com/gbmcom/wealth-management/v1',
      v2: 'https://api-stg.appgbm.com/gbmcom/wealth-management/v2',
    },
    consigliereApiUrl:
      'https://nlq28upk77.execute-api.us-east-1.amazonaws.com/stg',
    simulatorApi: {
      v1: 'https://b8sw2t6lcb.execute-api.us-east-1.amazonaws.com/stg/v1',
    },
    defaultFeatureFlags: {
      configurations: {},
      featureFlags: {
        isEnabledRebrandingLogo: false,
        maintenanceMode: false,
        wealthManagementPortfolios: false,
        tmpWealthManagementReprofiling: false,
        simulator: true,
        simulatorMaintenanceMode: false,
        wealthManagementShortTermRate: true,
      },
    },
    urls: {
      dashboard: 'http://localhost:3000',
      documents: 'https://origin.app.stg-gbm.com/docs',
      staticDocuments: `${process.env.PUBLIC_URL}/docs`,
    },
    debugLang: true,
    isMixpanelEnabled: false,
    mixpanelApiKey: '8ff89039b721db846de3ae187166d05a',
    rollOutApiKey: '5d4449ecbcc466413a109c99',
    gbmBlogUrl: 'https://blog.gbm.com',
    facebookAppId: '2341873139408432',
  },
  stg: {
    api: {
      v1: 'https://origin.api.stg-gbm.com/v1',
      v2: 'https://origin.api.stg-gbm.com/v2',
    },
    redirectApi: {
      v1: 'https://api-stg.appgbm.com/gbmcom/wealth-management/v1',
      v2: 'https://api-stg.appgbm.com/gbmcom/wealth-management/v2',
    },
    consigliereApiUrl: 'https://cdn.advisor.stg-gbmapi.com',
    simulatorApi: {
      v1: 'https://b8sw2t6lcb.execute-api.us-east-1.amazonaws.com/stg/v1',
    },
    defaultFeatureFlags: {
      configurations: {},
      featureFlags: {
        isEnabledRebrandingLogo: false,
        maintenanceMode: false,
        wealthManagementPortfolios: false,
        tmpWealthManagementReprofiling: false,
        simulator: true,
        simulatorMaintenanceMode: false,
        wealthManagementShortTermRate: true,
      },
    },
    urls: {
      dashboard: 'https://origin.app.stg-gbm.com',
      documents: 'https://origin.app.stg-gbm.com/docs',
      staticDocuments: `${process.env.PUBLIC_URL}/docs`,
    },
    debugLang: true,
    isMixpanelEnabled: true,
    mixpanelApiKey: '8ff89039b721db846de3ae187166d05a',
    rollOutApiKey: '5d4449bebcc466413a109c8a',
    gbmBlogUrl: 'https://blog.gbm.com',
    facebookAppId: '2341873139408432',
  },
  prd: {
    api: {
      v1: 'https://api.gbm.com/v1',
      v2: 'https://api.gbm.com/v2',
    },
    redirectApi: {
      v1: 'https://api.appgbm.com/gbmcom/wealth-management/v1',
      v2: 'https://api.appgbm.com/gbmcom/wealth-management/v2',
    },
    consigliereApiUrl: 'https://advisor.gbmapi.com',
    simulatorApi: {
      v1: 'https://rw8ioyonei.execute-api.us-east-1.amazonaws.com/prd/v1',
    },
    defaultFeatureFlags: {
      configurations: {},
      featureFlags: {
        isEnabledRebrandingLogo: false,
        maintenanceMode: false,
        wealthManagementPortfolios: false,
        tmpWealthManagementReprofiling: false,
        simulator: false,
        simulatorMaintenanceMode: false,
        wealthManagementShortTermRate: false,
      },
    },
    urls: {
      dashboard: 'https://app.gbm.com',
      documents: 'https://app.gbm.com/docs',
      staticDocuments: `${process.env.PUBLIC_URL}/docs`,
    },
    debugLang: false,
    isMixpanelEnabled: true,
    mixpanelApiKey: '3457f824a8dadfb4e99311b8ec7d87ce',
    rollOutApiKey: '5d4447382b1633417b34775c',
    gbmBlogUrl: 'https://blog.gbm.com',
    facebookAppId: '2341873139408432',
  },
};

/* istanbul ignore next */
const config = (appEnv = process.env.REACT_APP_ENV) => {
  const env = {
    NODE_ENV: process.env.NODE_ENV || 'dev',
    APP_ENV: getEnv(appEnv),
  };
  env.GBM_ADVISOR_STATE = 'GBM_ADVISOR_STATE';
  env.API = environments[env.APP_ENV].api;
  env.SIMULATOR_API = environments[env.APP_ENV].simulatorApi;
  env.REDIRECT_API = environments[env.APP_ENV].redirectApi;
  env.CONSIGLIERE_API_URL = environments[env.APP_ENV].consigliereApiUrl;
  env.DEFAULT_FEATURE_FLAGS = environments[env.APP_ENV].defaultFeatureFlags;
  env.IS_MIXPANEL_ENABLED = environments[env.APP_ENV].isMixpanelEnabled;
  env.MIXPANEL_API_KEY = environments[env.APP_ENV].mixpanelApiKey;
  env.REACT_APP_DEBUG_LANG = environments[env.APP_ENV].debugLang;
  env.ROLL_OUT_API_KEY = environments[env.APP_ENV].rollOutApiKey;
  env.URLS = environments[env.APP_ENV].urls;
  env.REACT_APP_GBM_BLOG_URL = environments[env.APP_ENV].gbmBlogUrl;
  env.FACEBOOK_APP_ID = environments[env.APP_ENV].facebookAppId;

  return env;
};

/* istanbul ignore next */
function getEnv(appEnv) {
  let environment = appEnv || 'LOCAL';
  if (Object.keys(environments).indexOf(environment) < 0) {
    environment = 'LOCAL';
  }
  return environment;
}

export default config;
