export const BASE_PATH = '/simulador-de-inversiones';

export const SIGNUP_URL =
  'https://auth.gbm.com/signup?client_id=7c464570619a417080b300076e163289';

export const HUBSPOT_URL =
  'https://meetings.hubspot.com/teresa-arzate/asesoria-gbm-simulador';

export const CURRENCY_TYPE = {
  mxn: 'MXN',
  usd: 'USD',
};

export const PRODUCTS = {
  wealth_management: 'wealth_management',
  smart_cash: 'smart_cash',
  trading: 'trading',
  trading_global: 'trading_global',
};

export const PRODUCT_MAPPER = {
  wealth_management: 'wealthManagement',
  smart_cash: 'smartCash',
  trading: 'trading',
  trading_global: 'tradingGlobal',
  liquidity: 'liquidity',
  balanced: 'balanced',
  speculative: 'speculative',
};

export const CHART_FIRST_RENDER = 'chartFirstRender';

export const FIRST_DEPOSIT_WM_OFFSET = 250000;

// Mockup values
export const SIMULATOR_OBJECTIVES_LIST = [
  {
    simulator_id: 'c949c5d3-043e-4d61-9b42-7d268f68a07c',
    id: '0e6c2de4-7793-4700-9fc4-8e13db1d4dbc',
    target: 'Fondo de Emergencia',
    product: 'wealth_management',
    currency: 'mxn',
    source: 'Liquidez',
    default_first_deposit: 75000,
    default_monthly_deposit: 7500,
    default_years_maximum: 5,
    default_years_minimum: 1,
    minimal_amount: 0,
    maximum_amount: 300000,
    yield: 1,
    volatility: 1,
    liquidity: 0.64,
  },
  {
    simulator_id: 'c949c5d3-043e-4d61-9b42-7d268f68a07c',
    id: '2d22b759-1532-41d2-9c38-1e389a630d3d',
    target: 'Fondo de Liquidez',
    product: 'smart_cash',
    currency: 'mxn',
    source: '7%*',
    default_first_deposit: 65000,
    default_monthly_deposit: 6500,
    default_years_maximum: 10,
    default_years_minimum: 2,
    minimal_amount: 500,
    maximum_amount: 0,
    yield: 0.9,
    volatility: 0.2,
    liquidity: 0.64,
  },
  {
    simulator_id: 'c949c5d3-043e-4d61-9b42-7d268f68a07c',
    id: '9f45556c-a1be-4567-8005-13d853b739c6',
    target: 'Invertir en USA',
    product: 'trading_global',
    currency: 'usd',
    source: 'S&P',
    default_first_deposit: 55000,
    default_monthly_deposit: 5500,
    default_years_maximum: 60,
    default_years_minimum: 3,
    minimal_amount: 0,
    maximum_amount: 0,
    yield: 0.2,
    volatility: 1,
    liquidity: 0.64,
  },
  {
    simulator_id: 'c949c5d3-043e-4d61-9b42-7d268f68a07c',
    id: 'ca9acc66-273b-46c8-8214-5cc23879e740',
    target: 'Ahorrar para mi retiro',
    product: 'wealth_management',
    currency: 'mxn',
    source: 'Balance',
    default_first_deposit: 45000,
    default_monthly_deposit: 4500,
    default_years_maximum: 10,
    default_years_minimum: 5,
    minimal_amount: 1000,
    maximum_amount: 50000,
    yield: 1,
    volatility: 0.5,
    liquidity: 0.64,
  },
];

export const SIMULATOR_RESULTS = {
  score_id: 'b9b6841a-7969-4539-a740-880d685a598c',
  target_id: '2d22b759-1532-41d2-9c38-1e389a630d3d',
  points_to_graph: {
    1: 44495,
    2: 78941,
    3: 116023,
    4: 155945,
    5: 198923,
    6: 245192,
    7: 295003,
    8: 348628,
    9: 406358,
    10: 468509,
  },
};

export const SIMULATOR_PERSONAL_INFO = {
  maximum_name_length: 50,
  minimal_number_length: 10,
};
