import { useEffect, useState } from 'react';

const BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

function getBreakpointName(innerWidth) {
  const breakpoints = Object.entries(BREAKPOINTS);

  for (let index = 0; index < breakpoints.length; index += 1) {
    const breakpointPixels = breakpoints[index][1] - 0.02;
    const breakpointName = breakpoints[index][0];

    if (innerWidth < breakpointPixels) {
      return breakpointName;
    }
  }

  return 'xxl';
}

/**
 * Gets the breakpoint name of the current size window when the user resize the screen.
 * @returns `sm` | `md` | `lg` | `xl` | `xxl`
 */
export function useBreakpoint() {
  const [breakpointName, setBreakpointName] = useState();

  const handleResize = () => {
    setBreakpointName(getBreakpointName(window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakpointName;
}
