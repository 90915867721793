const KEYBOARD_EVENTS = {
  enter: {
    keyCode: 13,
    key: 'Enter',
  },
  escape: {
    keyCode: 27,
    key: 'Escape',
  },
  space: {
    keyCode: 32,
    key: 'Space',
  },
  tab: {
    key: 'Tab',
    keyCode: 9,
  },
};

export default KEYBOARD_EVENTS;
