import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import clsx from 'clsx';

import iconWM from '../../images/atm-sym-mark-wm.svg';

// Other
const APP_NAME = 'GBM+';

const Title = ({ title, subtitle, documentTitle, className, testId }) => {
  const renderTitle = () => (typeof title === 'function' ? title() : title);

  const renderHead = () => (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title ? ` ${APP_NAME} | ${renderTitle()}` : `${APP_NAME}`}</title>
    </Helmet>
  );

  const renderSubtitle = () => (
    <h2 className="advisor-title__subtitle">
      {typeof subtitle === 'function' ? subtitle() : subtitle}
    </h2>
  );

  return (
    <div className={clsx('advisor-title', className)} data-testid={testId}>
      <img src={iconWM} alt="Wealth Management" />
      <h1
        className="typography-3 font-mabry typography--regular"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: renderTitle() }}
      />
      {documentTitle && renderHead()}
      {subtitle && renderSubtitle()}
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  documentTitle: PropTypes.bool,
  className: PropTypes.string,
  testId: PropTypes.string,
};

Title.defaultProps = {
  subtitle: '',
  documentTitle: true,
};

export default Title;
