/* eslint-disable camelcase */
import * as yup from 'yup';

import { SIMULATOR_PERSONAL_INFO } from './constants';

export const targetFormSchema = (messages) => {
  const {
    minAmountRequired,
    maxAmountRequired,
    amountRequired,
    minYearsRequired,
    maxYearsRequired,
    yearsRequired,
  } = messages;

  const handleAmountsRange = (target) => {
    const { minimal_amount, maximum_amount } = target;
    if (maximum_amount) {
      return yup
        .number()
        .min(
          minimal_amount || 1,
          minimal_amount ? minAmountRequired(minimal_amount) : amountRequired,
        )
        .max(maximum_amount, maxAmountRequired(maximum_amount))
        .required(amountRequired);
    }
    return yup
      .number()
      .min(
        minimal_amount || 1,
        minimal_amount ? minAmountRequired(minimal_amount) : amountRequired,
      )
      .required(amountRequired);
  };

  const handleYearsRange = (target) => {
    const { default_years_minimum, default_years_maximum } = target;
    if (default_years_maximum) {
      return yup
        .number()
        .min(
          default_years_minimum || 1,
          default_years_minimum
            ? minYearsRequired(default_years_minimum)
            : yearsRequired,
        )
        .max(default_years_maximum, maxYearsRequired(default_years_maximum))
        .required(yearsRequired);
    }
    return yup
      .number()
      .min(
        default_years_minimum || 1,
        default_years_minimum
          ? minYearsRequired(default_years_minimum)
          : yearsRequired,
      )
      .required(yearsRequired);
  };

  return yup.object({
    target: yup.object().required(),
    first_deposit: yup
      .number()
      .when('target', (target) => handleAmountsRange(target)),
    monthly_deposit: yup
      .number()
      .min(1, amountRequired)
      .required(amountRequired),
    years: yup.number().when('target', (target) => handleYearsRange(target)),
  });
};

export const personalInfoFormSchema = (messages) => {
  const {
    nameRequired,
    nameValid,
    nameLength,
    emailRequired,
    emailValid,
    numberRequired,
    numberMin,
    numberValid,
  } = messages;

  const { maximum_name_length, minimal_number_length } =
    SIMULATOR_PERSONAL_INFO;

  return yup.object({
    name: yup
      .string()
      .max(maximum_name_length, nameLength(maximum_name_length))
      .matches(/^(?=.*\S)[A-Za-zÀ-ÿ\s]+$/, nameValid)
      .required(nameRequired),
    email: yup
      .string()
      .email(emailValid)
      .matches(/^(\S+)@([a-zA-Z]{2,}\.)+[a-zA-Z]{2,}$/, emailValid)
      .required(emailRequired),
    phone: yup
      .string()
      .min(minimal_number_length, numberMin(minimal_number_length))
      .matches(/^(\d)(?!\1+$)\d{9}$/, numberValid)
      .required(numberRequired),
    is_gbm_client: yup.boolean().required(),
  });
};
