import Rox from 'rox-browser';

export const generateFeatureFlags = ({ configurations, featureFlags }) => {
  let featureFlagsConfigurationObject = {};

  Object.keys(featureFlags).forEach((key) => {
    featureFlagsConfigurationObject = {
      ...featureFlagsConfigurationObject,
      [key]: new Rox.Flag(featureFlags[key]),
    };
  });

  Object.keys(configurations).forEach((key) => {
    featureFlagsConfigurationObject = {
      ...featureFlagsConfigurationObject,
      [key]: new Rox.Configuration(configurations[key]),
    };
  });

  return featureFlagsConfigurationObject;
};
