import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import BlogBanner from 'components/BlogBanner';
import gbmLogo from 'images/atm-logo-negative-gbm.svg';

const MaintenanceModePage = () => {
  const { t } = useTranslation('maintenance');

  return (
    <div className="maintenance-mode">
      <div className="content">
        <div className="content__header">
          <div className="title">{t('header')}</div>
          <img className="gbm-logo" src={gbmLogo} alt="GBM+" />
        </div>
        <div className="content__subtitle">{t('subtitle')}</div>
        <div className="content__actions">
          <BlogBanner colorScheme="light" />
        </div>
      </div>
    </div>
  );
};

export default memo(MaintenanceModePage);
