import React from 'react';
import PropTypes from 'prop-types';

const FALLBACK = {
  CURRENCY: 'USD',
  LOCALE: 'en-US',
};

export const FORMAT_TYPES = {
  CURRENCY: 'currency',
  PERCENT: 'percent',
};

const FormattedNumber = ({
  maximumFractionDigits,
  minimumFractionDigits,
  style,
  value,
  withSymbol,
}) => {
  const options = {
    style,
    maximumFractionDigits,
    minimumFractionDigits,
  };
  const amount = new Intl.NumberFormat(
    FALLBACK.LOCALE,
    style === FORMAT_TYPES.PERCENT
      ? options
      : { ...options, currency: FALLBACK.CURRENCY },
  ).format(value);

  return <span>{withSymbol ? amount : amount.replace(/[$%]/g, '')}</span>;
};

FormattedNumber.defaultProps = {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  value: 0,
  withSymbol: true,
};

FormattedNumber.propTypes = {
  maximumFractionDigits: PropTypes.number,
  minimumFractionDigits: PropTypes.number,
  style: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withSymbol: PropTypes.bool,
};

export default FormattedNumber;
