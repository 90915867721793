import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const CLASS_PREFIX = 'portfolio-card';

const PortfolioCard = ({
  title,
  excerpt,
  image,
  onClick,
  isSelected,
  onKeyDown,
  testId,
}) => (
  <div
    className={clsx('radio--card', CLASS_PREFIX, {
      [`${CLASS_PREFIX}--clickable`]: !!onClick,
      [`${CLASS_PREFIX}--selected`]: isSelected,
    })}
    onClick={onClick}
    onKeyDown={onKeyDown}
    role="button"
    tabIndex={0}
    data-testid={testId}
  >
    <div className={`${CLASS_PREFIX}__image`}>
      <img src={image} alt={title} />
    </div>
    <div className="typography-6 typography--semibold">{title}</div>
    <div className="typography-b-1 typography--regular">{excerpt}</div>
  </div>
);

PortfolioCard.defaultProps = {
  onClick: null,
  onKeyDown: null,
  isSelected: false,
  testId: '',
};

PortfolioCard.propTypes = {
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  isSelected: PropTypes.bool,
  testId: PropTypes.string,
};

export default PortfolioCard;
