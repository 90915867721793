/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import clsx from 'clsx';

import { useNotificationContext } from '../../features/NotificationContext';

const COMPONENT_CLASS = 'notification';

const Notification = () => {
  const {
    content,
    isClosing,
    show,
    baseType: type,
    hideNotification,
  } = useNotificationContext();

  return (
    <div
      className={clsx(COMPONENT_CLASS, {
        [`${COMPONENT_CLASS}--closing`]: isClosing,
        [`${COMPONENT_CLASS}--active`]: show,
        [`${COMPONENT_CLASS}--${type}`]: type,
      })}
      role="alertdialog"
    >
      <div className={`${COMPONENT_CLASS}__mark`} />
      <span className={`${COMPONENT_CLASS}__text`}>{content}</span>
      <div
        role="button"
        className={`${COMPONENT_CLASS}__close`}
        onClick={hideNotification}
        tabIndex={0}
      />
    </div>
  );
};

export default Notification;
