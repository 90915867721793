import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const CLASS_PREFIX = 'advisor-cagr';

const PortfolioCagr = ({ cagr }) => {
  const { t } = useTranslation('results');

  return (
    <div className={CLASS_PREFIX}>
      <p className={`${CLASS_PREFIX}--description`}>
        {t('disclaimerOld', { cagr })}
      </p>
    </div>
  );
};

PortfolioCagr.defaultProps = {
  cagr: 0,
};

PortfolioCagr.propTypes = {
  cagr: PropTypes.number,
};

export default PortfolioCagr;
