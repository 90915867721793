import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { NOTIFICATION_TIMEOUT } from '../constants';

const NotificationContext = createContext();
export const useNotificationContext = () => useContext(NotificationContext);

const NotificationProvider = ({ children }) => {
  const [content, setContent] = useState('Error');
  const [isClosing, setIsClosing] = useState(false);
  const [show, setShow] = useState(false);
  const [baseType, setBaseType] = useState('error');

  const hideNotification = () => {
    setIsClosing(true);
    setTimeout(() => {
      setContent('Error');
      setBaseType('error');
      setIsClosing(false);
      setShow(false);
    }, 1000);
  };

  const showNotification = (type, message, timeout = NOTIFICATION_TIMEOUT) => {
    setContent(message);
    setBaseType(type);
    setShow(true);

    setTimeout(() => {
      hideNotification();
    }, timeout);
  };

  return (
    <NotificationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        content,
        isClosing,
        show,
        baseType,
        hideNotification,
        showNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default NotificationProvider;
