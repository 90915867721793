export const getMonthlyAmountLabel = (t, monthlyAmount) =>
  monthlyAmount ? t('monthlyDeposit') : t('monthlyWithdrawal');

export const getInvestmentTermLabel = (t, investmentTerm, max) => {
  const maxInvestmentTerm = max ?? 0;
  if (investmentTerm < 1) {
    return `${t('lessThanAYear')}`;
  }

  if (maxInvestmentTerm > 0 && investmentTerm >= maxInvestmentTerm) {
    return `${t('moreThan')} ${investmentTerm} ${t('years')}`;
  }

  return `${investmentTerm} ${t('years')}`;
};

export const formattedCurrency = (value) => {
  const FALLBACK = {
    CURRENCY: 'USD',
    LOCALE: 'en-US',
  };
  return new Intl.NumberFormat(FALLBACK.LOCALE, {
    currency: FALLBACK.CURRENCY,
  }).format(value);
};
