import React from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';

import Button from '../Button';
import Portal from '../Portal';
import OutsideAlerter from '../OutsideAlerter';

const COMPONENT_CLASS = 'modal';

const Modal = ({
  cancelActionLabel,
  confirmActionLabel,
  description,
  loading,
  onCancelAction,
  onConfirmAction,
  title,
  children,
}) => (
  <Portal>
    <FocusLock>
      <OutsideAlerter
        className={`${COMPONENT_CLASS} outside`}
        role="dialog"
        aria-busy={loading}
        onClickOutside={() => !loading && onCancelAction()}
      >
        {children || (
          <div
            className={`${COMPONENT_CLASS}__wrapper`}
            data-testid="modal-test"
          >
            <div
              className={`${COMPONENT_CLASS}__title`}
              role="heading"
              aria-level="1"
            >
              {title}
            </div>
            <p className={`${COMPONENT_CLASS}__description`}>{description}</p>
            <div className={`${COMPONENT_CLASS}__actions`}>
              <div className={`${COMPONENT_CLASS}__action--confirm`}>
                <Button
                  type="button"
                  value={confirmActionLabel}
                  kind="dark"
                  onClick={onConfirmAction}
                  loading={loading}
                />
              </div>
              <div className={`${COMPONENT_CLASS}__action--cancel`}>
                <Button
                  type="button"
                  value={cancelActionLabel}
                  kind="ghost"
                  onClick={onCancelAction}
                  disabled={loading}
                  testId="cancel-btn"
                />
              </div>
            </div>
          </div>
        )}
      </OutsideAlerter>
    </FocusLock>
  </Portal>
);

Modal.propTypes = {
  cancelActionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  confirmActionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool,
  onCancelAction: PropTypes.func,
  onConfirmAction: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default Modal;
