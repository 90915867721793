/**
 *
 * Loader
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import generateSecureRandomNumber from 'utils/generateSecureRandomNumber';

import { QUOTES, RANDOM_QUOTE_TIME } from './constants';

export class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: undefined,
      intervalId: false,
    };
  }

  componentDidMount() {
    this.selectRandomQuote();
    const intervalId = setInterval(this.selectRandomQuote, RANDOM_QUOTE_TIME);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    intervalId && clearInterval(intervalId);
  }

  selectRandomQuote = () => {
    this.setState({ quote: undefined });
    const { quote } =
      QUOTES[generateSecureRandomNumber(0, QUOTES.length - 1, true)];
    this.setState({ quote: `"${quote}"` });
  };

  render() {
    const { quote } = this.state;
    const { customMessage, withQuotes, animated, type } = this.props;
    return (
      <div
        className={clsx('container-loader', {
          'container-loader--dark': type === 'dark',
        })}
        role="alertdialog"
        aria-busy="true"
        aria-live="assertive"
        data-testid="loader-test"
      >
        <div
          className={clsx('loader', {
            'loader--animated': animated,
          })}
        />
        {customMessage && (
          <div className="loader__custom-message">{customMessage}</div>
        )}
        {quote && withQuotes && (
          <div className="loader__quote" data-testid="loader-quote">
            {quote}
          </div>
        )}
      </div>
    );
  }
}

Loader.defaultProps = {
  withQuotes: false,
  animated: true,
};

Loader.propTypes = {
  customMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  withQuotes: PropTypes.bool,
  animated: PropTypes.bool,
  type: PropTypes.string,
};

export default memo(Loader);
