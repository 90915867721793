import { RANGE_MATCHERS } from 'utils/constants';

export function getProjectedAmount(
  initialAmount,
  monthlyDeposit,
  investmentTermYears,
  cagr,
) {
  const investmentTermYearsInMonths = investmentTermYears * 12;
  const termYearsInterests = [initialAmount];
  const monthlyDepositInterests = [monthlyDeposit];
  const monthlyInterest = 1 + ((cagr + 1) ** (1 / 12) - 1);
  for (let i = 1; i <= investmentTermYearsInMonths; i += 1) {
    termYearsInterests.push(termYearsInterests[i - 1] * monthlyInterest);
    if (i < investmentTermYearsInMonths) {
      monthlyDepositInterests.push(
        monthlyDepositInterests[i - 1] * monthlyInterest,
      );
    }
  }
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  return (
    Math.round(
      (termYearsInterests[investmentTermYearsInMonths] +
        monthlyDepositInterests.reduce(reducer)) *
        100,
    ) / 100
  );
}

/**
 * Split amount and return decimal part
 * @param {number} amount
 */
export const getCents = (amount) => {
  let cents = (amount && amount.toString().split('.')[1]) || '00';
  cents = cents.length === 1 ? `${cents}0` : cents;
  return cents;
};

/**
 * Build answer object depends the type.
 *
 * @param {object} question
 * @param {array|object} answer
 * @returns {object} Parsed answer
 */
export const getAnswerByType = (question, answer) => {
  const answerObj = {};
  const answerIds = [];
  switch (question.question_type.toLowerCase()) {
    case 'input':
      answerObj.answer_value = answer;
      break;
    case 'multiple_select':
      answerObj.answer_identifiers = answer;
      break;
    case 'select':
      answerIds.push(answer.answer_id);
      answerObj.answer_identifiers = answerIds;
      break;
    /* istanbul ignore next */
    default:
      break;
  }

  return answerObj;
};

/**
 * Compares two strings to see if they are identical in meaning
 * (For comparing account names)
 *
 * @param {string} str1
 * @param {string} str2
 * @returns {boolean} equality
 */

export const similarStrings = (str1, str2) => {
  const a = str1.toLowerCase().trim().normalize();
  const b = str2.toLowerCase().trim().normalize();
  return a.localeCompare(b) === 0;
};

/**
 *
 * @param {*} value
 * @param {*} answers
 * @returns {array} answers
 */
export const getAnswersArray = (value, answers) =>
  value.map((el) => answers[el]?.value);

/**
 * Return true if the answer value contains the word "tiempo"
 *
 * @param {string} answer value
 * @returns {boolean}
 */
export const isTimeRange = (value) => value.includes(RANGE_MATCHERS.time);
