import config from 'config';

import { request } from 'api/utils/request';

import { HTTP_VERBS } from '../constants';

const { REDIRECT_API } = config();

const RedirectApi = {
  createStrategy(contractId, data) {
    const url = `${REDIRECT_API.v1}/contracts/${contractId}/accounts`;
    const options = {
      method: HTTP_VERBS.post,
      data,
    };

    return request(url, options);
  },
};

export default RedirectApi;
