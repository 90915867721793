import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import questionsReducer from 'features/questions/questionsSlice';

export default configureStore({
  reducer: {
    questionnaire: questionsReducer,
  },
  middleware: getDefaultMiddleware({
    /**
     * In order to avoid serializable error with the regular expression
     * of the static question when it is passed to the payload of the
     * saveStrategyNameAnswer action.
     */
    serializableCheck: false,
  }),
});
