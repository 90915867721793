import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DonutChart from 'components/Chart';

const Chart = ({ debt, equity }) => {
  const { t } = useTranslation('results');
  return (
    <div className="advisor-results--chart-old">
      <span className="advisor-results--chart-results">
        <DonutChart debt={debt} equity={equity} />
      </span>
      <div className="advisor-results--chart-instruments">
        {!!equity && (
          <span className="advisor-results--chart-instruments--type-equity">
            <span className="advisor-results--chart-instruments--type-title">
              <p>{t('equities')}</p>
            </span>
            <span className="advisor-results--chart-instruments--type-percentage">
              <p>{equity}%</p>
            </span>
          </span>
        )}
        {!!debt && (
          <span className="advisor-results--chart-instruments--type-debt">
            <span className="advisor-results--chart-instruments--type-title">
              <p>{t('debt')}</p>
            </span>
            <span className="advisor-results--chart-instruments--type-percentage">
              <p>{debt}%</p>
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

Chart.propTypes = {
  debt: PropTypes.number,
  equity: PropTypes.number,
};

export default Chart;
