import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from 'store';
import qs from 'qs';
import PropTypes from 'prop-types';
import ROUTES from 'routes';
import config from 'config';

import Simulator from 'containers/Simulator';
import DoneScreen from 'components/DoneScreen';
import ScrollToTop from 'components/ScrollToTop';
import MaintenanceModePage from 'components/MaintenanceModePage';
import EnvironmentAlert from 'components/EnvironmentAlert';
import ProfilerResultsOld from 'views/profilerResultsOld';

import Questionnaire from '../questionnaire';
import ProfilerResults from '../profilerResults';

const { GBM_ADVISOR_STATE, APP_ENV } = config();

/* istanbul ignore next */
const Advisor = ({ featureFlags }) => {
  const isUnderMaintenance = featureFlags.maintenanceMode.isEnabled();
  const isSimulatorEnabled = featureFlags.simulator.isEnabled();

  return (
    <Router basename={ROUTES.home}>
      <EnvironmentAlert environment={APP_ENV} />
      <ScrollToTop>
        <Switch>
          {isUnderMaintenance && (
            <Route path="*" component={MaintenanceModePage} />
          )}
          {isSimulatorEnabled && (
            <Route path={`${ROUTES.simulator}`} render={() => <Simulator />} />
          )}
          <Route
            exact
            path={ROUTES.results}
            render={({ history }) =>
              featureFlags.tmpWealthManagementReprofiling.isEnabled() ? (
                <ProfilerResults
                  history={history}
                  featureFlags={featureFlags}
                />
              ) : (
                <ProfilerResultsOld
                  history={history}
                  featureFlags={featureFlags}
                />
              )
            }
          />
          <Route
            exact
            path={`${ROUTES.question}/:id`}
            render={({ match, history }) => (
              <Questionnaire
                currentStep={match.params.id}
                featureFlags={featureFlags}
                history={history}
              />
            )}
          />
          <Route exact path={`${ROUTES.done}`} render={() => <DoneScreen />} />
          <Route
            path={ROUTES.home}
            render={({ location, history }) => {
              const {
                accounts,
                account,
                accessToken,
                contract,
                initialPortfolio,
                name,
                needAcceptance,
                strategyName,
                userEmail,
                partyId,
              } = qs.parse(location.search, { ignoreQueryPrefix: true });
              // eslint-disable-next-line no-unused-expressions
              store.clearAll();
              store.set(GBM_ADVISOR_STATE, {
                userEmail: userEmail?.replace(/\s/g, '+'),
                accounts,
                account,
                accessToken,
                contract,
                initialPortfolio,
                strategyName,
                name,
                needAcceptance: needAcceptance === 'true',
                partyId,
              });
              return (
                <Questionnaire featureFlags={featureFlags} history={history} />
              );
            }}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

Advisor.propTypes = {
  featureFlags: PropTypes.object,
};

export default Advisor;
