import React from 'react';
import PropTypes from 'prop-types';

import Bar from 'components/Bar';

const CLASS_PREFIX = 'bars';

const getBars = (percentage, offset) => {
  const content = [];
  const activePercentage = (percentage * 100 * offset) / 100;
  for (let i = 0; i < offset; i += 1) {
    content.push(<Bar key={`bar-${i}`} isActive={i <= activePercentage} />);
  }
  return content;
};

const Bars = ({ percentage, offset }) => (
  <div className={CLASS_PREFIX}>{getBars(percentage, offset)}</div>
);

Bars.propTypes = {
  offset: PropTypes.number,
  percentage: PropTypes.number,
};

export default Bars;
