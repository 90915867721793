import React, { useContext, useLayoutEffect, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PatternFormat } from 'react-number-format';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useBreakpoint } from 'hooks/useBreakpoint';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';

import { LANGUAGES } from 'containers/utils/constants';
import { SimulatorContext } from '../Context';
import { BASE_PATH, SIMULATOR_PERSONAL_INFO } from '../constants';
import { personalInfoFormSchema } from '../validationSchema';

const CLASS_PREFIX = 'simulator';
const className = clsx('input__input input__input--big');

const props = {
  className,
};

const PersonalInfo = () => {
  const { simulatorData, setSimulatorData } = useContext(SimulatorContext);
  const { personalInfo } = simulatorData;
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const isMobileDevice = breakpoint === 'md' || breakpoint === 'sm';

  const { t } = useTranslation('simulator');
  const currentLang = simulatorData.lng;
  const errorMessages = {
    nameRequired: t('nameRequired'),
    nameValid: t('nameValid'),
    nameLength: (maxLength) => t('nameLength', { max: maxLength }),
    emailRequired: t('emailRequired'),
    emailValid: t('emailValid'),
    numberMin: (minNumber) => t('numberMin', { min: minNumber }),
    numberRequired: t('numberRequired'),
    numberValid: t('numberValid'),
  };
  const {
    control,
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      is_gbm_client: false,
    },
    resolver: yupResolver(personalInfoFormSchema(errorMessages)),
  });

  const onSubmit = (data) => {
    const route = `${BASE_PATH}/results`;
    setSimulatorData({
      ...simulatorData,
      personalInfo: {
        data,
        isFilled: true,
      },
    });
    history.push(route);
  };

  useLayoutEffect(() => {
    if (personalInfo.isFilled) {
      const route = `${BASE_PATH}/results`;
      history.push(route);
    }
  }, []);

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  return (
    <div
      className={clsx(CLASS_PREFIX, { 'simulator--dark': true })}
      data-testid="simulator"
    >
      <form
        className={`${CLASS_PREFIX}__container`}
        data-testid="simulator-form"
      >
        <div
          className={`${CLASS_PREFIX}__content ${CLASS_PREFIX}__content--stretch`}
        >
          <div
            className={clsx('font-mabry typography--regular', {
              'typography-2': !isMobileDevice,
              'typography-3': isMobileDevice,
            })}
          >
            {t('personalInfoTitle')}
          </div>
          <div className="mt-40 mb-80">
            <div className="typography-6 typography--regular">
              {t('personalInfoDescription')}
              <a
                href={
                  currentLang === LANGUAGES.en
                    ? 'https://assets.gbm.com/simulador/GBM_Privacy_Policy_v20220719-87.pdf'
                    : 'https://assets.gbm.com/docs/Aviso_Privacidad_Integral_(Casa_de_Bolsa)_Oct22.pdf'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('personalInfoPrivacyNotice')}
              </a>
              {t('andSeparator')}
              <a
                href={
                  currentLang === LANGUAGES.en
                    ? 'https://assets.gbm.com/simulador/GBM_Wealth_Management_Disclosure_93.pdf'
                    : 'https://gbm.com/terminos-y-condiciones'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('personalInfoTC')}
              </a>
            </div>
          </div>
          {!isMobileDevice ? (
            <Button
              className={`${CLASS_PREFIX}__btn-next`}
              testId="btnSimulatorNext"
              onClick={handleSubmit(onSubmit)}
              kind="secondary"
              text={t('btnNext')}
            />
          ) : null}
        </div>
        <div className={`${CLASS_PREFIX}__form`}>
          <div className="input-wrapper" data-testid="input-name">
            <div className="input__label">{t('name')}</div>
            <div
              className={clsx('input input--big input--question', {
                'input--error': errors?.name?.message,
              })}
            >
              <input
                maxLength={SIMULATOR_PERSONAL_INFO.maximum_name_length}
                className={className}
                data-testid="simulator-name"
                {...register('name', { required: true })}
              />
              <span className="input--error-label">
                {errors?.name?.message}
              </span>
            </div>
          </div>
          <div className="input-wrapper" data-testid="input-mail">
            <div className="input__label">{t('email')}</div>
            <div
              className={clsx('input input--big input--question', {
                'input--error': errors?.email?.message,
              })}
            >
              <input
                className={className}
                data-testid="simulator-email"
                {...register('email', { required: true })}
              />
              <span className="input--error-label">
                {errors?.email?.message}
              </span>
            </div>
          </div>
          <div className="input-wrapper" data-testid="input-phone">
            <div className="input__label">{t('phone')}</div>
            <div
              className={clsx('input input--big input--question', {
                'input--error': errors?.phone?.message,
              })}
            >
              <Controller
                name="phone"
                control={control}
                render={({ field: { onChange } }) => (
                  <PatternFormat
                    {...props}
                    format="## #### ####"
                    onValueChange={(e) => onChange(e.value)}
                    aria-label="phone"
                  />
                )}
              />
              <span className="input--error-label">
                {errors?.phone?.message}
              </span>
            </div>
          </div>
          <div className="input-wrapper" data-testid="input-gbm-client">
            <Controller
              name="is_gbm_client"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  checked={value}
                  label={t('isGbmClient')}
                  onChange={() => onChange(!value)}
                  testId="client-check"
                />
              )}
            />
          </div>
        </div>
        {isMobileDevice ? (
          <div className="btn-mobile">
            <Button
              className={`${CLASS_PREFIX}__btn-next`}
              testId="btnSimulatorNext"
              onClick={handleSubmit(onSubmit)}
              kind="secondary"
              text={t('btnNext')}
            />
          </div>
        ) : null}
      </form>
    </div>
  );
};

PersonalInfo.propTypes = {};

PersonalInfo.defaultProps = {};

export default PersonalInfo;
