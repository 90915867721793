import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import config from 'config';

import useClickOutside from 'hooks/useClickOutside';

const { FACEBOOK_APP_ID } = config();

const SocialSharing = ({ content, mailSubject }) => {
  const { t } = useTranslation('simulator');
  const { ref, isVisible, setIsVisible } = useClickOutside(false);

  return (
    <div className="button--with-share">
      <div className="tooltip" role="tooltip">
        <div className="tooltip__indicator">
          <button
            aria-label="Share"
            type="button"
            className="button button-icon button-icon--share"
            ref={ref}
            onClick={() => setIsVisible(true)}
          />
          <div className="tooltip__card tooltip__card--down">{t('invite')}</div>
        </div>
      </div>
      <div
        className={clsx('button__share', {
          'button__share--open': isVisible,
        })}
      >
        <a
          className="button__share-option button__share-option--email"
          href={`mailto:?subject=${mailSubject} | GBM+&body=${content}`}
          id="share-email"
          onClick={() => {}}
          role="button"
        >
          Email
        </a>
        <a
          className="button__share-option button__share-option--whatsapp"
          href={`https://wa.me/send?text=${content}`}
          id="share-whatsapp"
          onClick={() => {}}
          rel="noopener noreferrer"
          role="button"
          target="_blank"
        >
          whatsapp
        </a>
        <a
          className="button__share-option button__share-option--twitter"
          href={`https://twitter.com/intent/tweet?text=${content}`}
          id="share-twitter"
          onClick={() => {}}
          rel="noopener noreferrer"
          role="button"
          target="_blank"
        >
          Twitter
        </a>
        <a
          className="button__share-option button__share-option--messenger"
          href={`https://www.facebook.com/dialog/send?app_id=${FACEBOOK_APP_ID}&display=popup&link=https%3A%2F%2Fgbm.com&redirect_uri=https%3A%2F%2Fgbm.com%2Fsimulador`}
          id="share-facebook"
          onClick={() => {}}
          rel="noopener noreferrer"
          role="button"
          target="_blank"
        >
          Facebook
        </a>
      </div>
    </div>
  );
};

SocialSharing.propTypes = {
  content: PropTypes.string.isRequired,
  mailSubject: PropTypes.string.isRequired,
};

export default SocialSharing;
