import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const CLASS_PREFIX = 'card';

const Card = ({ children, squared }) => (
  <div
    className={clsx(CLASS_PREFIX, {
      [`${CLASS_PREFIX}--squared-right`]: squared === 'right',
      [`${CLASS_PREFIX}--squared-left`]: squared === 'left',
      [`${CLASS_PREFIX}--squared-all`]: squared === 'all',
    })}
  >
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  squared: PropTypes.string,
};

export default Card;
