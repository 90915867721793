import SimulatorApi from 'api/simulator';

export const fetchTargets = async (simulatorData, setSimulatorData) => {
  try {
    const { data } = await SimulatorApi.getTargets(simulatorData.lng);
    setSimulatorData({
      ...simulatorData,
      targetsList: {
        data,
        isFilled: true,
      },
    });
  } catch (err) {
    setSimulatorData({
      ...simulatorData,
      targetsList: {
        data: err,
        isFilled: false,
      },
    });
  }
};

export const saveSimulatorAnswers = async (
  simulatorData,
  setSimulatorData,
  targetId,
  simulatorBody,
) => {
  try {
    const { data } = await SimulatorApi.saveSimulatorAnswers(
      targetId,
      simulatorBody,
      simulatorData.lng,
    );
    setSimulatorData({
      ...simulatorData,
      results: {
        data,
        isFilled: true,
      },
    });
  } catch (err) {
    setSimulatorData({
      ...simulatorData,
      results: {
        data: err,
        isFilled: false,
      },
    });
  }
};

export const sendSimulatorResult = async (scoreId) => {
  try {
    const { data } = await SimulatorApi.sendSimulatorResult(scoreId);
    return data.status === 200;
  } catch (err) {
    return err;
  }
};
