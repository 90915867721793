/* eslint-disable react/no-danger */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import KEYBOARD_EVENTS from 'catalogs/keyboard';

const SPLIT_CHAR = ' - ';

const setPercentageColors = (label) => {
  const [left, right] = label.split(SPLIT_CHAR);
  return (
    <>
      <span
        className={left.includes('-') ? 'negative' : 'positive'}
        data-testid="left-label-test"
      >
        {left}
      </span>
      {' - '}
      <span
        className={right.includes('-') ? 'negative' : 'positive'}
        data-testid="right-label-test"
      >
        {right}
      </span>
    </>
  );
};

const Label = ({ isMedium, label }) => {
  const hasPercentage = label.includes('%');
  const parsedLabel = !hasPercentage ? label : setPercentageColors(label);
  return (
    <div
      className={clsx('advisor-option--label', {
        'advisor-option--label-medium': isMedium,
      })}
    >
      {parsedLabel}
    </div>
  );
};

Label.propTypes = {
  isMedium: PropTypes.bool,
  label: PropTypes.string,
};

export const Description = ({ description }) => (
  <span className="radio__description">{description}</span>
);

Description.propTypes = {
  description: PropTypes.string,
};

export const Option = ({
  currentValue,
  value,
  label,
  ariaLabel,
  description,
  onClick,
  handlePrimary,
  medium,
  testId,
}) => (
  <div
    className={clsx('radio', {
      'radio--bar': !description,
      'radio--card': description,
      'radio-option radio-option--big': description,
      'radio-option radio-option--medium': medium,
    })}
    data-testid={testId}
    onClick={() => {
      onClick(value);
    }}
    role="radio"
    aria-checked={currentValue === value}
    aria-label={ariaLabel || label.replace(/\$/g, '')}
    onKeyDown={(key) => {
      if (key.key === KEYBOARD_EVENTS.enter.key) handlePrimary();
      if (key.key === KEYBOARD_EVENTS.space.key) onClick(value);
    }}
    tabIndex={0}
  >
    <input
      type="radio"
      className="input__radio"
      name="radio_bar"
      data-testid={`${testId}-input`}
      value={value}
      onChange={() => onClick(value)}
      checked={currentValue === value}
    />
    <label
      className={clsx('radio__label', {
        'radio__label--bar': !description,
        'radio__label--card': !!description,
      })}
      htmlFor="radio-bar"
    >
      <Label label={label} isMedium={medium} />
      {description && <Description description={description} />}
    </label>
  </div>
);

Option.propTypes = {
  ariaLabel: PropTypes.string,
  currentValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.string,
  testId: PropTypes.string,
  handlePrimary: PropTypes.func,
  label: PropTypes.string,
  medium: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Option;
