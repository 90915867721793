/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Slider } from '@reach/slider';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import store from 'store';
import config from 'config';
import '@reach/slider/styles.css';

import { similarStrings, isTimeRange } from 'utils';
import Title from 'components/Title';
import Button from 'components/Button';
import Input from 'components/Input';
import Option from 'components/Options/Option';
import Checkbox from 'components/Checkbox';
import { HelpCard } from 'components/HelpCard';

import { COLUMNS_OFFSET } from '../constants';
import { STATIC_QUESTIONS } from '../data';

import iconMiniArrow from '../../../images/atm-mini-arrow.svg';

const staticQuestion = {
  ...STATIC_QUESTIONS.v1[0],
};
const { GBM_ADVISOR_STATE } = config();

export const CheckboxOptions = ({
  currentQuestion,
  lastAnswerValue,
  onForwardStep,
  t,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(new Set());
  const saveAnswer = () => {
    onForwardStep(currentQuestion, [...selectedOptions]);
  };

  useEffect(() => {
    const lastAnswer = currentQuestion?.answer || lastAnswerValue;

    if (lastAnswer) {
      const { answer_identifiers: answerIds } = lastAnswer;
      setSelectedOptions(new Set(answerIds));
    }
  }, [currentQuestion, lastAnswerValue]);

  const saveOnSet = (id) => {
    const auxSet = new Set([...selectedOptions]);
    auxSet.has(id) ? auxSet.delete(id) : auxSet.add(id);
    setSelectedOptions(auxSet);
  };

  return (
    <>
      <div className="multiple-note">
        {t('multipleOptions')}
        <img src={iconMiniArrow} alt="Multiple checkbox" />
      </div>
      <div
        className={clsx('options options--multiple', {
          'options--column-1':
            currentQuestion.choice_answer.length <= COLUMNS_OFFSET,
          'options--column-2':
            currentQuestion.choice_answer.length > COLUMNS_OFFSET,
          'options--column-3': currentQuestion.question_key === 'percents',
        })}
        role="group"
      >
        {currentQuestion.choice_answer.map((option) => (
          <Checkbox
            hasCard
            key={option.answer_id}
            checked={selectedOptions.has(option.answer_id)}
            value={option.answer_id}
            onChange={() => saveOnSet(option.answer_id)}
            handlePrimary={() => saveAnswer()}
            ariaLabel={option.value}
            label={option.value}
            testId="checkbox-input__checkbox"
          />
        ))}
      </div>
      <Button
        className="button--top"
        disabled={false}
        onClick={() => saveAnswer()}
        text={t('continue')}
      />
    </>
  );
};

CheckboxOptions.propTypes = {
  choice_answer: PropTypes.array,
  currentQuestion: PropTypes.object,
  lastAnswerValue: PropTypes.object,
  onForwardStep: PropTypes.func,
  question_key: PropTypes.string,
  t: PropTypes.func,
};

export const Options = ({
  currentQuestion,
  onForwardStep,
  lastAnswerValue,
  t,
}) => {
  const [value, setValue] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const saveAnswer = () => {
    onForwardStep(currentQuestion, value);
  };

  useEffect(() => {
    if (
      currentQuestion?.answer?.answer_identifiers?.length ||
      lastAnswerValue?.answer_identifiers?.length
    ) {
      const lastAnswer =
        currentQuestion?.answer?.answer_identifiers[0] ||
        lastAnswerValue?.answer_identifiers[0];
      if (lastAnswer && !isEdit) {
        const answer = currentQuestion.choice_answer.find(
          (a) => a.answer_id === lastAnswer,
        );
        setIsEdit(true);
        setValue(answer);
      }
    }
  }, [currentQuestion, lastAnswerValue]);

  return (
    <>
      <div
        className={clsx('options', {
          'options--column-1':
            currentQuestion.choice_answer.length <= COLUMNS_OFFSET,
          'options--column-2':
            currentQuestion.choice_answer.length > COLUMNS_OFFSET,
          'options--column-3': currentQuestion.question_key === 'percents',
        })}
        role="radiogroup"
      >
        {currentQuestion.choice_answer.map((option) => (
          <Option
            hasCard
            key={option.answer_id}
            currentValue={option.answer_id}
            value={value.answer_id}
            description={option.description}
            handlePrimary={() => saveAnswer()}
            onClick={() => setValue(option)}
            ariaLabel={option.value}
            label={option.value}
            testId="options-input"
          />
        ))}
      </div>
      <Button
        className="button--top"
        disabled={!value}
        onClick={() => saveAnswer()}
        text={t('continue')}
      />
    </>
  );
};

Options.propTypes = {
  choice_answer: PropTypes.array,
  currentQuestion: PropTypes.object,
  lastAnswerValue: PropTypes.object,
  onForwardStep: PropTypes.func,
  question_key: PropTypes.string,
  t: PropTypes.func,
};

export const MultipleInput = ({
  currentQuestion,
  onForwardStep,
  lastAnswerValue,
  t,
}) => {
  const [value, setValue] = useState('');
  const [valid, setValid] = useState(false);
  const [hint, setHint] = useState('');
  const questionnaire = useSelector((state) => state.questionnaire);

  const handleChange = (newValue, isValid) => {
    setValue(newValue);

    // Is question is about strategy name, check if is not repeated
    if (currentQuestion.question_key === staticQuestion.question_key) {
      checkStrategyName(newValue, isValid);
    } else {
      setValid(isValid);
    }
  };

  const checkStrategyName = (newValue, isRegexValid) => {
    const accountWithoutBlanks = newValue.replace(/\s+/g, ' ');
    const accounts = store.get(GBM_ADVISOR_STATE)?.accounts?.split(',');
    const concurrences =
      accounts?.filter((account) =>
        similarStrings(account, accountWithoutBlanks),
      ).length || 0;
    let text =
      concurrences > 0
        ? t('invalidStrategyName')
        : currentQuestion.metadata?.notes;

    if (!isRegexValid) {
      text = t('invalidStrategyNameRegex');
    }

    setHint(text);
    setValid(concurrences === 0 && isRegexValid);
  };

  const saveAnswer = () => {
    if (value && valid) {
      onForwardStep(currentQuestion, value);
    }
  };

  useEffect(() => {
    const { answers } = questionnaire;
    const answer =
      answers?.[currentQuestion.question_id] || lastAnswerValue?.answer_value;

    // Check if answer is valid
    if (answer && typeof answer === 'string') {
      setValue(answer);
      setValid(true);
    } else if (answer?.answer_value) {
      setValue(answer.answer_value);
      setValid(true);
    } else if (typeof answer === 'undefined') {
      setValue('');
      setValid(true);
    } else {
      setValue('');
      setValid(false);
    }

    setHint(currentQuestion.metadata?.notes);
    // eslint-disable-next-line
  }, [currentQuestion, lastAnswerValue]);

  return (
    <div className="input-wrapper" data-testid="multiple-input">
      <Input
        hasHint={!!hint}
        hint={!valid ? hint : null}
        isLabelHidden
        label="Name"
        valid={valid}
        invalid={!valid && !!hint}
        maxLength={currentQuestion.metadata?.max_length}
        pattern={currentQuestion.metadata?.regular_expression}
        name={currentQuestion.metadata?.name}
        placeholder={currentQuestion.metadata?.placeholder}
        questionType={currentQuestion.metadata?.question_data_type.toLowerCase()}
        type={currentQuestion.metadata?.type || 'text'}
        value={value}
        defaultValue={value}
        autoFocus
        valueChanged={handleChange}
        handlePrimary={saveAnswer}
        testId="multiple-input__input"
      />
      <Button
        className="button--top"
        disabled={!value || !valid}
        onClick={saveAnswer}
        text={t('continue')}
      />
    </div>
  );
};

MultipleInput.propTypes = {
  currentQuestion: PropTypes.object,
  lastAnswerValue: PropTypes.object,
  metadata: PropTypes.object,
  onForwardStep: PropTypes.func,
  t: PropTypes.func,
};

export const RangeInput = ({
  currentQuestion,
  onForwardStep,
  lastAnswerValue,
  t,
}) => {
  const { metadata } = currentQuestion;
  const isRangeTime = isTimeRange(currentQuestion.value);
  const rangeLabel = isRangeTime ? t('years') : t('dependents');
  const [value, setValue] = useState(metadata.max_range / 2);
  const [label, setLabel] = useState(`${metadata.max_range / 2} ${rangeLabel}`);

  useEffect(() => {
    if (Number(lastAnswerValue?.answer_value) < metadata.max_range) {
      setValue(lastAnswerValue.answer_value);
      setLabel(`${lastAnswerValue.answer_value} ${rangeLabel}`);
    } else if (Number(lastAnswerValue?.answer_value) >= metadata.max_range) {
      setValue(metadata.max_range);
      setLabel(`${metadata.max_range} ${rangeLabel}`);
    }
  }, [lastAnswerValue]);

  const handleChange = (newValue) => {
    let sliderLabel = t('sliderValue', { value: newValue, label: rangeLabel });
    if (newValue < 1) {
      sliderLabel = isRangeTime
        ? t('sliderMinValue', { label: rangeLabel })
        : t('sliderValue', { value: newValue, label: rangeLabel });
    }
    if (newValue >= metadata.max_range) {
      sliderLabel = isRangeTime
        ? t('sliderMaxValue', {
            value: metadata.max_range,
            label: rangeLabel,
          })
        : t('sliderMaxValueDependents');
    }
    setValue(newValue);
    setLabel(sliderLabel);
  };

  const saveAnswer = () => {
    onForwardStep(currentQuestion, value.toString());
  };

  return (
    <div className="input-wrapper">
      <div className="slider__label">{label}</div>
      <Slider
        data-testid="range-input"
        min={0}
        max={metadata.max_range}
        step={1}
        value={value}
        name="range"
        onChange={handleChange}
      />
      <Button
        className="button--top"
        disabled={false}
        onClick={() => saveAnswer()}
        text={t('continue')}
      />
    </div>
  );
};

RangeInput.propTypes = {
  currentQuestion: PropTypes.object,
  lastAnswerValue: PropTypes.object,
  onForwardStep: PropTypes.func,
  t: PropTypes.func,
};

export const Question = (props) => {
  const { currentQuestion } = props;

  return !isEmpty(currentQuestion) ? (
    <div className="question">
      <Title title={currentQuestion.value || ''} />
      {!!currentQuestion.description && (
        <HelpCard
          type="info"
          icon="info"
          message={currentQuestion.description}
        />
      )}
      {!!currentQuestion.choice_answer?.length &&
        currentQuestion.question_type === 'select' && <Options {...props} />}
      {!!currentQuestion.choice_answer?.length &&
        currentQuestion.question_type === 'multiple_select' && (
          <CheckboxOptions {...props} />
        )}
      {!currentQuestion.choice_answer?.length &&
        currentQuestion?.metadata?.question_data_type !== 'range' && (
          <MultipleInput {...props} />
        )}
      {!currentQuestion.choice_answer?.length &&
        currentQuestion?.metadata?.question_data_type === 'range' && (
          <RangeInput {...props} />
        )}
    </div>
  ) : null;
};

Question.propTypes = {
  choice_answer: PropTypes.array,
  currentQuestion: PropTypes.object,
  description: PropTypes.string,
  metadata: PropTypes.object,
  question_type: PropTypes.string,
  t: PropTypes.func,
  value: PropTypes.string,
};

const Steps = (props) => (
  <Question
    currentQuestion={props.currentQuestion}
    accountProfiling={props.accountProfiling}
    currentStep={props.currentStep}
    onForwardStep={props.onForwardStep}
    lastAnswerValue={props.lastAnswerValue}
    t={props.t}
  />
);

Steps.propTypes = {
  accountProfiling: PropTypes.bool,
  currentQuestion: PropTypes.object,
  currentStep: PropTypes.string,
  lastAnswerValue: PropTypes.object,
  onForwardStep: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation('steps')(Steps);
