/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import SocialSharing from 'components/SocialSharing';

import { FIRST_DEPOSIT_WM_OFFSET, PRODUCTS, SIGNUP_URL } from '../constants';

const CLASS_PREFIX = 'simulator';

const Actions = ({ onTryAgain, onSendAdvised, onSendResults, target }) => {
  const { t } = useTranslation('simulator');

  return (
    <>
      <div className={`${CLASS_PREFIX}__actions`}>
        <Button
          className={`${CLASS_PREFIX}__btn-start`}
          testId="btnSimulatorstartToInvest"
          onClick={() => {
            window.open(SIGNUP_URL, '_blank');
          }}
          text={t('btnStartToInvest')}
          kind="secondary"
        />
        <Button
          className={`${CLASS_PREFIX}__btn-try`}
          testId="btnTryAgain"
          onClick={onTryAgain}
          text={t('testOtherProduct')}
          kind="ghost"
        />
      </div>
      <div className={`${CLASS_PREFIX}__actions`}>
        {target.target.product === PRODUCTS.wealth_management &&
        target.first_deposit >= FIRST_DEPOSIT_WM_OFFSET ? (
          <div className="tooltip" role="tooltip">
            <div className="tooltip__indicator">
              <button
                aria-label="Advised"
                data-testid="btnSendToHubspot"
                type="button"
                className="button button-icon button-icon--users"
                onClick={onSendAdvised}
              />
            </div>
            <div className="tooltip__card tooltip__card--down">
              {t('advisedAccount')}
            </div>
          </div>
        ) : null}
        <div className="tooltip" role="tooltip">
          <div className="tooltip__indicator">
            <button
              aria-label="Send result"
              data-testid="btnSendToEmail"
              type="button"
              className="button button-icon button-icon--email"
              onClick={onSendResults}
            />
          </div>
          <div className="tooltip__card tooltip__card--down">
            {t('sendResult')}
          </div>
        </div>
        <SocialSharing
          content={t('shareContent')}
          mailSubject={t('emailSubject')}
        />
      </div>
    </>
  );
};

Actions.propTypes = {
  onTryAgain: PropTypes.func,
  onSendAdvised: PropTypes.func,
  onSendResults: PropTypes.func,
  target: PropTypes.object,
};

Actions.defaultProps = {};

export default Actions;
