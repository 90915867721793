import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const CLASS_PREFIX = 'bar';

const Bar = ({ isActive }) => (
  <div
    className={clsx(CLASS_PREFIX, {
      [`${CLASS_PREFIX}--active`]: isActive,
    })}
  ></div>
);

Bar.propTypes = {
  isActive: PropTypes.bool,
};

export default Bar;
