import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const CLASS_PREFIX = 'information-section';

const InformationSection = ({ description, value, subtitle, icon }) => (
  <div className={CLASS_PREFIX}>
    <div className={`${CLASS_PREFIX}__description`}>
      {!!icon && <img src={icon} alt={description} />}
      <span
        className={clsx({
          [`${CLASS_PREFIX}__description--bold-description`]: !!icon,
        })}
      >
        {description}
      </span>
    </div>
    <span className={`${CLASS_PREFIX}__value`}>{value}</span>
    {subtitle && (
      <span className={`${CLASS_PREFIX}__subtitle`}>{subtitle}</span>
    )}
  </div>
);

InformationSection.propTypes = {
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default InformationSection;
