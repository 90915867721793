import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import config from 'config';
import { getLangFromURL } from 'containers/utils/getLang';

const env = config();
const { REACT_APP_DEBUG_LANG } = env;
const language = getLangFromURL();

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: language,
    fallbackLng: [language],
    load: 'currentOnly',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    debug: REACT_APP_DEBUG_LANG,
    react: {
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
