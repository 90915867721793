/* eslint-disable no-param-reassign */
// Libraries
import { select, pie } from 'd3';

// Components
import ChartUtils from '../shared';

// Config object, created to make it easier to change chart details
const data = {
  elementClassName: 'radial-chart-svg-foreground',
  outerRadius: 100,
  selectedArcGrowth: 0,
  valueKey: 'percentage',
  valueTotal: 1,
  chartPadding: 0,
};

const EMPTY = {
  valueKey: 'name',
  value: 'empty',
};

// Render chart foreground, a blue arc with rounded borders
export const foreground = (
  node,
  diameter,
  debt,
  equity,
  arcsWidth = 15,
  remove = false,
) => {
  const { outerRadius, selectedArcGrowth } = data;
  const innerRadius = outerRadius - arcsWidth;
  const grownOuterRadius = outerRadius + selectedArcGrowth / 2;
  const grownInnerRadius = innerRadius - selectedArcGrowth / 2;

  if (remove) {
    select('#portfolio-composition').remove();
  }

  // Create base shape for chart
  const renderSvg = select(node)
    .append('svg')
    .attr('id', 'portfolio-composition')
    .attr('width', diameter)
    .attr('height', diameter)
    .append('g')
    .attr('transform', ChartUtils.center(diameter, data.chartPadding))
    .attr('stroke', 'white')
    .style('stroke-width', '1px');

  const centerText = renderSvg.append('text').attr('text-anchor', 'middle');

  centerText
    .append('tspan')
    .attr('class', 'center-text-percentage')
    .attr('dy', 0)
    .attr('x', 0)
    .style('stroke', 'black');

  centerText
    .append('tspan')
    .attr('class', 'center-text-name')
    .attr('dy', 24)
    .attr('x', 0)
    .style('stroke', 'black');

  const values = [
    { name: 'debt', percentage: debt, label: 'Renta Fija' },
    { name: 'equity', percentage: equity, label: 'Renta Variable' },
  ];

  const defaultIndex = debt > equity ? 0 : 1;

  // Save data of slices to pieSlices
  const renderPie = pie().value((d) => d[data.valueKey]);
  const datums = [];
  let nodeListItems = [];
  let datumItem = {};
  // Create shapes for slice
  const dg = renderSvg
    .selectAll(`.${data.elementClassName}`)
    .data(renderPie(values))
    .enter()
    .append('g')
    .attr('class', data.elementClassName);

  const selectedName = renderSvg.select('.center-text-name');
  const selectedPercentage = renderSvg.select('.center-text-percentage');

  selectedName.text(values[defaultIndex].label);
  selectedPercentage.text(`${values[defaultIndex].percentage}%`);
  dg.append('path')
    .each((d, index, nodeList) => {
      if (defaultIndex === index) {
        nodeListItems = nodeList;
        datumItem = d;
        datumItem.outerRadius = outerRadius;
        datumItem.innerRadius = innerRadius;
      }
    })
    .attr('d', ChartUtils.renderArc)
    .attr('class', (d) => `composition-item ${d.data.name.toLowerCase()}`);
  if (datumItem.data[EMPTY.valueKey] !== EMPTY.value) {
    ChartUtils.animateChart(
      defaultIndex,
      nodeListItems,
      datumItem,
      'mouseover',
      grownInnerRadius * 0.95,
      grownOuterRadius * 1.05,
    );
  }

  // Append arcs to svg, add mouse events
  /* istanbul ignore next */
  dg.select('path')
    .each((d, index) => {
      const datum = d;
      datums[index] = datum;
      datum.outerRadius = outerRadius;
      datum.innerRadius = innerRadius;
    })
    .attr('d', ChartUtils.renderArc)
    .attr('class', (d) => `composition-item ${d.data.name.toLowerCase()}`)
    .on('mouseover', (datum, index, nodeList) => {
      if (datum.data[EMPTY.valueKey] !== EMPTY.value) {
        ChartUtils.animateChart(
          index,
          nodeList,
          datum,
          'mouseover',
          grownInnerRadius * 0.95,
          grownOuterRadius * 1.05,
        );
        ChartUtils.animateChart(
          1 - index,
          nodeList,
          datums[1 - index],
          'mouseover',
          grownInnerRadius,
          grownOuterRadius,
        );
        selectedName.text(datum.data.label);
        selectedPercentage.text(`${datum.data.percentage}%`);
      }
    })
    .on('mouseout', (datum, index, nodeList) => {
      const copyNodeList = nodeList.slice(0);
      copyNodeList.splice(index, 1);
      nodeList.forEach((d) =>
        select(d).classed('composition-item--opacity', false),
      );
      if (datum.data[EMPTY.valueKey] !== EMPTY.value) {
        ChartUtils.animateChart(
          index,
          nodeList,
          datum,
          'mouseout',
          innerRadius,
          outerRadius,
        );

        ChartUtils.animateChart(
          defaultIndex,
          nodeList,
          datums[defaultIndex],
          'mouseover',
          grownInnerRadius * 0.95,
          grownOuterRadius * 1.05,
        );
        selectedName.text(values[defaultIndex].label);
        selectedPercentage.text(`${values[defaultIndex].percentage}%`);
      }
    });
};
