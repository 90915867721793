import decode from 'jwt-decode';
import store from 'store';

import config from 'config';

const { GBM_ADVISOR_STATE } = config();

export default class Auth {
  getToken() {
    return store.get(GBM_ADVISOR_STATE)?.accessToken;
  }

  getTokenExpiration(token) {
    return decode(token)?.exp;
  }

  isTokenExpired(token) {
    const expirationDate = this.getTokenExpiration(token);
    return expirationDate.valueOf() <= new Date().valueOf();
  }

  getEmail(token) {
    const decoded = token ? decode(token) : {};
    return decoded?.username?.replace('|', '@');
  }

  getUserId(token) {
    const decoded = token ? decode(token) : {};
    return decoded?.sub;
  }
}
